import React from "react";
import { Typography } from "constants/Typography/Typography";
import {
  CommonStyledInput,
  ErrorMessage,
  InputFieldContainer,
  PlaceholderLabel
} from "./sharedInputStyling";

interface InputFieldProps {
  label?: string;
  type?: string;
  name: string;
  value: string;
  placeholder?: string;
  error?: boolean;
  errorString?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  color?: string;
  onFocus?: () => void;
  onBlur?: () => void;
}

const InputField = ({
  label,
  type = "text",
  name,
  value,
  placeholder,
  error = false,
  errorString,
  color,
  onChange,
  onFocus,
  onBlur,
  onPaste,
}: InputFieldProps) => {
  return (
    <InputFieldContainer>
      {label && <Typography color={color}>{label}</Typography>}
      <CommonStyledInput
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        error={error || !!errorString}
        onFocus={onFocus}
        onBlur={onBlur}
        onPaste={onPaste}
      />

      {error && <ErrorMessage>This field is required</ErrorMessage>}
      {errorString && <ErrorMessage>{errorString}</ErrorMessage>}
    </InputFieldContainer>
  );
};

export default InputField;
