export const FontFamily = {
  primary: "Montserrat-Medium",
  secondary: "Comfortaa",
  semiBold: "Montserrat-SemiBold",
  bold: "Montserrat-Bold",
  medium: "Montserrat-Medium",
  boldItalic: "Montserrat-BoldItalic",
} as const;

export const FontSize = {
  h1: "54px",
  h2: "34px",
  h3: "27px",
  h4: "24px",
  base: "16px",
  small: "14px",
  medium: "17px",
  large: "20px",
  p24: "24px",
  p20: "20px",
} as const;

export const FontWeight = {
  normal: 400,
  semiBold: 600,
  bold: 700,
} as const;

export const FontStyle = {
  italic: "italic",
} as const;
