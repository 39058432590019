import React, { useContext } from "react";
import { Typography } from "constants/Typography/Typography";
import styled, { ThemeContext } from "styled-components";
import TypeDescription from "common/TypeDescription";

import { ReactComponent as BPIcon } from "../../../assets/svgs/program_bp_icon.svg";
import { ReactComponent as GLIcon } from "../../../assets/svgs/program_gl_icon.svg";
import { ReactComponent as WMICon } from "../../../assets/svgs/program_wm_icon.svg";
import useTranslation from "common/TranslationHook/useTranslation";

const ProgramContainer = styled.div`
  display: flex;
  padding: 0px var(--container-padding) var(--content-gap)
    var(--container-padding);
  flex-direction: column;
  gap: 24px;
  max-width: var(--max-content-width);
  margin: auto;
`;

const ProgramsList = styled.div`
  box-sizing: border-box;
  display: flex;
  gap: 40px;
  justify-content: center;
  justify-content: space-between;
  flex-direction: ${(props) => (props.theme.format > 1 ? "row" : "column")};
`;

const Program = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

const ProgramIconAndText = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

const StyledHeader = styled(Typography)`
  text-align: ${(props) => (props.theme.format > 1 ? "center" : "flex-start")};
`;

const Programs = () => {
  const themeContext = useContext(ThemeContext)!;
  const { t } = useTranslation("programs");

  type Program = {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    header: string;
    body: string;
  };

  const programs: Program[] = [
    {
      icon: BPIcon,
      header: t("hightBloodPressure"),
      body: t("hightBloodPressureText"),
    },
    {
      icon: GLIcon,
      header: t("type2Diabetes"),
      body: t("type2DiabetesText"),
    },
    {
      icon: WMICon,
      header: t("weightManagement"),
      body: t("weightManagementText"),
    },
  ];

  return (
    <ProgramContainer>
      <StyledHeader headingLevel="h2" marginTop="0px" mb={"0px"}>
        {t("programsHeader")}
      </StyledHeader>
      <ProgramsList>
        {programs.map(({ icon, header, body }) => {
          return (
            <Program key={header + body}>
              <ProgramIconAndText>
                {React.createElement(icon, {
                  style: {
                    minHeight: 64,
                    minWidth: 64,
                    flexShrink: 0,
                    fill: themeContext.colors.tintSecondary50,
                  },
                })}
                <Typography headingLevel="h4" marginTop={"0px"} mb={"0px"}>
                  {header}
                </Typography>
              </ProgramIconAndText>
              <Typography marginTop={"0px"} mb={"0px"}>
                {body}
              </Typography>
            </Program>
          );
        })}
      </ProgramsList>
    </ProgramContainer>
  );
};

export default Programs;
