import { Typography } from "constants/Typography/Typography";
import BackForwardButton from "common/BackForwardButton";
import { useEffect, useState } from "react";
import StateSelect from "common/StateSelect";
import { IContextActions } from "store/Context";
import "../style.css";
import { useApi } from "store/useApi";
import InputField from "common/InputField/InputField";
import PatternInputField from "common/InputField/PatternFormat";
import useTranslation from "common/TranslationHook/useTranslation";

interface MoreInfoFormProps {
  handleUpdatedUser: () => void;
  actions: IContextActions;
}

export const MoreInfoForm = ({
  handleUpdatedUser,
  actions,
}: MoreInfoFormProps) => {
  const [streetAddress, setStreetAddress] = useState("");
  const [addressLine, setAddressLine] = useState("");
  const [city, setCity] = useState("");
  const [livingState, setLivingstate] = useState("");
  const [zipCode, setZipCode] = useState("");

  const [streetAddressError, setStreetAddressError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [livingstateError, setLivingstateError] = useState(false);
  const [zipCodeError, setZipCodeError] = useState(false);
  const [fullAddress, setFullAddress] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  const { t } = useTranslation("photoidverification");

  const { UpdateUserAddress } = useApi();

  const updateFullAddress = () => {
    const combinedAddress = addressLine
      ? `${streetAddress}, ${addressLine}`
      : streetAddress;
    setFullAddress(combinedAddress);
  };
  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [streetAddress, addressLine, city, livingState, zipCode]);

  const onChange = (event: any) => {
    const { name, value } = event.target;
    actions.setValue(name, value);
    switch (name) {
      case "StreetAddress":
        setStreetAddress(value);
        if (value.trim() !== "") setStreetAddressError(false);
        break;
      case "AddressLine":
        setAddressLine(value);
        break;
      case "City":
        setCity(value);
        if (value.trim() !== "") setCityError(false);
        break;
      case "LivingState":
        setLivingstate(value);
        if (value.trim() !== "") setLivingstateError(false);
        break;
      case "ZipCode":
        setZipCode(value);
        if (value.trim() !== "") setZipCodeError(false);
        break;
    }
    if (name === "StreetAddress" || name === "AddressLine") {
      updateFullAddress();
    }
  };

  const checkFormValidity = () => {
    return (
      streetAddress.trim() !== "" &&
      city.trim() !== "" &&
      livingState.trim() !== "" &&
      zipCode.trim() !== ""
      // Add other fields if necessary
    );
  };

  const validateBasicForm = () => {
    let isValid = true;
    if (streetAddress === "") {
      setStreetAddressError(true);
      isValid = false;
    } else {
      setStreetAddressError(false);
    }
    if (city === "") {
      setCityError(true);
      isValid = false;
    } else {
      setCityError(false);
    }
    if (livingState === "") {
      setLivingstateError(true);
      isValid = false;
    } else {
      setLivingstateError(false);
    }
    if (zipCode === "") {
      setZipCodeError(true);
      isValid = false;
    } else {
      setZipCodeError(false);
    }

    return isValid;
  };

  const handleContinue = () => {
    const isFormValid = validateBasicForm();

    if (isFormValid) {
      actions.setValue("alternateAddress", fullAddress);
      actions.setValue("alternateCity", city);
      actions.setValue("alternateState", livingState);
      actions.setValue("alternateZipCode", zipCode);

      UpdateUserAddress().then((data) => {
        if (data.userAccessCode !== null || data.userAccessCode !== "") {
          handleUpdatedUser();
        }
      });
    }
  };
  return (
    <div>
      <Typography>{t("moreInfoForm.introText")}</Typography>
      <Typography headingLevel="large" style={{ marginTop: 24 }}>
        {t("moreInfoForm.providePreviousAddress")}
      </Typography>
      <div className="input-field-div">
        <InputField
          label={t("moreInfoForm.fields.streetAddress")}
          name="StreetAddress"
          value={streetAddress}
          onChange={onChange}
          error={streetAddressError}
        />
        <InputField
          label={t("moreInfoForm.fields.addressLine")}
          name="AddressLine"
          value={addressLine}
          onChange={onChange}
        />

        <div className="input-field-wrapper">
          <InputField
            label={t("moreInfoForm.fields.city")}
            name="City"
            value={city}
            onChange={onChange}
            error={cityError}
          />
          <StateSelect
            livingState={livingState}
            onChange={onChange}
            livingstateError={livingstateError}
          />
        </div>
        <PatternInputField
          label={t("moreInfoForm.fields.zipCode")}
          name="ZipCode"
          value={zipCode}
          format="#####"
          error={zipCodeError}
          onChange={onChange}
          width="47%"
        />
        <BackForwardButton
          forwardAction={handleContinue}
          backVisible={false}
          disabled={!isFormValid}
        />
      </div>
    </div>
  );
};
