import styled from "styled-components";
import { Typography } from "constants/Typography/Typography";
import { Color } from "constants/Colors";
import { InputHTMLAttributes } from "react";

interface CommonStyledInputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  width?: string;
  flexSize?: string;
}

export const InputFieldContainer = styled.div<CommonStyledInputProps>`
  max-width: 100% !important;
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flexSize ? props.flexSize : "1")};
  margin-right: 0;

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;
export const ErrorMessage = styled(Typography)`
  color: ${(props) => props.theme.colors.alertRed};
  margin-top: 1px;
  margin-bottom: 0;
`;

export const CommonStyledInput = styled.input<CommonStyledInputProps>`
  border-radius: 8px;
  box-sizing: border-box;
  height: 46px;
  width: ${(props) => (props.width ? "47%" : "auto")};
  border: 1px solid ${(props) => props.theme.colors.textPrimary};
  ${(props) =>
    props.error ? props.theme.colors.alertRed : props.theme.colors.textPrimary};
  display: block;
  font-size: 17px;
  padding: 0.375rem 0.75rem !important;
  font-family: "Montserrat-Medium";
  color: ${(props) => props.theme.colors.textPrimary};

  &:focus {
    border-color: ${(props) => props.theme.colors.textLink};
    outline: none;
  }

  &::placeholder {
    font-size: 17px;
    font-family: "Montserrat-Medium";
  }

  &.error {
    border-color: ${(props) => props.theme.colors.alertRed};
  }
  @media (max-width: 768px) {
    width: auto;
  }
`;

export const PlaceholderLabel = styled.label`
  position: relative;
  top: -30px;
  right: 10px;
  display: flex;
  justify-content: end;
  color: #999;
  font-size: 17px;
  font-family: "Montserrat-Medium";
  pointer-events: none;
  transition: all 0.2s ease-out;
  white-space: nowrap;
  display: flex;
  align-items: center;
 
  /* ${CommonStyledInput}:focus + &,
  ${CommonStyledInput}:not(:placeholder-shown) + & {
    left: calc(100% - 10px);
    transform: translateX(-100%);
  } */
`;
