import { Color } from "constants/Colors";
import { FontFamily, FontSize } from "constants/Fonts";
import { Typography } from "constants/Typography/Typography";
import React from "react";
import styled from "styled-components";

interface RadioButtonProps {
  value: string;
  checked: boolean;
  onChange: () => void;
  label: string | React.ReactNode;
  style?: React.CSSProperties;
  labelColor?: string;
  borderColor?: string;
  id?: any;
}

const StyledLabel = styled.label`
  display: flex;
  flex: 1;
  cursor: pointer;
  margin-right: 28px;
  line-height: 30px;
  font-family: ${FontFamily.primary};
  color: ${(props) => props.color || props.theme.colors.textPrimary};
  font-size: ${FontSize.base};
`;

const StyledRadio = styled.input`
  display: flex;
  appearance: none;
  width: 24px;
  height: 24px;
  background-color: #e7ecf2;
  border: 1px solid ${(props) => props.color || props.theme.colors.primary};
  border-radius: 50%;
  outline: none;
  margin-right: 8px;
  margin-left: 0px;
  position: relative;
  cursor: pointer;

  &:checked {
    background-color: ${(props) => props.theme.colors.primary};
    border: none;
  }

  &:checked::after {
    content: "✓";
    position: absolute;
    font-size: 14px;
    color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
`;
const RadioButton: React.FC<RadioButtonProps> = ({
  value,
  checked,
  onChange,
  style,
  label,
  labelColor,
  borderColor,
  id,
}) => {
  return (
    <Wrapper>
      <StyledRadio
        id={id}
        type="radio"
        value={value}
        checked={checked}
        onClick={onChange}
        style={style}
        color={borderColor}
      />
      <StyledLabel htmlFor={id} color={labelColor}>
        {label}
      </StyledLabel>
    </Wrapper>
  );
};

export default RadioButton;
