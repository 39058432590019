import { useState } from "react";
import styled, { useTheme } from "styled-components";
import { ReactComponent as PlusIcon } from "assets/svgs/accordionPlus_icon.svg";
import { ReactComponent as MinusIcon } from "assets/svgs/accordionMinus_icon.svg";
import { Fade } from "react-awesome-reveal";

const maxWidth = `900px`;

const AccordionSection = styled.div<{ clicked: boolean }>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  transition: height 0.1s ease-in;
  /* height: 650px; */
  ${(props) => {
    if (props.clicked !== false) {
      return `
      transition: height 0.1s ease-out;
      `;
    }
  }}
  @media (min-width: 768px) {
    /* height: 500px; */
    ${(props) => {
      if (props.clicked !== false) {
        return `
        transition: height 0.1s ease-out;
        `;
      }
    }}
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  /* padding: 0px 16px; */
  border-radius: 8px;
  gap: 8px;
  cursor: pointer;
  /* background: var(--backgrounds-primary-ochsner-light-blue-10, #E5F1FA); */
  @media (min-width: 992px) {
    width: ${maxWidth};
    max-width: ${maxWidth};
  }
`;

const Wrap = styled.div<{ clicked: boolean }>`
  background: ${(props) => props.theme.colors.bgLight};
  color: ${(props) => props.theme.colors.textPrimary};
  font-family: Montserrat-Medium;
  font-size: 16px;
  font-weight: 600;
  line-height: 130%;
  /* border-radius: 8px; */
  padding: 12px 16px;
  border-radius: 8px;
  /* ${(props) => {
    if (props.clicked !== false) {
      return `
      border-bottom: 0.5px solid var(--Tints-BlueGrey50, #A9B9D2);
      `;
    }
  }} */
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  width: auto;
  /* gap: 8px; */
  align-self: stretch;
`;

const Dropdown = styled.div<{ clicked: boolean }>`
  background: ${(props) => props.theme.colors.bgLight};
  color: ${(props) => props.theme.colors.textPrimary};
  max-height: 400px;
  overflow: scroll;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  ${(props) => {
    if (props.clicked !== false) {
      return `
      border-top: 0.5px solid var(--Tints-BlueGrey50, #A9B9D2);
      `;
    }
  }}
  text-align: left;
  /* border-radius: 8px; */
  ${(props) => {
    if (props.clicked !== false) {
      return `
      border-radius: 0px 0px 8px 8px;
      `;
    }
  }}
  /* padding: 0 2rem; */
  width: auto;
  padding: 12px 16px;
`;

const StyledPlusIcon = styled(PlusIcon)`
  width: 20px;
  height: 20px;
  fill: ${(props) => props.theme.colors.textPrimary};
`;

const StyledMinusIcon = styled(MinusIcon)`
  width: 20px;
  height: 20px;
  fill: ${(props) => props.theme.colors.textLink};
`;
interface AccordionProps {
  data: any;
}

const Accordion = ({ data }: AccordionProps) => {
  const [clicked, setClicked] = useState(false);
  const theme = useTheme();

  const toggle = (index: any) => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(false);
    }

    setClicked(index);
  };

  return (
    <AccordionSection clicked={clicked}>
      <Container>
        {data.map((item: any, index: any) => {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                background: theme.colors.bgLight,
                borderRadius: "8px",
              }}
            >
              <Wrap onClick={() => toggle(index)} key={index} clicked={clicked}>
                {item.topic}

                {clicked === index ? <StyledMinusIcon /> : <StyledPlusIcon />}
              </Wrap>
              {clicked === index ? (
                <Fade>
                  <Dropdown clicked={clicked}>{item.info}</Dropdown>
                </Fade>
              ) : null}
            </div>
          );
        })}
      </Container>
    </AccordionSection>
  );
};

export default Accordion;
