import moment from "moment";

const SetDemoGraphComponent = (data: any, actions: any) => {
  const date = moment(data.BirthDate).format("MM/DD/YYYY");
  const formattedDate = moment(Date.parse(date)).format("MM/DD/YYYY");
  actions.setValue("FirstName", data.FirstName);
  actions.setValue("LastName", data.LastName);
  actions.setValue("BirthDate", formattedDate);
  actions.setValue("gender", data.Gender);
  actions.setValue("streetAddressOne", data.StreetAddressOne);
  actions.setValue("streetAddressTwo", data.StreetAddressTwo);
  actions.setValue("City", data.City);
  actions.setValue("LivingState", data.State);
  actions.setValue("ZipCode", data.ZipCode);
  actions.setValue("PhoneNumber", data.MobileNumber);
  actions.setValue("EmailAddress", data.EmailAddress);
  actions.setValue("Last4SSN", data.SSN);
  actions.setValue("StreetAddress", data.Address);
};

export default SetDemoGraphComponent;
