import { useEffect, useState } from "react";
import { Typography } from "constants/Typography/Typography";
import { FontSize } from "constants/Fonts";
import BackForwardButton from "common/BackForwardButton";
import licenseFront from "../../assets/svgs/license_front_visual.svg";
import licenseBack from "../../assets/svgs/license_back_visual.svg";
import BulletPointsComponent from "common/BulletPointsComponent";
import ellipse from "../../assets/svgs/ellipse.svg";
import HorizontallyAligned from "common/HorizontallyAligned";
import PopupComponent from "common/Popup/PopupComponent";
import ProcessingUpload from "./ProcessingUpload";
import { MoreInfoForm } from "./MoreInfoForm";
import { IContextActions, IContextState } from "store/Context";
import { useApi } from "store/useApi";
import "../style.css";
import { PAGE_TITLES } from "screens/wizardscreen/PageTitles";
import { StyledLink } from "screens/landingPage/newLandingPageComponents/JoinTheProgram";
import { useTheme } from "styled-components";
import useTranslation from "common/TranslationHook/useTranslation";

interface PhotoIDVerificationProps {
  nextStep: () => void;
  backStep: () => void;
  setStepCount: (step: number) => void;
  setDisableTitle: any;
  setPhotoIdSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setTitle: (title: string) => void;
  actions: IContextActions;
  state: IContextState;
}

export const PhotoIDVerification = ({
  backStep,
  setStepCount,
  setPhotoIdSuccess,
  setTitle,
  setDisableTitle,
  actions,
  state,
}: PhotoIDVerificationProps) => {
  const [retry, setRetry] = useState(false);
  const [frontUploaded, setFrontUploaded] = useState(false);
  const [backUploaded, setBackUploaded] = useState(false);
  const [uploadedBothImages, setUploadedBothImages] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [idFront, setIdFront] = useState("");
  const [idBack, setIdBack] = useState("");
  const [fileUploadError, setFileUploadError] = useState(false);
  const [continueBtn, setContinueBtn] = useState(false);
  const [userAccessCode, setUserAccessCode] = useState("");
  const [failedUpload, setFailedUpload] = useState(false);
  const [idVerified, setIdVerified] = useState(false);
  const [uploadAlternateAddress, setUploadAlternateAddress] = useState(false);
  const [attempts, setAttempts] = useState(1);
  const theme = useTheme();
  const { t } = useTranslation("photoidverification");
  const {
    CreateMyChartAccount,
    CreateUserAndConsent,
    auditTrail,
    checkUploadID,
    getTestResult,
    uploadID,
    verifyID,
  } = useApi();

  const bulletPointsData = [t("bulletPoints")[0], t("bulletPoints")[1]];

  const createUserAndConsent = () => {
    CreateUserAndConsent()
      .then((data) => {
        actions.setValue("userAccessCode", userAccessCode);
        setUserAccessCode(data.userAccessCode);
        auditTrail("Successfully created user and consent");
      })
      .catch((error) => {
        auditTrail("CreateUserAndConsent failed, retrying");
        createUserAndConsent();
      });
  };

  useEffect(() => {
    auditTrail("Photo ID");
    createUserAndConsent();
  }, [retry]);

  const handleFrontUpload = (frontBase64: any, fileTooBig: any) => {
    setIdFront(frontBase64);
    setFrontUploaded(true);

    if (frontUploaded && backUploaded) {
      setUploadedBothImages(true);
    }
  };

  const handleBackUpload = (backBase64: any, fileTooBig: any) => {
    setIdBack(backBase64);
    setBackUploaded(true);

    if (frontUploaded && backUploaded) {
      setUploadedBothImages(true);
    }
  };

  const IDVerificationProcess = () => {
    uploadID(userAccessCode, idFront, idBack).then((data) => {
      if (data.success === true) {
        setTimeout(() => {
          checkUploadID(userAccessCode).then((data) => {
            if (
              data.success !== true ||
              data.result === "unknown_state" ||
              data.result === "under_review"
            ) {
              IDVerificationProcess();
              return;
            } else if (
              data.success === true &&
              data.result !== "unknown_state" &&
              data.result !== "under_review"
            ) {
              // auditTrail("Successfully Uploaded ID");
              verifyID(userAccessCode).then((data) => {
                if (data.success === true) {
                  auditTrail("Successfully Verified Photo ID");
                  setIdVerified(true);
                  CreateMyChartAccount().then((response) => {
                    if (response.status === 200 || response.status < 300) {
                      setPhotoIdSuccess(true);
                      setStepCount(PAGE_TITLES.SUCCESS_PAGE.id);
                    }
                  });
                } else {
                  if (
                    data.errorMessage ===
                    "We were able to read the document, but the information does not match the user input. Please confirm the user information matches the ID and try again."
                  ) {
                    setAttempts(2);
                    setUploadAlternateAddress(true);
                    setTitle(t("alternateAddressTitle"));
                    auditTrail("Needed to Upload Additional Address");
                  } else {
                    auditTrail("Unsuccessfully Verified ID");
                    getTestResult(userAccessCode).then((data) => {
                      if (
                        data.scannedUser.DocumentVerificationConfidenceRating <=
                        74
                      ) {
                        auditTrail("ID Verification Score < 74, sent to quiz");
                        setFailedUpload(true);
                        setStepCount(
                          PAGE_TITLES.IDENTITY_VERIFICATION_QUESTIONS.id,
                        );
                      } else {
                        auditTrail(
                          "ID Verification failed, score greater than 75",
                        );
                        let birthday = "";
                        birthday +=
                          data.user.month +
                          "-" +
                          data.user.day +
                          "-" +
                          data.user.year;

                        let capFirstName = data.user.firstName.toUpperCase();
                        let capLastName = data.user.lastName.toUpperCase();

                        if (
                          capFirstName === data.scannedUser.NameFirst &&
                          capLastName === data.scannedUser.NameLast &&
                          birthday === data.scannedUser.DateOfBirth4
                        ) {
                          auditTrail(
                            "ID Verification not passed, but Name and DOB match",
                          );
                          setIdVerified(true);
                          CreateMyChartAccount().then((response) => {
                            if (
                              response.status === 200 ||
                              response.status < 300
                            ) {
                              setPhotoIdSuccess(true);
                              setStepCount(PAGE_TITLES.SUCCESS_PAGE.id);
                            }
                          });
                        } else {
                          auditTrail(
                            "Name and DOB do not match, sent to Identity Quiz",
                          );
                          setStepCount(
                            PAGE_TITLES.IDENTITY_VERIFICATION_QUESTIONS.id,
                          );
                        }
                      }
                      setDisableTitle(false);
                      setContinueBtn(false);
                    });
                  }
                }
              });
            } else if (attempts === 1) {
              auditTrail("Failed to upload, retrying with new pictures");
              setAttempts(2);
              setDisableTitle(false);
              setFrontUploaded(false);
              setBackUploaded(false);
              setContinueBtn(false);
              setRetry(true);
            } else {
              auditTrail("2 attempts failed, sent to Identity Quiz");
              setAttempts(3);
              setFailedUpload(true);
              setStepCount(PAGE_TITLES.IDENTITY_VERIFICATION_QUESTIONS.id);
            }
          });
        }, 20000);
      } else {
        auditTrail("Unsuccessfully Uploaded ID");
        if (attempts >= 2) {
          setFailedUpload(true);
          setDisableTitle(false);
          actions.setValue("photoIdFailed", true);
          setStepCount(PAGE_TITLES.ERROR_ID_VERIFY.id);
        } else {
          auditTrail("Failed to upload, retrying with new pictures");
          setAttempts(2);
          setRetry(true);
          setDisableTitle(false);
          setFrontUploaded(false);
          setBackUploaded(false);
          setContinueBtn(false);
        }
      }
    });
  };
  const handleContinue = () => {
    setContinueBtn(true);
    setDisableTitle(true);
    IDVerificationProcess();
  };
  const handleUpdateUser = () => {
    setUploadAlternateAddress(false);
    setTitle(t("popupContent.title"));
    handleContinue();
  };

  if (continueBtn) {
    return (
      <>
        <ProcessingUpload />
      </>
    );
  }
  if (uploadAlternateAddress) {
    return (
      <>
        <MoreInfoForm handleUpdatedUser={handleUpdateUser} actions={actions} />
      </>
    );
  }
  return (
    <div>
      <Typography fontSize={FontSize.large}>{t("introText")}</Typography>
      <BulletPointsComponent
        bulletPoints={bulletPointsData}
        ellipse={ellipse}
      />
      <StyledLink
        className="IdClickableText"
        onClick={() => {
          setShowPopup(true);
        }}
      >
        {t("viewExampleText")}
      </StyledLink>
      <PopupComponent showPopup={showPopup} setShowPopup={setShowPopup} />
      <div className="uploadContainer" style={{ gap: 32, marginTop: 30 }}>
        <HorizontallyAligned
          side="front"
          retry={retry}
          uploaded={frontUploaded}
          handleUpload={handleFrontUpload}
          imageSrc={licenseFront}
          setFileUploadError={setFileUploadError}
        />
        <HorizontallyAligned
          side="back"
          retry={retry}
          uploaded={backUploaded}
          handleUpload={handleBackUpload}
          imageSrc={licenseBack}
          setFileUploadError={setFileUploadError}
        />
      </div>
      <Typography
        style={{ color: fileUploadError ? theme.colors.alertRed : "none" }}
      >
        {t("uploadGuidelines")}
      </Typography>
      {retry && (
        <Typography style={{ color: theme.colors.alertRed }}>
          {t("retryMessage")}
        </Typography>
      )}
      <BackForwardButton
        backAction={backStep}
        forwardAction={handleContinue}
        backVisible={true}
        disabled={!frontUploaded || !backUploaded || fileUploadError}
      />
    </div>
  );
};
