import { Typography } from "constants/Typography/Typography";
import "./style.css";
import "../common/style.css";
import BackForwardButton from "common/BackForwardButton";
import { useEffect, useState } from "react";
import StateSelect from "common/StateSelect";
import RadioButton from "common/RadioButton";
import Checkbox from "common/CheckboxNew";
import { IContextActions, IContextState } from "store/Context";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "./wizardscreen/PageTitles";
import InputField from "common/InputField/InputField";
import PatternInputField from "common/InputField/PatternFormat";
import useTranslation from "common/TranslationHook/useTranslation";

interface SelectShippingAddressProps {
  nextStep: () => void;
  backStep: () => void;
  HBPSelected: boolean;
  T2Selected: boolean;
  visitNeeded: string;
  state: IContextState;
  actions: IContextActions;
  setStepCount: (step: number) => void;
}

export const SelectShippingAddress = ({
  backStep,
  T2Selected,
  visitNeeded,
  HBPSelected,
  setStepCount,
  state,
  actions,
}: SelectShippingAddressProps) => {
  const { DeviceDetail, auditTrail } = useApi();
  const { t } = useTranslation("SelectShippingAddress");

  useEffect(() => {
    auditTrail("SelectShippingAddress");
  }, []);

  const handleContinue = () => {
    if (addressChoice === "Obar") {
      actions.setValue("obar", true);
      const obar: any = true;
      const shippingAddressParam: any = {
        StreetAddressOne: state.streetAddressOne,
        StreetAddressTwo: state.streetAddressTwo,
        City: state.City,
        State: state.LivingState,
        ZipCode: state.ZipCode,
      };
      const businessAddressParam: any = state.businessAddress;

      DeviceDetail({
        obar: obar,
        shippingAddress: shippingAddressParam,
        businessAddress: businessAddressParam,
      }).then((data) => {});

      setStepCount(PAGE_TITLES.CONFIRMATION_PAGE.id);
      return;
    }
    if (addressChoice === "New") {
      const isFormValid = validateBasicForm();
      if (isFormValid) {
        actions.setValue("fullAddress", fullAddress);
        actions.setValue("streetAddressOne", newStreetAddress);
        actions.setValue("streetAddressTwo", newAddressLine);
        actions.setValue("City", newCity);
        actions.setValue("LivingState", newLivingState);
        actions.setValue("ZipCode", newZipCode);
        if (businessAddress) {
          actions.setValue("businessAddress", true);
        }
      }
    }

    setStepCount(PAGE_TITLES.ADDRESS_CONFIRMATION.id);
  };

  const [addressChoice, setAddressChoice] = useState("Default");

  const [newStreetAddress, setNewStreetAddress] = useState("");
  const [newAddressLine, setNewAddressLine] = useState("");
  const [newCity, setNewCity] = useState("");
  const [newLivingState, setNewLivingstate] = useState("");
  const [newZipCode, setNewZipCode] = useState("");
  const [businessAddress, setBusinessAddress] = useState(false);

  const userAddress = (
    <div>
      {state.FirstName} {state.LastName}
      <br />
      {state.streetAddressOne}
      {state.streetAddressTwo !== "" &&
        state.streetAddressTwo !== null &&
        state.streetAddressTwo !== "null" &&
        state.streetAddressTwo &&
        " " + state.streetAddressTwo}
      , {state.City}, {state.LivingState} {state.ZipCode}
    </div>
  );

  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [newStreetAddress, newAddressLine, newCity, newLivingState, newZipCode]);

  const checkFormValidity = () => {
    return (
      newStreetAddress.trim() !== "" &&
      newCity.trim() !== "" &&
      newLivingState.trim() !== "" &&
      newZipCode.trim() !== ""
    );
  };

  const [isFormValid, setIsFormValid] = useState(false);

  const [streetAddressError, setStreetAddressError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [livingstateError, setLivingstateError] = useState(false);
  const [zipCodeError, setZipCodeError] = useState(false);
  const [birthdateError, setBirthdateError] = useState(false);
  const [last4SSNError, setLast4SSNError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [fullAddress, setFullAddress] = useState("");

  const updateFullAddress = () => {
    const combinedAddress: any = newAddressLine
      ? `${newStreetAddress}, ${newAddressLine}`
      : newStreetAddress;
    setFullAddress(combinedAddress);
  };

  const handleCheckBox = () => {
    setBusinessAddress(!businessAddress);
  };

  const onChange = (event: any) => {
    const { name, value } = event.target;

    switch (name) {
      case "StreetAddress":
        setNewStreetAddress(value);
        if (value.trim() !== "") setStreetAddressError(false);
        break;
      case "AddressLine":
        setNewAddressLine(value);
        break;
      case "City":
        setNewCity(value);
        if (value.trim() !== "") setCityError(false);
        break;
      case "LivingState":
        setNewLivingstate(value);
        if (value.trim() !== "") setLivingstateError(false);
        break;
      case "ZipCode":
        setNewZipCode(value);
        if (value.trim() !== "") setZipCodeError(false);

        break;
      default:
        break;
    }
    if (name === "StreetAddress" || name === "AddressLine") {
      updateFullAddress();
    }
  };

  const validateBasicForm = () => {
    let isValid = true;
    if (state.streetAddress === "") {
      setStreetAddressError(true);
      isValid = false;
    } else {
      setStreetAddressError(false);
    }
    if (state.City === "") {
      setCityError(true);
      isValid = false;
    } else {
      setCityError(false);
    }
    if (newLivingState === "") {
      setLivingstateError(true);
      isValid = false;
    } else {
      setLivingstateError(false);
    }
    if (newZipCode === "") {
      setZipCodeError(true);
      isValid = false;
    } else {
      setZipCodeError(false);
    }
    return isValid;
  };

  return (
    <div>
      {HBPSelected && T2Selected ? (
        <Typography>{t("shippingAddress.bothSelected")}</Typography>
      ) : HBPSelected ? (
        <Typography>{t("shippingAddress.HBPSelected")}</Typography>
      ) : (
        <Typography>
          <Typography>{t("shippingAddress.T2Selected")}</Typography>
        </Typography>
      )}
      <div className="CGMUse-radio-group" style={{ margin: "40px 0px" }}>
        <RadioButton
          value="Default"
          id="defaultInput"
          checked={addressChoice === "Default"}
          onChange={() => {
            setAddressChoice("Default");
          }}
          label={userAddress}
        />
        <RadioButton
          value="New"
          id="newInput"
          checked={addressChoice === "New"}
          onChange={() => {
            setAddressChoice("New");
          }}
          label={t("addressOptions.newAddress")}
        />
      </div>
      {addressChoice === "New" && (
        <div>
          <div className="input-field-div">
            <InputField
              label={t("formLabels.streetAddress")}
              name="StreetAddress"
              error={streetAddressError}
              value={newStreetAddress}
              onChange={onChange}
            />
            <InputField
              label={t("formLabels.addressLine2")}
              name="AddressLine"
              value={newAddressLine}
              onChange={onChange}
            />

            <div className="input-field-wrapper">
              <InputField
                label={t("formLabels.city")}
                name="City"
                value={newCity}
                onChange={onChange}
                error={cityError}
              />
              <StateSelect
                livingState={newLivingState}
                onChange={onChange}
                livingstateError={livingstateError}
              />
            </div>
            <PatternInputField
              label={t("formLabels.zipCode")}
              name="ZipCode"
              value={newZipCode}
              format="#####"
              error={zipCodeError}
              onChange={onChange}
              width="47%"
            />
            <Checkbox
              checked={businessAddress}
              onChange={handleCheckBox}
              label={t("formLabels.businessAddress")}
              error={false}
              marginTop={true}
            />
          </div>
        </div>
      )}
      <></>
      {!visitNeeded && state.directEnroll && (
        <>
          <Typography style={{ marginBottom: "24px" }}>
            {t("OBarOption.obarLocationPrompt")}
          </Typography>

          <RadioButton
            value="Obar"
            id="obarInput"
            checked={addressChoice === "Obar"}
            onChange={() => {
              setAddressChoice("Obar");
            }}
            label={t("OBarOption.obarLabel")}
          />
        </>
      )}
      <BackForwardButton
        backAction={backStep}
        forwardAction={handleContinue}
        backVisible={true}
        frontVisible={true}
        disabled={addressChoice === "New" && !isFormValid}
      />
    </div>
  );
};
