import { Typography } from "constants/Typography/Typography";
import BackForwardButton from "common/BackForwardButton";
import { useEffect } from "react";
import { IContextState } from "store/Context";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "../PageTitles";
import useTranslation from "common/TranslationHook/useTranslation";

interface ProgramConsentDetailsProps {
  state: IContextState;
  setStepCount: (step: number) => void;
  setPrevStepDevice: (value: number) => void;
  HBPSelected: boolean;
  T2Selected: boolean;
  prevStep: number;
}

export const ProgramConsentDetails = ({
  state,
  setStepCount,
  HBPSelected,
  prevStep,
  setPrevStepDevice,
}: ProgramConsentDetailsProps) => {
  const { auditTrail, PatientProgress } = useApi();
  const { t } = useTranslation("programConsentDetails");
  useEffect(() => {
    const attribution3 = [
      "MEDICARE",
      "MEDICARE PART B",
      "OHP MEDICARE ADVANTAGE PLAN",
      "HUMANA GOLD",
      "HUMANA MANAGED MEDICARE GENERIC",
      "HUMANA MANAGED MEDICARE GOLDPLUS",
      "HUMANA MANAGED MEDICARE HMO",
      "HUMANA MANAGED MEDICARE PPO",
      "HUMANA MANAGED MEDICARE SNP",
      "HUMANA TOTAL CARE ADVANTAGE",
    ];
    const sponsor: any = state.sponsor;
    if (attribution3.includes(sponsor)) {
      setStepCount(PAGE_TITLES.CONSOLIDATED_CONSENT.id);
    }
  }, []);

  const handleContinue = () => {
    console.log("prevStep", prevStep);
    if (state.needToConsent) {
      console.log("ProgramConsentDetails needToConsent");
      setStepCount(PAGE_TITLES.CONSOLIDATED_CONSENT.id);
    } else {
      if (prevStep === PAGE_TITLES.PROGRAM_SELECTION.id) {
        if (HBPSelected) {
          console.log("ProgramConsentDetails HBPSelected");

          setStepCount(PAGE_TITLES.CUFF_SIZE_SELECTION.id);
        } else {
          console.log("ProgramConsentDetails GLUCOMETER_SELECTION");

          setStepCount(PAGE_TITLES.GLUCOMETER_SELECTION.id);
        }
      } else if (prevStep === PAGE_TITLES.EXCLUSIONARY_CRITERIA.id) {
        console.log("ProgramConsentDetails CONFIRMATION_PAGE");

        setStepCount(PAGE_TITLES.CONFIRMATION_PAGE.id);
      }
    }
    PatientProgress("SignedCostConsent");
  };

  useEffect(() => {
    auditTrail("Patient financial eligibility error");
    setPrevStepDevice(PAGE_TITLES.PROGRAM_COST_CONSENT.id);
  }, []);

  return (
    <div>
      <div style={{ marginBottom: 40 }}>
        <Typography mb="16px">{t("programConsentDetailsText1")}</Typography>
        <Typography>
          {t("programConsentDetailsText2")} <b>{t("noCost")}</b>
        </Typography>
        <Typography>
          {t("programConsentDetailsText3")}
          <b>{t("noCost")}</b>
        </Typography>
      </div>

      <BackForwardButton forwardAction={handleContinue} backVisible={false} />
    </div>
  );
};
