import { Typography } from "constants/Typography/Typography";
import "./style.css";
import { FontFamily, FontSize, FontStyle, FontWeight } from "constants/Fonts";
import useTranslation from "common/TranslationHook/useTranslation";

interface ProgramConsentTextProps {
  forceStaticHTMLSupport?: boolean;
}

const ProgramConsentText = ({
  forceStaticHTMLSupport = false,
}: ProgramConsentTextProps) => {
  const { t } = useTranslation("programconsenttext");

  const TypographyOrP = ({ children, ...rest }: any) =>
    forceStaticHTMLSupport ? (
      <p>{children}</p>
    ) : (
      <Typography {...rest}>{children}</Typography>
    );

  return (
    <>
      <TypographyOrP fontSize="small">
        <Typography fontWeight={FontWeight.bold}>
          {t("programConsentHeaderTypeIIDiabetes")}
        </Typography>
        <p>{t("programConsentTermsDescriptionTypeIIDiabetes")}</p>
        <p>{t("programConsentTermsDescriptionDigitalMedicine")}</p>
        <Typography fontStyle="h4">{t("informedConsent.title")}</Typography>
        <p>
          <span>{t("intro.text")}</span>
          <strong>
            <span>{t("servicesTitle.text")}</span>
          </strong>
          <span>{t("description.text")}</span>
          <strong>
            <span>{t("careTeamTitle.text")}</span>
          </strong>
          <span>{t("servicesEnd.text")}</span>
        </p>
        <ul>
          <li>
            <strong>
              <u>
                <span>{t("enrollingPhysician.title")}</span>
              </u>
            </strong>
            <strong>
              <span>:</span>
            </strong>
            <span> {t("enrollingPhysician.descriptionA")}</span>
            <strong>
              <span> {t("enrollingPhysician.title")}</span>
            </strong>
            <span>{t("enrollingPhysician.descriptionB")}</span>
          </li>
          <li>
            <strong>
              <u>
                <span>{t("orderingProvider.title")}</span>
              </u>
            </strong>
            <u>
              <span>:</span>
            </u>
            <span> {t("orderingProvider.descriptionA")}</span>
            <strong>
              <span>{t("orderingProvider.title")}</span>
            </strong>
            <span>{t("orderingProvider.descriptionB")}</span>
            <span></span>
          </li>
          <li>
            <strong>
              <u>
                <span>{t("clinician.title")}</span>
              </u>
            </strong>
            <strong>
              <span>:</span>
            </strong>
            <span>{t("clinician.descriptionA")}</span>
            <strong>
              <span>{t("clinician.title")}</span>
            </strong>
            <span>{t("clinician.descriptionB")}</span>
          </li>
          <li>
            <strong>
              <u>
                <span>{t("healthCoaches.title")}</span>
              </u>
            </strong>
            <strong>
              <span>: </span>
            </strong>
            <span></span>
            <strong>
              <span> "Coaches" </span>
            </strong>
            <span>{t("healthCoaches.description")}</span>
          </li>
        </ul>
        <p>
          <span>{t("emergency.text")}</span>
        </p>
        <p>
          <span>{t("physicianVerification.text")}</span>
        </p>
        <p>
          <span>{t("careTeamReview.text")}</span>
        </p>
        <p>
          <span>{t("stateRequirements.text")}</span>
          <strong>
            <span>{t("continueCare.text")}</span>
          </strong>
          <span>{t("contactInfo.text")}</span>
          <strong>
            <span>{t("emergencyInstructions.text")}</span>
          </strong>
          <span>.</span>
          <strong>
            <u>
              <span
                style={{
                  fontFamily: "Calibri Light",
                  textTransform: "uppercase",
                }}
              >
                {t("seekMedicalAdvice.text")}
              </span>
            </u>
          </strong>
          <strong>
            <u>
              <span>.</span>
            </u>
          </strong>
        </p>
        <p>
          <span>{t("withdraw.text")}</span>
        </p>
        <p>
          <span>{t("monitoringDevice.text")}</span>
          <strong>
            <span>{t("monitoringDevice.title")}</span>
          </strong>
          <span>{t("monitoringDevice.description")}</span>
          <strong>
            <span>"Monitored Health Data"</span>
          </strong>
          <span>{t("monitoredHealthData.text")}</span>
          <strong>
            <span>"Digital Medicine App"</span>
          </strong>
          <span> {t("monitoredHealthData.discriptionA")}</span>
          <strong>
            <span>"MyOchsner App"</span>
          </strong>
          <span>{t("monitoredHealthData.discriptionB")}</span>
        </p>
        <p>
          <span>{t("programTechnology.text")}</span>
        </p>
        <p>
          <u>
            <span>{t("providerPlatform.text")}</span>
          </u>
          <strong>
            <u>
              <span style={{ textDecoration: "underline" }}>
                "Provider Platform"
              </span>
            </u>
          </strong>
          <u>
            <span>{t("providerPlatform.discriptionA")}</span>
          </u>
          <strong>
            <u>
              <span>Providers</span>
            </u>
          </strong>
          <u>
            <span>{t("providerPlatform.discriptionB")}</span>
          </u>
          <strong>
            <u>
              <span>"Program Technology"</span>
            </u>
          </strong>
          <u>
            <span>{t("providerPlatform.discriptionC")}</span>
          </u>
        </p>
        <p>
          <span>{t("noWarranty.text")}</span>
        </p>
        <p>
          <span>{t("noLiability.text")}</span>
        </p>
        <p>
          <span>{t("availableServices.text")}</span>
        </p>
        <p>
          <span>{t("selfMonitoring.text")}</span>
          <span>{t("cooperationWarning.text")}</span>
          <span></span>
          <span>{t("accessTermination.text")}</span>
        </p>
        <p>
          <span>{t("confidentiality.text")}</span>
        </p>
        <p>
          <span>{t("communicationMethods.text")}</span>
        </p>
        <p>
          <span>{t("enrollmentProcess.text")}</span>
        </p>
        <p>
          <span>{t("thirdPartyVendors.text")}</span>
        </p>
        <p>
          <span>{t("thirdPartyVendorsListIntro.text")}</span>
        </p>
        <ul>
          <li>
            <span>{t("patientPortal.title")}</span>
            <ul
              style={{
                marginRight: "0px",
                marginLeft: "0px",
                paddingLeft: "0px",
                listStyle: "circle",
              }}
            >
              <li style={{ marginLeft: "29.3px", paddingLeft: "6.7px" }}>
                <a
                  href="https://my.ochsner.org/PRD/default.asp?mode=stdfile&option=termsandconditions"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <u>
                    <span
                      style={{ fontFamily: "Calibri Light", color: "#000000" }}
                    >
                      https://my.ochsner.org/PRD/default.asp?mode=stdfile&option=termsandconditions
                    </span>
                  </u>
                </a>
              </li>
              <li style={{ marginLeft: "29.3px", paddingLeft: "6.7px" }}>
                <a
                  href="https://my.ochsner.org/PRD/default.asp?mode=stdfile&option=hlthprivacy"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <u>
                    <span
                      style={{ fontFamily: "Calibri Light", color: "#000000" }}
                    >
                      https://my.ochsner.org/PRD/default.asp?mode=stdfile&amp,opx"ion=hlthprivacy
                    </span>
                  </u>
                </a>
                <span></span>
              </li>
            </ul>
          </li>
          <li>
            <span>{t("deviceHealthLabs.title")}</span>
            <ul
              style={{
                marginRight: "0px",
                marginLeft: "0px",
                paddingLeft: "0px",
                listStyle: "circle",
              }}
            >
              <li style={{ marginLeft: "29.3px", paddingLeft: "6.7px" }}>
                <a
                  href="https://ihealthlabs.com/about-us/terms-and-conditions/"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <u>
                    <span
                      style={{ fontFamily: "Calibri Light", color: "#000000" }}
                    >
                      https://ihealthlabs.com/about-us/terms-and-conditions/
                    </span>
                  </u>
                </a>
              </li>
              <li style={{ marginLeft: "29.3px", paddingLeft: "6.7px" }}>
                <a
                  href="https://ihealthlabs.com/pages/privacy-policy"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <u>
                    <span
                      style={{ fontFamily: "Calibri Light", color: "#000000" }}
                    >
                      https://ihealthlabs.com/pages/privacy-policy
                    </span>
                  </u>
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <p>
          <strong>
            <u>
              <span style={{ textDecoration: "underline" }}>
                {t("noGuarantee.text")}
              </span>
            </u>
          </strong>
        </p>
        <p>
          <span>{t("stateLimitations.text")}</span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <u>
            <span>{t("healthServicesDiscretion.text")}</span>
          </u>
          <u>
            <span></span>
          </u>
          <u>
            <span style={{ textDecoration: "underline" }}>
              {t("liabilityDisclaimer.text")}
            </span>
          </u>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>{t("discretion.text")}</span>
        </p>
        <p>
          <span>{t("programChange.text")}</span>
          <span></span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span></span>
        </p>
      </TypographyOrP>
      <TypographyOrP>
        <Typography fontWeight={FontWeight.bold} marginTop="0px">
          {t("hypertensionConsent.title")}
        </Typography>
        <p>{t("termsDescription.text")}</p>
        <p>{t("viewConditionsDescription.text")}</p>
        <Typography fontStyle="h4">{t("informedConsent.title")}</Typography>
        <p>
          <span>{t("intro.text")}</span>
          <strong>
            <span>"Health Services"</span>
          </strong>
          <span>{t("description.text")}</span>
          <strong>
            <span>"Care Team"</span>
          </strong>
          <span>{t("servicesEnd.text")}</span>
        </p>
        <ul>
          <li>
            <strong>
              <u>
                <span>{t("enrollingPhysician.title")}</span>
              </u>
            </strong>
            <strong>
              <span>:</span>
            </strong>
            <span>{t("enrollingPhysician.descriptionA")}</span>
            <strong>
              <span> "Enrolling Physician" </span>
            </strong>
            <span>{t("enrollingPhysician.descriptionB")}</span>
          </li>
          <li>
            <strong>
              <u>
                <span>{t("orderingProviderHT.title")}</span>
              </u>
            </strong>
            <u>
              <span>:</span>
            </u>
            <span>{t("orderingProviderHT.descriptionA")}</span>
            <strong>
              <span> "Ordering Provider" </span>
            </strong>
            <span>{t("orderingProviderHT.descriptionB")}</span>
            <span></span>
          </li>
          <li>
            <strong>
              <u>
                <span>{t("clinician.title")}</span>
              </u>
            </strong>
            <strong>
              <span>:</span>
            </strong>
            <span>{t("clinician.descriptionA")}</span>
            <strong>
              <span>"Clinician"</span>
            </strong>
            <span>{t("clinician.descriptionB")}</span>
          </li>
          <li>
            <strong>
              <u>
                <span>{t("healthCoaches.title")}</span>
              </u>
            </strong>
            <strong>
              <span>: </span>
            </strong>
            <span></span>
            <strong>
              <span> "Coaches" </span>
            </strong>
            <span>{t("healthCoaches.description")}</span>
          </li>
        </ul>
        <p>
          <span>{t("emergency.text")}</span>
        </p>
        <p>
          <span>{t("physicianVerification.text")}</span>
        </p>
        <p>
          <span>{t("careTeamReview.text")}</span>
        </p>
        <p>
          <span>{t("stateRequirements.text")}</span>
          <strong>
            <span style={{ fontStyle: "bold" }}>{t("continueCare.text")}</span>
          </strong>
          <span>{t("contactInfo.text")}</span>
          <strong>
            <span>{t("emergencyInstructionsHT.text")}</span>
          </strong>
          <span>.</span>
          <strong>
            <u>
              <span
                style={{
                  textTransform: "uppercase",
                }}
              >
                {t("seekMedicalAdvice.text")}
              </span>
            </u>
          </strong>
          <strong>
            <u>
              <span>.</span>
            </u>
          </strong>
        </p>
        <p>
          <span>{t("withdraw.text")}</span>
        </p>
        <p>
          <span>
            {t("monitoringDeviceHT.text")}
            The Digital Medicine Program helps you manage your hypertension with
            some simple features. You must have a personal monitoring device (a{" "}
          </span>
          <strong>
            <span>"Monitoring Device"</span>
          </strong>
          <span>{t("monitoringDeviceHT.description")}</span>
          <strong>
            <span>"Monitored Health Data"</span>
          </strong>
          <span>{t("monitoredHealthDataHT.text")}</span>
          <strong>
            <span>"MyOchsner App"</span>
          </strong>
          <span>{t("monitoredHealthData.discriptionB")}</span>
        </p>
        <p>
          <span>{t("programTechnology.text")}</span>
        </p>
        <p>
          <u>
            <span>{t("providerPlatform.text")}</span>
          </u>
          <strong>
            <u>
              <span style={{ textDecoration: "underline" }}>
                "Provider Platform"
              </span>
            </u>
          </strong>
          <u>
            <span>{t("providerPlatform.discriptionA")}</span>
          </u>
          <strong>
            <u>
              <span>Providers</span>
            </u>
          </strong>
          <u>
            <span>{t("providerPlatform.discriptionB")}</span>
          </u>
          <strong>
            <u>
              <span>"Program Technology"</span>
            </u>
          </strong>
          <u>
            <span>{t("providerPlatform.discriptionC")}</span>
          </u>
        </p>
        <p>
          <span>{t("noWarranty.text")}</span>
        </p>
        <p>
          <span>{t("liabilityDisclaimerDetailed.text")}</span>
        </p>
        <p>
          <span>{t("availableServicesPolicy.text")}</span>
        </p>
        <p>
          <span>{t("selfMonitoringAgreement.text")}</span>
          <span>{t("failureToCooperatePolicy.text")}</span>
          <span></span>
          <span>{t("accessTerminationPolicyDetailed.text")}</span>
        </p>
        <p>
          <span>{t("confidentialityAgreementDetailed.text")}</span>
        </p>
        <p>
          <span>{t("communicationMethodsPolicy.text")}</span>
        </p>
        <p>
          <span>{t("enrollmentProcessOverview.text")}</span>
        </p>
        <p>
          <span>{t("thirdPartyVendorsAgreementDetailed.text")}</span>
        </p>
        <p>
          <span>{t("thirdPartyVendorsListIntro.text")}</span>
        </p>
        <ul>
          <li>
            <span>{t("patientPortal.text")}</span>
            <ul
              style={{
                marginRight: "0px",
                marginLeft: "0px",
                paddingLeft: "0px",
                listStyle: "circle",
              }}
            >
              <li style={{ marginLeft: "29.3px", paddingLeft: "6.7px" }}>
                <a
                  href="https://my.ochsner.org/PRD/default.asp?mode=stdfile&option=termsandconditions"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <u>
                    <span style={{ color: "#000000" }}>
                      https://my.ochsner.org/PRD/default.asp?mode=stdfile&option=termsandconditions
                    </span>
                  </u>
                </a>
              </li>
              <li style={{ marginLeft: "29.3px", paddingLeft: "6.7px" }}>
                <a
                  href="https://my.ochsner.org/PRD/default.asp?mode=stdfile&option=hlthprivacy"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <u>
                    <span style={{ color: "#000000" }}>
                      https://my.ochsner.org/PRD/default.asp?mode=stdfile&amp,opx"ion=hlthprivacy
                    </span>
                  </u>
                </a>
                <span></span>
              </li>
            </ul>
          </li>
          <li>
            <span>{t("deviceHealthLabs.title")}</span>
            <ul
              style={{
                marginRight: "0px",
                marginLeft: "0px",
                paddingLeft: "0px",
                listStyle: "circle",
              }}
            >
              <li style={{ marginLeft: "29.3px", paddingLeft: "6.7px" }}>
                <a
                  href="https://ihealthlabs.com/about-us/terms-and-conditions/"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <u>
                    <span style={{ color: "#000000" }}>
                      https://ihealthlabs.com/about-us/terms-and-conditions/
                    </span>
                  </u>
                </a>
              </li>
              <li style={{ marginLeft: "29.3px", paddingLeft: "6.7px" }}>
                <a
                  href="https://ihealthlabs.com/pages/privacy-policy"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <u>
                    <span style={{ color: "#000000" }}>
                      https://ihealthlabs.com/pages/privacy-policy
                    </span>
                  </u>
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <p>
          <strong>
            <u>
              <span style={{ textDecoration: "underline" }}>
                {t("noGuarantee.text")}
              </span>
            </u>
          </strong>
        </p>
        <p>
          <span>{t("stateLimitations.text")}</span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <u>
            <span>{t("healthServicesDiscretion.text")}</span>
          </u>
          <u>
            <span></span>
          </u>
          <u>
            <span style={{ textDecoration: "underline" }}>
              {t("liabilityDisclaimer.text")}
            </span>
          </u>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>{t("discretion.text")}</span>
        </p>
        <p>
          <span>{t("programChange.text")}</span>
          <span></span>
        </p>
      </TypographyOrP>
      <TypographyOrP>
        <Typography fontWeight={FontWeight.bold} marginTop="0px">
          {t("programConsentHeaderWeightManagement")}
        </Typography>
        <p>{t("programConsentTermsDescriptionWeightManagement")}</p>
        <p>{t("programConsentTermsViewConditionsWeightManagement")}</p>
        <Typography fontStyle="h4">{t("informedConsent.title")}</Typography>
        <p>
          <span>{t("intro.text")}</span>
          <strong>
            <span>"Health Services"</span>
          </strong>
          <span>{t("description.text")})</span>
          <strong>
            <span>"Care Team"</span>
          </strong>
          <span>{t("servicesEnd.text")}</span>
        </p>
        <ul>
          <li>
            <strong>
              <u>
                <span>{t("enrollingPhysicianWeightManagement.title")}</span>
              </u>
            </strong>
            <strong>
              <span>:</span>
            </strong>
            <span>{t("enrollingPhysicianWeightManagement.description")}</span>
          </li>
          <li>
            <strong>
              <u>
                <span>{t("orderingProviderWeightManagement.title")}</span>
              </u>
            </strong>
            <u>
              <span>:</span>
            </u>
            <span> {t("orderingProviderWeightManagement.description")}</span>
            <strong>
              <span> "Ordering Provider" </span>
            </strong>
            <span>{t("orderingProviderWeightManagement.descriptionB")}</span>
            <span></span>
          </li>
          <li>
            <strong>
              <u>
                <span>{t("clinicianWeightManagement.title")}</span>
              </u>
            </strong>
            <strong>
              <span>:</span>
            </strong>
            <span>{t("clinicianWeightManagement.descriptionA")}</span>
            <strong>
              <span>"Clinician"</span>
            </strong>
            <span>{t("clinicianWeightManagement.descriptionB")}</span>
          </li>
          <li>
            <strong>
              <u>
                <span>Health Coaches</span>
              </u>
            </strong>
            <strong>
              <span>: </span>
            </strong>
            <span></span>
            <strong>
              <span> "Coaches" </span>
            </strong>
            <span>{t("healthCoachesWeightManagement.description")}</span>
          </li>
          <li>
            <strong>
              <u>
                <span>{t("registeredDietitian.title")}</span>
              </u>
            </strong>
            <strong>
              <span>: </span>
            </strong>
            <span>{t("registeredDietitian.description")}</span>
          </li>
        </ul>
        <p>
          <span>{t("participationDescriptionWeightManagement.text")}</span>
        </p>
        <p>
          <span>{t("physicianVerificationWeightManagement.text")}</span>
        </p>
        <p>
          <span>{t("careTeamReviewWeightManagement.text")}</span>
        </p>
        <p>
          <span>{t("stateRequirementsWeightManagement.text")}</span>
          <strong>
            <span style={{ fontStyle: "bold" }}>
              {t("continueCareWeightManagement.text")}
            </span>
          </strong>
          <span>{t("continueCareWeightManagement.text2")}</span>
          <strong>
            <span>{t("emergencyInstructionsHT.text")}</span>
          </strong>
          <span>.</span>
          <strong>
            <u>
              <span
                style={{
                  textTransform: "uppercase",
                  textDecoration: "underline",
                }}
              >
                {t("seekMedicalAdviceWeightManagement.text")}
              </span>
            </u>
          </strong>
          <strong>
            <u>
              <span>.</span>
            </u>
          </strong>
        </p>
        <p>
          <span>{t("withdrawWeightManagement.text")}</span>
        </p>
        <p>
          <span>{t("monitoringDeviceWeightManagement.text")}</span>
        </p>
        <p>
          <span>{t("programTechnologyWeightManagement.text")}</span>
        </p>
        <p>
          <u>
            <span>{t("providerPlatform.text")}</span>
          </u>
          <strong>
            <u>
              <span style={{ textDecoration: "underline" }}>
                "Provider Platform"
              </span>
            </u>
          </strong>
          <u>
            <span>{t("providerPlatform.discriptionA")}</span>
          </u>
          <strong>
            <u>
              <span>Providers</span>
            </u>
          </strong>
          <u>
            <span>{t("providerPlatform.discriptionB")}</span>
          </u>
          <strong>
            <u>
              <span style={{ textDecoration: "underline" }}>
                "Program Technology"
              </span>
            </u>
          </strong>
          <u>
            <span>{t("providerPlatform.discriptionC")}</span>
          </u>
        </p>
        <p>
          <span>{t("noWarranty.text")}</span>
        </p>
        <p>
          <span>{t("liabilityDisclaimerDetailed.text")}</span>
        </p>
        <p>
          <span>{t("availableServicesPolicy.text")}</span>
        </p>
        <p>
          <span>{t("selfMonitoringAgreement.text")}</span>
          <span>{t("failureToCooperatePolicy.text")}</span>
          <span></span>
          <span>{t("accessTerminationPolicyDetailed.text")}</span>
        </p>
        <p>
          <span>{t("confidentialityAgreementDetailed.text")}</span>
        </p>
        <p>
          <span>{t("communicationMethodsPolicy.text")}</span>
        </p>
        <p>
          <span>{t("enrollmentProcessOverview.text")}</span>
        </p>
        <p>
          <span>{t("thirdPartyVendorsAgreementDetailed.text")}</span>
        </p>
        <p>
          <span>{t("thirdPartyVendorsListIntro.text")}</span>
        </p>
        <ul>
          <li>
            <span>{t("patientPortal.title")}</span>
            <ul
              style={{
                marginRight: "0px",
                marginLeft: "0px",
                paddingLeft: "0px",
                listStyle: "circle",
              }}
            >
              <li style={{ marginLeft: "29.3px", paddingLeft: "6.7px" }}>
                <a
                  href="https://my.ochsner.org/PRD/default.asp?mode=stdfile&option=termsandconditions"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <u>
                    <span style={{ color: "#000000" }}>
                      https://my.ochsner.org/PRD/default.asp?mode=stdfile&option=termsandconditions
                    </span>
                  </u>
                </a>
              </li>
              <li style={{ marginLeft: "29.3px", paddingLeft: "6.7px" }}>
                <a
                  href="https://my.ochsner.org/PRD/default.asp?mode=stdfile&option=hlthprivacy"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <u>
                    <span style={{ color: "#000000" }}>
                      https://my.ochsner.org/PRD/default.asp?mode=stdfile&amp,opx"ion=hlthprivacy
                    </span>
                  </u>
                </a>
                <span></span>
              </li>
            </ul>
          </li>
        </ul>
        <p>
          <strong>
            <u>
              <span style={{ textDecoration: "underline" }}>
                {t("noGuarantee.text")}
              </span>
            </u>
          </strong>
        </p>
        <p>
          <span>{t("stateLimitations.text")}</span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <u>
            <span>{t("healthServicesDiscretion.text")}</span>
          </u>
          <u>
            <span></span>
          </u>
          <u>
            <span style={{ textDecoration: "underline" }}>
              {t("liabilityDisclaimer.text")}
            </span>
          </u>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>{t("discretion.text")}</span>
        </p>
        <p>
          <span>{t("programChange.text")}</span>
          <span></span>
        </p>
      </TypographyOrP>
    </>
  );
};

export default ProgramConsentText;
