import { StickyContainer } from "react-sticky";
import styled from "styled-components";

export const MainContainer = styled(StickyContainer)`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    box-sizing: border-box;
  }

  @media (max-width: 1024px) and (min-width: 768px) {
    box-sizing: border-box;
  }
`;

export const BottomTextDivMobile = styled.div`
  display: none;
  visibility: hidden;

  @media (max-width: 768px) {
    display: block;
    visibility: visible;
    padding: 48px 20px 32px 20px;
    top: 0px;
    z-index: 1;
  }

  @media (max-width: 1024px) and (min-width: 768px) {
    display: block;
    visibility: visible;
    padding: 48px 20px 32px 30px;
    top: 0px;
    z-index: 1;
    justify-content: center;
    align-items: center;
  }
`;
