import React from "react";
import { Typography } from "constants/Typography/Typography";
import { FontSize, FontWeight } from "constants/Fonts";
import BackForwardButton from "common/BackForwardButton";
import calendarIcon from "../assets/svgs/calendar_icon.svg";
import { ReactComponent as LoadingSpinner } from "../assets/svgs/loadingSpinner.svg";
import { useEffect, useState } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "./wizardscreen/PageTitles";
import { useTheme, styled } from "styled-components";
import { IContextActions, IContextState } from "store/Context";
import useTranslation from "common/TranslationHook/useTranslation";

interface Slot {
  DisplayDate: string;
  ServerDate: string;
  Duration: number;
  ProviderId: string;
  // Add more properties if needed based on your API response
}

interface DataType {
  Slots: Slot[];
  TimeZone: string;
  TimeZoneAbbr: string;
}

const StyledVisitTimeContainer = styled.div<{ active: boolean }>`
  border: 1px solid ${(props) => props.theme.colors.buttonSecondary};
  background-color: ${(props) =>
    props.active
      ? props.theme.colors.buttonSecondary
      : props.theme.colors.textWhite};
  color: ${(props) =>
    props.active
      ? props.theme.colors.textWhite
      : props.theme.colors.textPrimary};
  justify-content: center;
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 25%;
  border-radius: 8px;
  width: 30%;
  transition: all 0.3s;
  &:hover {
    background-color: ${(props) =>
      !props.active && props.theme.colors.tintPrimary10};
    cursor: pointer;
  }
`;
const StyledScheduleLater = styled(Typography)`
  cursor: pointer;
  color: ${(props) => props.theme.colors.textLink};
  transition: all 03s;
  &:hover {
    color: ${(props) => props.theme.colors.textPrimary};
  }
`;

const StyledVisitText = styled.p`
  text-align: center;
  font-family: Montserrat-Medium;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 25.5px */
  margin: 0;
`;

interface ScheduleVideoConsultation_2Props {
  nextStep: () => void;
  backStep: () => void;
  setStepCount: (step: number) => void;
  setScheduleNowChoice: React.Dispatch<React.SetStateAction<boolean>>;
  actions: IContextActions;
  state: IContextState;
}

export const ScheduleVideoConsultation_2 = ({
  backStep,
  actions,
  setStepCount,
  setScheduleNowChoice,
  state,
}: ScheduleVideoConsultation_2Props) => {
  const { auditTrail, GetOpenSlots2, ScheduleAppointment2 } = useApi();

  const [isReady, setIsReady] = useState(true);
  const theme = useTheme();
  const { t } = useTranslation("ScheduleVideoConsultation_2");

  useEffect(() => {
    auditTrail("ScheduleVirtualVisitDateandTime");
  }, []);

  const handleContinue = () => {
    setIsReady(false);
    setScheduleNowChoice(true);
    let wmMedicationAppt = null;
    if (
      state.wmMedication === "Yes" ||
      state.wmMedication === "alreadyTaking"
    ) {
      wmMedicationAppt = true;
    } else if (state.wmMedication === "No") {
      wmMedicationAppt = false;
    }
    ScheduleAppointment2(wmMedicationAppt)
      .then((resp) => {
        actions.setValue("scheduleChoice", "Now");
        console.log(resp);
        setIsReady(true);
        setStepCount(PAGE_TITLES.CONFIRMATION_PAGE.id);
      })
      .catch((err) => {
        const startsWithfunc = (str: string, word: string) => {
          return str.lastIndexOf(word, 0) === 0;
        };
        console.log("went to the catch part!");
        console.log(err);
        const errString = err.toString();
        if (errString.lastIndexOf("Syntax", 0) === 0) {
          actions.setValue("scheduleChoice", "Now");
          setIsReady(true);
          setStepCount(PAGE_TITLES.CONFIRMATION_PAGE.id);
        } else {
          actions.setValue("scheduleChoice", "Later");
          setIsReady(true);
          setErrorLoadingSlots(true);
          setStepCount(PAGE_TITLES.SCHEDULE_VISIT_ERROR.id);
        }
      });
  };

  const [dateSelected, setDateSelected] = useState(false);
  const [timeSelected, setTimeSelected] = useState(false);
  const [timeZone, setTimeZone] = useState("");
  const [timeZoneAbbr, setTimeZoneAbbr] = useState("");
  const [availableDates, setAvailableDates] = useState([""]);
  const [errorLoadingSlots, setErrorLoadingSlots] = useState(false);
  const [timesReady, setTimesReady] = useState(false);
  const [dateChosen, setDateChosen] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [Data, setData] = useState<DataType | null>(null);
  const [allTimings, setAllTimings] = useState<any[]>([]);
  const [availableSlotsInfo, setAvailableSlotsInfo] = useState<any[]>([{}]);
  const [Time, setTime] = useState(null);
  const [inputDateID, setInputDateID] = useState("inputDate-before");

  useEffect(() => {
    window.scrollTo(0, 0);
    GetOpenSlots2()
      .then((data) => {
        let dataAvailableDates: any = [];
        let dataAvailableSlotsInfo: any = [];
        setData(data);
        setTimeZone(data.TimeZone);
        setTimeZoneAbbr(data.TimeZoneAbbr);
        actions.setValue("appointmentTimeZoneAbbr", data.TimeZoneAbbr);
        data.Slots.forEach((element: any) => {
          dataAvailableDates.push(
            moment(element.DisplayDate).format("DD--MM-YYYY").toString(),
          );
          dataAvailableSlotsInfo.push(element);
        });
        setAvailableDates(dataAvailableDates);
        setAvailableSlotsInfo(dataAvailableSlotsInfo);
        setTimesReady(true);
      })
      .catch((err) => {
        console.log(err);
        setErrorLoadingSlots(true);
        setTimeout(() => {
          setStepCount(PAGE_TITLES.SCHEDULE_VISIT_ERROR.id);
        }, 6000);
      });
  }, []);

  const active = (e: any) => {
    setTimeSelected(true);
    setTime(e.currentTarget.id);
    console.log(e.currentTarget.id);
    const selectedSlot = allTimings[e.currentTarget.id];
    console.log(availableSlotsInfo);
    // console.log(allTimings[e.currentTarget.id])
    actions.setValue(
      "appointmentTime",
      moment(selectedSlot.ServerDate, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
    );
    actions.setValue(
      "appointmentTimeDisplay",
      moment(selectedSlot.DisplayDate, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
    );
    actions.setValue("appointmentDateTime", selectedSlot.ServerDate);
    actions.setValue("appointmentDuration", selectedSlot.Duration);
    actions.setValue("providerId", selectedSlot.ProviderId);
  };

  const handleChangeDateCustom = (startDate: any) => {
    setDateChosen(moment(startDate).format("YYYY-MM-DD"));
    setStartDate(startDate);
    actions.setValue("appointmentDate", moment(startDate).format("YYYY-MM-DD"));
    setInputDateID("inputDate-after");
  };

  const CustomInput = React.forwardRef((props: any, ref: any) => (
    <div
      style={{
        color: !dateChosen ? "#757679" : "black",
        fontFamily: "Montserrat-Medium",
      }}
      className="custom-input-container"
      onClick={props.onClick}
      ref={ref}
    >
      {dateChosen ? props.value : props.placeholder}
      <img src={calendarIcon} />
    </div>
  ));

  const getTimeSchedule = (event: any) => {
    const allTimes: any[] | ((prevState: never[]) => never[]) = [];
    if (Data && Data.Slots) {
      Data.Slots.forEach((element: any) => {
        if (
          moment(element.DisplayDate, "YYYY-MM-DD HH:mm:ss").format(
            "DD-MM-YYYY",
          ) === moment(event).format("DD-MM-YYYY")
        ) {
          allTimes.push(element);
        }
      });
      setAllTimings(allTimes);
      console.log(allTimes);
    }
  };
  const ScheduleLater = () => {
    actions.setValue("scheduleChoice", "Later");
    setStepCount(PAGE_TITLES.CONFIRMATION_PAGE.id);
  };

  return (
    <div className="visit">
      <Typography
        fontSize={FontSize.medium}
        dangerouslySetInnerHTML={{
          __html: t("consultationHeader"),
        }}
      />
      <Typography>{t("chooseDateLabel")}</Typography>
      {timesReady ? (
        <DatePicker
          selected={startDate}
          onChange={(startDate) => {
            setDateSelected(true);
            setTimeSelected(false);
            getTimeSchedule(startDate);
            handleChangeDateCustom(startDate);
            setTime(null);
          }}
          placeholderText="Date"
          customInput={<CustomInput placeholder="Date" />}
        />
      ) : (
        <LoadingSpinner
          fill={theme.colors.tertiary}
          className="spinner button-container-loader"
        />
      )}

      {dateSelected && (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: "16px",
              alignSelf: "stretch",
            }}
          >
            <Typography>{t("selectTimeLabel")}</Typography>
            <Typography style={{ flex: "1 0 0", textAlign: "right" }}>
              {t("timeZoneNotice")}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              // flexDirection: "column",
              alignItems: "flex-start",
              gap: "12px",
              // width: "544px",
              flexWrap: "wrap",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {allTimings.length > 0 ? (
              allTimings.map((item, i: any) => {
                return (
                  <StyledVisitTimeContainer
                    key={i}
                    id={i}
                    active={Time == i}
                    onClick={(e) => active(e)}
                  >
                    <StyledVisitText>
                      {moment(item.DisplayDate, "YYYY-MM-DD HH:mm:ss").format(
                        "hh:mm A",
                      )}
                    </StyledVisitText>
                  </StyledVisitTimeContainer>
                );
              })
            ) : (
              <Typography>{t("noAvailableTimesMessage")}</Typography>
            )}
          </div>
        </div>
      )}
      {timesReady ? (
        <StyledScheduleLater
          fontWeight={FontWeight.bold}
          textDecoration="underline"
          onClick={ScheduleLater}
        >
          {t("scheduleLaterLink")}
        </StyledScheduleLater>
      ) : null}
      {isReady ? (
        <BackForwardButton
          backAction={backStep}
          forwardAction={handleContinue}
          backVisible={true}
          frontVisible={true}
          disabled={timeSelected ? false : true}
        />
      ) : (
        <LoadingSpinner
          fill={theme.colors.tertiary}
          className="spinner button-container-loader"
        />
      )}
    </div>
  );
};
