import React from "react";
import { Typography } from "constants/Typography/Typography";
import { FontFamily, FontWeight } from "constants/Fonts";
import { ReactComponent as LoadingSpinner } from "../../assets/svgs/loadingSpinner.svg";
import "./styles.css";
import { useTheme } from "styled-components";
import useTranslation from "common/TranslationHook/useTranslation";
interface ProcessingUploadProps {}

const ProcessingUpload = ({}: ProcessingUploadProps) => {
  const theme = useTheme();
  const { t } = useTranslation("photoidverification");

  return (
    <div className="processingUpload">
      <div className="processingUploadText">
        <Typography
          fontWeight={FontWeight.semiBold}
          fontFamily={FontFamily.secondary}
          mb="24px"
          headingLevel="h3"
        >
          {t("processingUpload.processingIdentification")}
        </Typography>
        <Typography>{t("processingUpload.estimateTimeText")}</Typography>
      </div>
      <div
        style={{
          marginTop: 70,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <LoadingSpinner fill={theme.colors.tertiary} className="spinner" />
      </div>
    </div>
  );
};

export default ProcessingUpload;
