type RequestMethod = "GET" | "POST" | "PUT" | "DELETE";

interface ApiRequestOptions {
  url: string;
  method: RequestMethod;
  body?: object | string | null;
  headers?: Record<string, string>;
}

const makeApiRequest = async ({
  url,
  method,
  body = null,
  headers = {},
}: ApiRequestOptions): Promise<any> => {
  try {
    const fullUrl = `${process.env.REACT_APP_API_URL}${url}`;
    const options: RequestInit = {
      method,
      headers: new Headers({ "Content-Type": "application/json", ...headers }),
    };
    if (body) {
      options.body = typeof body === "object" ? JSON.stringify(body) : body;
    }

    const response = await fetch(fullUrl, options);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    if (response.status === 204) {
      return response.status;
    }
    return response.json();
  } catch (error) {
    if (error instanceof Error) {
      console.error(`Error in making API request: ${error.message}`);
    } else {
      console.error("Unknown error in making API request");
    }
    throw error;
  }
};

export default makeApiRequest;
