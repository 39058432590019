import { Typography } from "constants/Typography/Typography";
import { FontSize } from "constants/Fonts";
import { useEffect } from "react";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "./wizardscreen/PageTitles";
import { IContextState } from "store/Context";
import BackForwardButton from "common/BackForwardButton";
import checkLogo from "../assets/svgs/check_logo.svg";
import ellipse from "../assets/svgs/ellipse.svg";
import BulletPointsComponent from "common/BulletPointsComponent";
import QualificationComponent from "common/QualificationComponent ";
import useTranslation from "common/TranslationHook/useTranslation";
import "./style.css";

interface WizardScreenProps {
  backStep: () => void;
  setStepCount: (step: number) => void;
  state: IContextState;
}

export const WelcomeScreen = ({
  backStep,
  setStepCount,
}: WizardScreenProps) => {
  const { auditTrail } = useApi();
  const { t } = useTranslation("welcomescreentranslate");

  const titleText = t("qualificationTitle");

  useEffect(() => {
    auditTrail("Welcome Screen");
  }, []);

  const handleContinue = () => {
    setStepCount(PAGE_TITLES.CONSOLIDATED_CONSENT.id);
  };

  console.log("qualificationsArray", t("qualifications"));
  return (
    <div>
      <Typography fontSize={FontSize.large} mb="16px">
        {t("introText")}
      </Typography>
      <Typography mb="16px">{t("instructionsText")}</Typography>

      <BulletPointsComponent
        bulletPoints={t("bulletPoints")}
        ellipse={ellipse}
      />
      <QualificationComponent
        title={titleText}
        qualifications={t("qualifications")}
        checkLogo={checkLogo}
        footerNote=""
      />
      <BackForwardButton
        backAction={backStep}
        forwardAction={handleContinue}
        backVisible={false}
      />
    </div>
  );
};
