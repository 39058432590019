import React from "react";
import FileUploader from "./FileUploader";
import { Typography } from "constants/Typography/Typography";
import { FontWeight } from "constants/Fonts";
import styled, { useTheme } from "styled-components";
import "./style.css";

interface HorizontallyAlignedProps {
  retry: boolean;
  uploaded: boolean;
  setFileUploadError: any;
  side: "front" | "back";
  handleUpload: (base64String: string, fileTooBig: boolean) => void;
  imageSrc: string;
  style?: React.CSSProperties;
}
interface ErrorMessageProps {
  retry: boolean;
}
export const ErrorMessage = styled(Typography)<ErrorMessageProps>`
  color: ${(props) =>
    props.retry ? props.theme.colors.alertRed : props.theme.colors.textPrimary};
  margin-top: 1px;
  margin-bottom: 0;
  font-weight: ${FontWeight.semiBold};
`;

const HorizontallyAligned = ({
  retry = false,
  uploaded = false,
  side = "front",
  handleUpload = () => null,
  imageSrc = "",
  style,
  setFileUploadError,
}: HorizontallyAlignedProps) => {
  const uploadContainerId = `${side}UploadContainer`;
  const uploadTitle = `Uploads ${side} side`;
  const idImg = `licenseImg`;
  const theme = useTheme();
  return (
    <div className={uploadContainerId} style={style}>
      <ErrorMessage retry={retry}>{uploadTitle}</ErrorMessage>
      <div
        className={retry ? `frontIDRetry` : "IdContainer"}
        style={{ backgroundColor: theme.colors.bgLight }}
      >
        {!uploaded && (
          <img className={idImg} src={imageSrc} alt={`License ${side}`} />
        )}
        <FileUploader
          sideOfID={side}
          retry={retry}
          handleUpload={handleUpload}
          setFileUploadError={setFileUploadError}
        />
      </div>
    </div>
  );
};

export default HorizontallyAligned;
