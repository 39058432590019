import { Typography } from "constants/Typography/Typography";

import styled, { useTheme } from "styled-components";
import { ReactComponent as Checkmark } from "../../../assets/svgs/check_logo_new.svg";
import { ReactComponent as SplashBlob2 } from "../../../assets/svgs/splash_blob_2.svg";
import useTranslation from "common/TranslationHook/useTranslation";

const OuterContainer = styled.div`
  background-color: ${(props) => props.theme.colors.bgLight};
`;

const ContentContainer = styled.div`
  padding: var(--content-gap) var(--container-padding);
  gap: ${(props) => (props.theme.format > 0 ? "40px" : "24px")};
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: var(--max-content-width);
  margin: auto;
`;

const VideoWithCheckItemsContainer = styled.div`
  display: flex;
  gap: ${(props) => (props.theme.format > 0 ? "40px" : "24px")};
  flex-direction: ${(props) => (props.theme.format > 1 ? "row" : "column")};
  z-index: 5;
  align-content: center;
`;

const VideoContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: ${(props) => (props.theme.format > 1 ? "27.25%" : "56.25%")};
  flex: 1;
  border-radius: 8px;
`;

const Video = styled.iframe`
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;

  border-radius: 16px;
`;

const ChecklistContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  padding: ${(props) => (props.theme.format > 1 ? "16px 0px" : null)};
`;

const ChecklistItem = styled.div`
  display: flex;
  gap: 12px;
  align-self: stretch;
`;

const StyledCheckmark = styled(Checkmark)`
  fill: ${(props) => props.theme.colors.tertiary};
  min-width: ${(props) => (props.theme.format > 1 ? "24px" : "20px")};
`;

const StyledSplashBlob2 = styled(SplashBlob2)`
  position: absolute;
  overflow: visible;
  padding-top: 40px;
`;
const Header = styled(Typography)`
  z-index: 5;
  text-align: ${(props) => (props.theme.format > 1 ? "center" : null)};
`;

const VideoWithCheckItems = () => {
  const theme = useTheme();
  const { t } = useTranslation("videowithcheckitems");
  return (
    <OuterContainer>
      {theme.format > 0 && <StyledSplashBlob2 />}
      <ContentContainer>
        <Header headingLevel="h2" marginTop="0px" mb={"0px"}>
          {t("videowithcheckitemsHeader")}
        </Header>
        <VideoWithCheckItemsContainer>
          <VideoContainer>
            <Video
              src={`https://www.youtube.com/embed/zcDqKWkhBJc`}
              // src={"https://youtube.com/embed/playlist?list=PLMVwylNvy51AeBUplFJZDbYhfGOH_gWaN&si=_FHmcqTwWHR4RfBD&rel=0"}
              // src={"https://youtube.com/embed/playlist?list=PLxBAnQex67xgk42f2hVqiu_GmftmQyHUz&si=BqSTW9brrIizlrau&rel=0"}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />
          </VideoContainer>
          <ChecklistContainer>
            <ChecklistItem>
              <StyledCheckmark />
              <Typography marginTop="0px" mb="0px">
                <span style={{ fontFamily: "Montserrat-Bold" }}>
                  {t("dedicatedCareTeam")}
                </span>{" "}
                {t("videowithcheckitemsText1", {
                  dedicatedCareTeam: t("dedicatedCareTeam"),
                })}
              </Typography>
            </ChecklistItem>
            <ChecklistItem>
              <StyledCheckmark />
              <Typography marginTop="0px" mb="0px">
                <span style={{ fontFamily: "Montserrat-Bold" }}>
                  {t("connectedDevices")}
                </span>
                {t("videowithcheckitemsText2", {
                  connectedDevices: t("connectedDevices"),
                })}
              </Typography>
            </ChecklistItem>
            <ChecklistItem>
              <StyledCheckmark />
              <Typography marginTop="0px" mb="0px">
                <span style={{ fontFamily: "Montserrat-Bold" }}>
                  {t("DigitalApp")}
                </span>{" "}
                {t("videowithcheckitemsText3", {
                  DigitalApp: t("DigitalApp"),
                })}
              </Typography>
            </ChecklistItem>

            <Typography headingLevel="small" marginTop="8px" mb="0px">
              {t("videowithcheckitemsSubText")}
            </Typography>
          </ChecklistContainer>
        </VideoWithCheckItemsContainer>
      </ContentContainer>
    </OuterContainer>
  );
};

export default VideoWithCheckItems;
