import React from "react";
import styled from "styled-components";
import { Color } from "constants/Colors";
import { FontFamily, FontSize } from "constants/Fonts";
import useTranslation from "./TranslationHook/useTranslation";

interface ButtonProps {
  primary?: boolean;
  disabled?: boolean;
  consent?: boolean;
  marginTop?: string;
}

const ButtonsContainer = styled.div<ButtonProps>`
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "20px")};
  font-family: ${FontFamily.medium};
`;

const StyledButton = styled.button<ButtonProps>`
  display: flex;
  padding: 12px 32px;
  border-radius: 8px;
  justify-content: center;
  border: 2px solid
    ${(props) =>
      props.consent
        ? props.theme.colors.buttonPrimary
        : props.primary
        ? props.theme.colors.buttonPrimary
        : props.theme.colors.buttonSecondary};
  background-color: ${(props) =>
    props.consent
      ? props.theme.colors.buttonPrimary
      : props.primary
      ? props.theme.colors.buttonPrimary
      : props.theme.colors.buttonSecondary};
  color: ${(props) =>
    props.consent
      ? props.theme.colors.buttonPrimaryText
      : props.primary
      ? props.theme.colors.buttonPrimaryText
      : props.theme.colors.buttonSecondaryText};
  cursor: pointer;
  transition:
    background-color 0.3s,
    border-color 0.3s;
  width: 100%;
  text-align: center;
  min-width: 120px;
  font-family: ${FontFamily.semiBold};
  font-size: ${FontSize.base};

  &:hover,
  &:active {
    background-color: ${(props) =>
      props.consent
        ? props.theme.colors.buttonPrimaryHover
        : props.primary
        ? props.theme.colors.buttonPrimaryHover
        : props.theme.colors.buttonSecondaryHover};
    border-color: ${(props) =>
      props.consent
        ? props.theme.colors.buttonPrimaryHover
        : props.primary
        ? props.theme.colors.buttonPrimaryHover
        : props.theme.colors.buttonSecondaryHover};
  }

  &:disabled {
    background-color: ${Color.lightGray};
    color: ${Color.darkGray};
    pointer-events: none;
    opacity: 0.5;
    border: 2px solid ${Color.lightGray};
  }

  ${(props) =>
    props.consent &&
    `
    @media (max-width: 768px) {
      padding: 12px 24px;
    }
  `}
`;

const BackButton = styled(StyledButton)`
  background-color: ${(props) => props.theme.colors.bgWhite};
  color: ${(props) => props.theme.colors.buttonSecondaryText};
  border: 2px solid ${(props) => props.theme.colors.buttonSecondary};
  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colors.buttonSecondary};
    color: ${Color.white};
  }
`;

interface BackForwardButtonProps {
  backText?: string;
  forwardText?: string;
  closeText?: string;
  backAction?: () => void;
  forwardAction?: () => void;
  closeButtonAction?: () => void;
  backVisible?: boolean;
  frontVisible?: boolean;
  closeButtonVisible?: boolean;
  disabled?: boolean;
  idStyle?: string;
  consent?: boolean;
}

const BackForwardButton: React.FC<BackForwardButtonProps> = ({
  backText,
  forwardText,
  closeText,
  backAction,
  forwardAction,
  closeButtonAction,
  backVisible = true,
  frontVisible = true,
  closeButtonVisible = false,
  disabled = false,
  idStyle = "",
  consent = false,
}) => {
  const { t } = useTranslation("BackForwardButton");

  return (
    <ButtonsContainer>
      {backVisible && (
        <BackButton id={idStyle} onClick={backAction} consent={consent}>
          {backText || t("back")}
        </BackButton>
      )}

      {frontVisible && !closeButtonVisible && (
        <StyledButton
          primary={true}
          onClick={disabled ? undefined : forwardAction}
          disabled={disabled}
          consent={consent}
        >
          {forwardText || t("continue")}
        </StyledButton>
      )}

      {closeButtonVisible && (
        <StyledButton
          primary={true}
          onClick={disabled ? undefined : closeButtonAction}
          disabled={disabled}
        >
          {closeText || t("close")}
        </StyledButton>
      )}
    </ButtonsContainer>
  );
};

export default BackForwardButton;
