export const PAGE_TITLES = {
  WELCOME: { id: 0, title: "Welcome to Digital Medicine" },
  YOUR_INFORMATION: { id: 2, title: "Your Information" },
  COVERAGE: { id: 3, title: "Your Coverage" },
  IDENTITY_CONFIRMATION: { id: 5, title: "Let's Confirm Your Identity" },
  IDENTITY_VERIFICATION_QUESTIONS: {
    id: 6,
    title: "Identity Verification Questions",
  },
  UPLOAD_PHOTO_ID: { id: 7, title: "Upload Your ID for Identity Verification" },
  PROGRAM_SELECTION: { id: 8, title: "What Are Your Health Goals?" },
  THANK_YOU_SELECTION: { id: 23, title: "Your Health Goal Selection:" },
  HEIGHT_WEIGHT: { id: 9, title: "Your Current Height and Weight" },
  WM_MEDICATION: {
    id: 10,
    title: "Are You Interested in Weight Loss Medications?",
  },
  EXCLUSIONARY_CRITERIA: {
    id: 11,
    title: "Let's See if the Program is Right for You",
  },
  CONSOLIDATED_CONSENT: { id: 12, title: "Program Terms & Conditions" },

  CUFF_SIZE_SELECTION: {
    id: 13,
    title: "Choose the Right Blood Pressure Cuff Size",
  },
  GLUCOMETER_SELECTION: {
    id: 14,
    title: "Do You Already Have a Dexcom or Freestyle Libre?",
  },
  SHIPPING_ADDRESS: { id: 15, title: "Select Shipping Address" },
  ADDRESS_CONFIRMATION: { id: 16, title: "Shipping Address Confirmed" },
  SCHEDULE_CONSULTATION_SECOND: {
    id: 17,
    title: "Schedule Video Consultation",
  },
  COVERAGE_UNIQUE_ID: { id: 18, title: "Your Coverage" },
  SUCCESS_PAGE: { id: 19, title: "Success!" },

  ERROR_FINANCIAL_ELIGIBLE: {
    id: 20,
    title: "Unable to Continue at This Time",
  },

  ERROR_PATIENT_CAP: {
    id: 21,
    title: "Unable to Continue at This Time",
  },
  ERROR_GENERAL: { id: 22, title: "Unable to Continue at This Time" },
  ERROR_WM: { id: 24, title: "Weight Management" },
  CONFIRMATION_PAGE: { id: 25, title: "Confirmation & Next Steps" },
  PROGRAM_VALIDATION: {
    id: 26,
    title: "Let’s See if the Program Is Right for You",
  },
  API_LOADER: {
    id: 27,
    title: "Welcome Back!",
  },
  ERROR_WM_DMII: {
    id: 28,
    title: "Weight Management",
  },
  ERROR_EXCLUSIONARYCRITERIA: {
    id: 29,
    title: "Unable to Continue",
  },
  OAUTH_API_LOADER: {
    id: 30,
    title: "",
  },
  ERROR_ID_VERIFY: { id: 31, title: "We Couldn't Verify Your Identity" },
  SCHEDULE_VISIT_ERROR: { id: 32, title: "We're Having Trouble" },
  ERROR_UNDERAGE: { id: 33, title: "Unable to Continue" },
  ERROR_USERINFO: { id: 34, title: "Unable to Continue at This Time" },
  PROGRAM_COST_CONSENT: { id: 35, title: "Program Cost" },
};
