import { Typography } from "constants/Typography/Typography";
import appleStore from "../../assets/svgs/appleStore_icon.svg";
import googleStore from "../../assets/svgs/googleplay_icon.svg";
import DMAppIcon from "../../assets/svgs/DM_App_Icon.svg";
import "../../common/style.css";
import "add-to-calendar-button";
import "moment-timezone";
import "../style.css";
import useTranslation from "common/TranslationHook/useTranslation";

interface DMAppProps {
  healthGoalsVerbiage: string;
  wmIncluded: boolean;
}

const DMApp = ({ healthGoalsVerbiage, wmIncluded }: DMAppProps) => {
  const { t } = useTranslation("Confirmation");
  return (
    <div>
      <Typography>
        <Typography
          mb="12px"
          style={{ marginTop: 0 }}
          dangerouslySetInnerHTML={{
            __html: t("getStartedText").replace(
              "{healthGoalsVerbiage}",
              `${healthGoalsVerbiage}`,
            ),
          }}
        />
        {wmIncluded ? t("weightSubmissionText") : ""}.
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "24px",
          alignSelf: "stretch",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            border: ".424px solid #6A88AF",
            borderRadius: "25.74px",
            display: "flex",
            width: "117px",
            height: "117px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={DMAppIcon} alt="Digital Medicine App Logo"></img>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flex: "1 0 0",
            gap: "24px",
          }}
        >
          <Typography marginTop="0" mb="0">
            {t("downloadAppInstructions")}
          </Typography>
          <div
            style={{ display: "flex", alignItems: "flex-start", gap: "16px" }}
          >
            <img
              src={appleStore}
              alt="apple store"
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(
                  "https://apps.apple.com/us/app/ochsner-digital-medicine/id1667347046",
                )
              }
            />
            <img
              src={googleStore}
              alt="google play store"
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.ochsner.digitalmedicine",
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DMApp;
