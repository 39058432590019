import { Typography } from "constants/Typography/Typography";
import { useEffect, useState } from "react";
import { IContextActions, IContextState } from "store/Context";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "./wizardscreen/PageTitles";
import { ReactComponent as LoadingSpinner } from "../assets/svgs/loadingSpinner.svg";
import { useTheme } from "styled-components";
import BackForwardButton from "common/BackForwardButton";
import InputField from "common/InputField/InputField";
import useTranslation from "common/TranslationHook/useTranslation";
import "./style.css";

interface UniqueIdCoverageProps {
  nextStep: () => void;
  backStep: () => void;
  setStepCount: (step: number) => void;
  state: IContextState;
  actions: IContextActions;
}

export const UniqueIdCoverage = ({
  backStep,
  setStepCount,
  state,
  actions,
}: UniqueIdCoverageProps) => {
  const [uniqueId, setUniqueId] = useState("");
  const [confirmUniqueId, setConfirmUniqueId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [ready, setReady] = useState(true);
  const { PatientLookup, auditTrail, PatientPrograms } = useApi();
  const theme = useTheme();
  const { t } = useTranslation("UniqueIdCoverage");

  const onChangeUniqueId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUniqueId(event.target.value);
    if (errorMessage) setErrorMessage("");
  };

  const onChangeConfirmUniqueId = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inputVal = event.target.value;
    setConfirmUniqueId(inputVal);

    if (uniqueId && inputVal.toUpperCase() !== uniqueId.toUpperCase()) {
      setErrorMessage(t("errorMessages.idMismatch"));
    } else {
      setErrorMessage("");
    }
  };

  useEffect(() => {
    auditTrail("Unique ID");
  }, []);

  const handleContinue = () => {
    if (uniqueId === confirmUniqueId && uniqueId.trim() !== "") {
      setReady(false);
      actions.setValue("sponsorId", uniqueId);
      PatientLookup().then((data: any) => {
        if (data.PatientId !== "") {
          // actions.setValue("htn", data.HTN);
          // actions.setValue("diab", data.DIAB);
          actions.setValue("patientId", data.PatientId);
          PatientPrograms(data.PatientId, state.oauth).then((data: any) => {
            if (
              data.CapMet === false &&
              !data.Enrolled.length &&
              !data.Eligible.length &&
              data.Sponsor === ""
            ) {
              auditTrail("Not Eligible");
              auditTrail("Came from MyOchsner");
              actions.setValue("comingFromSignup", true);
              setStepCount(PAGE_TITLES.ERROR_FINANCIAL_ELIGIBLE.id);
            } else if (data.CapMet === "true") {
              actions.setValue("comingFromSignup", true);
              actions.setValue("oauth", true);
              actions.setValue("hasMyChartAccount", true);
              auditTrail("Cap Met");
              setStepCount(PAGE_TITLES.ERROR_PATIENT_CAP.id);
            } else if (!data.Enrolled.length && !data.Eligible.length) {
              auditTrail("Not Eligible");
              setStepCount(PAGE_TITLES.ERROR_FINANCIAL_ELIGIBLE.id);
            } else {
              actions.setValue("Capmet", data.CapMet);
              actions.setValue("sponsor", data.Sponsor);
              actions.setValue("threefourtyB", data.Eligible340B);
              if (data.Enrolled.includes("Hypertension")) {
                actions.setValue("htnEnrolled", true);
              }
              if (data.Enrolled.includes("Type 2 Diabetes")) {
                actions.setValue("diabEnrolled", true);
              }
              if (data.Eligible.includes("Hypertension")) {
                actions.setValue("htnEligible", true);
              }
              if (data.Eligible.includes("Type 2 Diabetes")) {
                actions.setValue("diabEligible", true);
              }
              if (data.Eligible.includes("Weight Management")) {
                actions.setValue("wmEligible", true);
              }
              if (data.WMType === "Lifestyle Only") {
                actions.setValue("wmEligible", true);
                actions.setValue("wmLifestyleProgram", true);
              }
              if (data.WMType === "Med Management") {
                actions.setValue("wmEligible", true);
                actions.setValue("wmMedsProgram", true);
              }
              setStepCount(PAGE_TITLES.SUCCESS_PAGE.id);
            }
          });
        } else {
          setReady(true);
          auditTrail("Patient Not Found");
          setStepCount(PAGE_TITLES.ERROR_GENERAL.id);
        }
      });
    } else {
      setErrorMessage(t("errorMessages.idMismatch"));
    }
  };
  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    setErrorMessage(t("errorMessages.noPaste"));
  };

  const canContinue =
    uniqueId && confirmUniqueId && uniqueId === confirmUniqueId;

  return (
    <div>
      <Typography>
        {t("introText", { idTypePrompt: state.idTypePrompt })}
      </Typography>
      <InputField
        label={t("labels.uniqueId", { idTypePrompt: state.idTypePrompt })}
        name="UniqueId"
        value={uniqueId.toUpperCase()}
        onChange={onChangeUniqueId}
      />
      <InputField
        label={t("labels.confirmUniqueId", {
          idTypePrompt: state.idTypePrompt,
        })}
        name="ConfirmUniqueId"
        value={confirmUniqueId.toUpperCase()}
        onChange={onChangeConfirmUniqueId}
        errorString={errorMessage}
        onPaste={handlePaste}
      />
      {ready ? (
        <BackForwardButton
          backAction={backStep}
          forwardAction={handleContinue}
          backVisible={true}
          frontVisible={true}
          disabled={!canContinue}
        />
      ) : (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            padding: "16px 0px",
          }}
        >
          <LoadingSpinner
            fill={theme.colors.tertiary}
            style={{ height: 36 }}
            className="spinner"
          />
        </div>
      )}
    </div>
  );
};
