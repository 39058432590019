import { Typography } from "constants/Typography/Typography";
import useTranslation from "common/TranslationHook/useTranslation";
import "./style.css";

interface TelemedicineConsentTextProps {
  forceStaticHTMLSupport?: boolean;
}

const TelemedicineConsentText = ({
  forceStaticHTMLSupport = false,
}: TelemedicineConsentTextProps) => {
  const { t } = useTranslation("telemedicineconsenttext");

  const TypographyOrP = ({ children, ...rest }: any) =>
    forceStaticHTMLSupport ? (
      <p>{children}</p>
    ) : (
      <Typography {...rest}>{children}</Typography>
    );
  const consentList = t("consentList", { returnObjects: true });
  const riskSubPoints = t("riskSubPoints", { returnObjects: true });

  return (
    <TypographyOrP fontSize="small">
      <div>{t("purpose")}</div>
      <br />
      <div>{t("description")}</div>
      <div>
        <br />
        {t("sinceFamiliar")}
      </div>
      <ol>
        {Array.isArray(consentList) &&
          consentList.map((item: string, index: number) => (
            <li key={index}>
              {item}
              {index === 4 && (
                <ul>
                  {Array.isArray(riskSubPoints) &&
                    riskSubPoints.map((subItem: string, subIndex: number) => (
                      <li key={subIndex}>{subItem}</li>
                    ))}
                </ul>
              )}
            </li>
          ))}
      </ol>
      <div>
        CONSENT: <br /> {t("consentConclusion")}
      </div>
      <br />
      {t("agreeStatement")}
    </TypographyOrP>
  );
};

export default TelemedicineConsentText;
