import { Typography } from "constants/Typography/Typography";
import { FontWeight } from "constants/Fonts";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "./wizardscreen/PageTitles";
import { StyledLink } from "./landingPage/newLandingPageComponents/JoinTheProgram";
import { useTheme } from "styled-components";
import { StyledBulletCircle, StyledBulletNumber } from "common/NumericCircle";
import { IContextActions, IContextState } from "store/Context";
import RadioButton from "common/RadioButton";
import BloodPressureCuff1 from "../assets/svgs/bloodpressurecuff_visual1.svg";
import BloodPressureCuff2 from "../assets/svgs/bloodpressurecuff_visual2.svg";
import useTranslation from "common/TranslationHook/useTranslation";
import BackForwardButton from "common/BackForwardButton";
import "./style.css";

interface HBPCuffSizeProps {
  nextStep: () => void;
  backStep: () => void;
  HBPSelected: boolean;
  T2Selected: boolean;
  setStepCount: (step: number) => void;
  setPrevStep: React.Dispatch<React.SetStateAction<number>>;
  setPrevStepDevice: React.Dispatch<React.SetStateAction<number>>;
  actions: IContextActions;
  state: IContextState;
}

export const HBPCuffSize = ({
  backStep,
  setStepCount,
  setPrevStep,
  setPrevStepDevice,
  actions,
  state,
}: HBPCuffSizeProps) => {
  const navigate = useNavigate();
  const { DeviceDetail, auditTrail } = useApi();
  const { t } = useTranslation("HBPCuffSize");

  const theme = useTheme();

  useEffect(() => {
    auditTrail("CuffSize");
  }, []);

  const handleContinue = () => {
    if (
      customCuffInput !== "" &&
      cuffSize === "notapplicable" &&
      !recordedCustomCuff
    ) {
      actions.setValue("noCuffSize", true);
      const noCuffSize: any = true;
      DeviceDetail({ noCuffSize: noCuffSize }).then((data) => {});
      setRecordedCustomCuff(true);
      return;
    }

    let cuffSizeParam: any = "";
    if (cuffSize === "standard") {
      actions.setValue("cuffSize", "Standard");
      cuffSizeParam = "Standard";
    } else if (cuffSize === "large") {
      actions.setValue("cuffSize", "Large");
      cuffSizeParam = "Large";
    } else {
      actions.setValue("cuffSize", "ExtraLarge");
      cuffSizeParam = "ExtraLarge";
    }

    DeviceDetail({ cuffSize: cuffSizeParam }).then((data) => {});

    setPrevStep(13);
    setPrevStepDevice(13);
    if (state.diabChosen) {
      setStepCount(PAGE_TITLES.GLUCOMETER_SELECTION.id);
    } else {
      setStepCount(PAGE_TITLES.SHIPPING_ADDRESS.id);
    }
  };

  const closeButtonAction = () => {
    navigate("/signup");
  };

  const [cuffSize, setCuffSize] = useState("");
  const [customCuffInput, setCustomCuffInput] = useState("");
  const [recordedCustomCuff, setRecordedCustomCuff] = useState(false);

  const handleInput = (e: any) => {
    setCustomCuffInput(e.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        alignSelf: "stretch",
      }}
    >
      <Typography>{t("intro.text")}</Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "32px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "24px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "16px",
                alignSelf: "stretch",
              }}
            >
              <StyledBulletCircle>
                <StyledBulletNumber>1</StyledBulletNumber>
              </StyledBulletCircle>
              <Typography fontSize="27px" fontWeight={FontWeight.bold}>
                {t("steps.step1.title")}
              </Typography>
            </div>
          </div>
          <div className="cuff-image-text">
            <Typography>{t("steps.step1.description")}</Typography>
            <img
              src={BloodPressureCuff1}
              alt="Upper middle arm visual"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "24px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "16px",
                alignSelf: "stretch",
              }}
            >
              <StyledBulletCircle>
                <StyledBulletNumber>2</StyledBulletNumber>
              </StyledBulletCircle>
              <Typography fontSize="27px" fontWeight={FontWeight.bold}>
                {t("steps.step2.title")}
              </Typography>
            </div>
          </div>
          <div className="cuff-image-text">
            <Typography>{t("steps.step2.description")}</Typography>
            <img
              src={BloodPressureCuff2}
              alt="Upper middle arm visual"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "24px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "16px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "16px",
                alignSelf: "stretch",
              }}
            >
              <StyledBulletCircle>
                <StyledBulletNumber>3</StyledBulletNumber>
              </StyledBulletCircle>
              <Typography fontSize="27px" fontWeight={FontWeight.bold}>
                {t("steps.step3.title")}
              </Typography>
            </div>
          </div>
          <Typography>{t("steps.step3.description")}</Typography>
          <div className="cuffsize-radio-group">
            <RadioButton
              value="Standard"
              id="standardInput"
              checked={cuffSize === "standard"}
              onChange={() => {
                setCuffSize("standard");
              }}
              label={t("cuffOptions.standard")}
            />

            <RadioButton
              value="Large"
              id="largeInput"
              checked={cuffSize === "large"}
              onChange={() => {
                setCuffSize("large");
              }}
              label={t("cuffOptions.large")}
            />

            <RadioButton
              value="X-Large"
              id="xlargeInput"
              checked={cuffSize === "X-Large"}
              onChange={() => {
                setCuffSize("X-Large");
              }}
              label={t("cuffOptions.xlarge")}
            />

            <RadioButton
              value="NotApplicable"
              id="notappInput"
              checked={cuffSize === "notapplicable"}
              onChange={() => {
                setCuffSize("notapplicable");
              }}
              label={t("cuffOptions.notApplicable")}
            />
            {cuffSize === "notapplicable" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                  flexWrap: "wrap",
                }}
              >
                <Typography>{t("cuffOptions.customSizePrompt")}</Typography>
                <div
                  style={{
                    width: "116px",
                    display: "flex",
                    padding: "12px 16px",
                    alignItems: "center",
                    gap: "12px",
                    flexShrink: 0,
                    borderRadius: "8px",
                    border: `1px solid ${theme.colors.textPrimary}`,
                    justifyContent: "center",
                  }}
                >
                  <input
                    value={customCuffInput}
                    onChange={(e) => {
                      handleInput(e);
                    }}
                    style={{
                      flex: "1 0 0",
                      color: theme.colors.textPrimary,
                      border: "none",
                      boxSizing: "border-box",
                      width: "100%",
                      fontFamily: "Montserrat",
                      fontSize: "17px",
                    }}
                  />
                  <Typography fontWeight={FontWeight.bold} fontSize="17px">
                    "
                  </Typography>
                </div>
              </div>
            )}
            {cuffSize === "notapplicable" &&
              customCuffInput !== "" &&
              recordedCustomCuff && (
                <div
                  style={{
                    display: "flex",
                    padding: "24px",
                    margin: "40px",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "16px",
                    alignSelf: "stretch",
                    border: `1px solid ${theme.colors.secondary}`,
                    borderRadius: "8px",
                    background: theme.colors.tintSecondary10,
                  }}
                >
                  <Typography>{t("noSizeAvailable.text")}</Typography>
                </div>
              )}
            <Typography style={{ marginTop: "40px" }}>
              {t("helpText.text")}
              <StyledLink
                href="tel:866-273-0548"
                style={{ fontFamily: "Montserrat-Bold" }}
              >
                {t("helpText.phone")}
              </StyledLink>{" "}
            </Typography>
          </div>
        </div>
      </div>
      <BackForwardButton
        backAction={backStep}
        forwardAction={handleContinue}
        closeButtonAction={closeButtonAction}
        backVisible={true}
        frontVisible={true}
        disabled={
          cuffSize === "" ||
          (cuffSize === "notapplicable" && customCuffInput === "")
        }
        closeButtonVisible={cuffSize === "notapplicable" && recordedCustomCuff}
      />
    </div>
  );
};
