import { Color } from "constants/Colors";
import { Typography } from "constants/Typography/Typography";
import styled from "styled-components";
import { ReactComponent as InfoIcon } from "assets/svgs/infoIcon.svg";

export const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0px;
  }
`;

export const GenderTitle = styled(Typography)`
  margin-bottom: 10px;
`;

export const RadioGroupDiv = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const InfoSection = styled.div`
  margin-top: 3px;
  display: flex;
  cursor: pointer;
`;

export const InfoImg = styled(InfoIcon)`
  width: 24px;
  height: 24px;
  margin-left: 0px;
  fill: ${(props) => props.theme.colors.primary};
`;

export const TextStyle = styled.span`
  font-family: "Montserrat-SemiBold";
  text-decoration: underline;
  margin-left: 10px;
  padding-top: 2px;
  color: ${(props) => props.theme.colors.textPrimary};
`;

export const ButtonContainerLoader = styled.img`
  height: 44px;
  margin: auto;
  margin-top: 56px;
  margin-bottom: 56px;
  display: flex;
`;
