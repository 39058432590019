import { Typography } from "constants/Typography/Typography";
import { useEffect } from "react";
import { IContextState } from "store/Context";
import { useApi } from "store/useApi";
import BackForwardButton from "common/BackForwardButton";
import useTranslation from "common/TranslationHook/useTranslation";
import "./style.css";

interface WMDMIIErrorPageProps {
  nextStep: () => void;
  backStep: () => void;
  closeButtonAction: () => void;
  stepNumber: number;
  setStepCount: (step: number) => void;
  state: IContextState;
}

export const WMDMIIErrorPageProps = ({
  closeButtonAction,
}: WMDMIIErrorPageProps) => {
  const { PatientProgress } = useApi();
  const { t } = useTranslation("WMDMIIError");

  useEffect(() => {
    PatientProgress("WtMgmtDiabEnrolled").then(() => {});
  }, []);

  return (
    <div>
      <div style={{ marginBottom: 78 }}>
        <Typography>{t("mainText")}</Typography>
      </div>
      <BackForwardButton
        closeButtonAction={closeButtonAction}
        backVisible={false}
        frontVisible={false}
        closeButtonVisible={true}
      />
    </div>
  );
};
