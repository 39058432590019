import { useEffect, useState } from "react";
import { Typography } from "constants/Typography/Typography";
import { FontFamily } from "constants/Fonts";
import { IContextState } from "store/Context";
import { useApi } from "store/useApi";
import BackForwardButton from "common/BackForwardButton";
import RadioButton from "common/RadioButton";
import "../style.css";
import useTranslation from "common/TranslationHook/useTranslation";

interface ProgramValidationProps {
  nextStep: () => void;
  setStepCount: (step: number) => void;
  state: IContextState;
}

export const ProgramValidation = ({ state }: ProgramValidationProps) => {
  const [selectedPregnancy, setSelectedPregnancy] = useState("");
  const [selectedEating, setSelectedEating] = useState("");
  const [selectedTypeDiab, setSelectedTypeDiab] = useState("");
  const { auditTrail } = useApi();

  const { t } = useTranslation("ProgramValidation");

  const isEligible =
    state.gender === "male"
      ? selectedEating !== "" && selectedTypeDiab !== ""
      : selectedPregnancy !== "" &&
        selectedEating !== "" &&
        selectedTypeDiab !== "";

  useEffect(() => {
    auditTrail("Program Validation");
  }, []);

  const handleContinue = () => {
    // actions.setValue("phoneType", selectedSmartphoneOption);
    // actions.setValue("chosenHealthCondition", selectedHealthCondition);
    auditTrail("Submit Eligibility");
    auditTrail("Pregnancy Selection" + selectedPregnancy);
    auditTrail("TypeDiab Selection " + selectedTypeDiab);
    auditTrail("EatingDisorder Selection" + selectedEating);
  };

  return (
    <div>
      {state.gender === "female" ? (
        <>
          <Typography fontFamily={FontFamily.bold}>
            {t("programValidationPregnancyQuestion")}
          </Typography>
          <div className="eligibility-radio-group" style={{ marginBottom: 24 }}>
            <RadioButton
              id="Pregnant"
              value="Pregnant"
              checked={selectedPregnancy === "Pregnant"}
              onChange={() => setSelectedPregnancy("Pregnant")}
              label={t("Yes")}
            />
            <RadioButton
              id="NOTPregnant"
              value="NOTPregnant"
              checked={selectedPregnancy === "NOTPregnant"}
              onChange={() => setSelectedPregnancy("NOTPregnant")}
              label={t("No")}
            />
          </div>
        </>
      ) : null}
      <Typography fontFamily={FontFamily.bold}>
        {t("programValidationEatingDisorderQuestion")}
      </Typography>
      <div className="eligibility-radio-group" style={{ marginBottom: 24 }}>
        <RadioButton
          id="EatingDisorder"
          value="Pregnant"
          checked={selectedEating === "EatingDisorder"}
          onChange={() => setSelectedEating("EatingDisorder")}
          label={t("Yes")}
        />
        <RadioButton
          id="NOTEatingDisorder"
          value="NOTEatingDisorder"
          checked={selectedEating === "NOTEatingDisorder"}
          onChange={() => setSelectedEating("NOTEatingDisorder")}
          label={t("No")}
        />
      </div>
      <Typography fontFamily={FontFamily.bold}>
        {t("programValidationDiabetesQuestion")}
      </Typography>
      <div className="eligibility-radio-group" style={{ marginBottom: 24 }}>
        <RadioButton
          id="Type1Diab"
          value="Type1Diab"
          checked={selectedTypeDiab === "Type1Diab"}
          onChange={() => setSelectedTypeDiab("Type1Diab")}
          label={t("Yes")}
        />
        <RadioButton
          id="NOType1Diab"
          value="NOType1Diab"
          checked={selectedTypeDiab === "NOType1Diab"}
          onChange={() => setSelectedTypeDiab("NOType1Diab")}
          label={t("No")}
        />
      </div>

      <BackForwardButton
        forwardAction={handleContinue}
        backVisible={false}
        disabled={!isEligible}
      />
    </div>
  );
};
