import { Typography } from "constants/Typography/Typography";
import "../../common/style.css";
import { useEffect, useState } from "react";
import "add-to-calendar-button";
import moment from "moment";
import "moment-timezone";
import { IContextActions, IContextState } from "store/Context";
import { useApi } from "store/useApi";
import "../style.css";
import MyOchApp from "./MyOchApp";
import { useTheme } from "styled-components";
import ScheduleVV from "./ScheduleVV";
import NumericCircleConfirmation from "common/NumericCircleConfirmation";
import AttendVV from "./AttendVV";
import DMApp from "./DMApp";
import { CallToActionSharp } from "@mui/icons-material";
import useTranslation from "common/TranslationHook/useTranslation";

interface ConfirmationProps {
  nextStep: () => void;
  backStep: () => void;
  scheduleNowChoice: boolean;
  HBPSelected: boolean;
  T2Selected: boolean;
  visitNeeded: string;
  setStepCount: (step: number) => void;
  state: IContextState;
  actions: IContextActions;
}

export const Confirmation = ({
  state,
  setStepCount,
  actions,
}: ConfirmationProps) => {
  const [apptDate, setApptDate] = useState("");
  const [apptTimeStart, setApptTimeStart] = useState("");
  const [apptTimeEnd, setApptTimeEnd] = useState("");
  const [apptDateandTimeFormatted, setApptDateandTimeFormatted] = useState("");
  const { ProgramBenefitsPut, auditTrail, PatientProgramsPost } = useApi();
  const theme = useTheme();
  const [wmIncluded, setwmIncluded] = useState(false);
  const { t } = useTranslation("Confirmation");

  useEffect(() => {
    const appointmentDate = state.appointmentDate;
    const appointmentTime = state.appointmentTimeDisplay;

    if (appointmentDate && appointmentTime) {
      const fullDateTime = moment(`${appointmentDate}T${appointmentTime}`);
      const appointmentTimeEnd = fullDateTime
        .clone()
        .add(20, "minutes")
        .format("HH:mm:ss");

      setApptDate(fullDateTime.format("YYYY-MM-DD"));
      setApptTimeStart(fullDateTime.format("HH:mm:ss"));
      setApptTimeEnd(appointmentTimeEnd);

      const formattedDisplayDate =
        fullDateTime.format("dddd, MMMM Do, YYYY [at] hh:mm a zz") +
        " " +
        // moment.tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr();
        state.appointmentTimeZoneAbbr;
      setApptDateandTimeFormatted(formattedDisplayDate);
    }
  }, [state.appointmentDate, state.appointmentTime]);

  const emailAddress = state.EmailAddress;
  const [healthGoalsVerbiage, setHealthGoalsVerbiage] = useState("");

  useEffect(() => {
    let programs = [];
    if (state.htnChosen) {
      programs.push("Hypertension");
    }
    if (state.diabChosen) {
      programs.push("Type2Diabetes");
    }
    if (state.wmChosen) {
      programs.push("WeightManagement");
    }
    PatientProgramsPost(state.patientId, programs);
  }, []);

  useEffect(() => {
    if (state.htnChosen) {
      if (state.wmChosen) {
        setHealthGoalsVerbiage(
          t("programSelection.managingWeightAndBloodPressure"),
        );
        setwmIncluded(true);
      } else if (state.diabChosen) {
        setHealthGoalsVerbiage(
          t("programSelection.managingBloodPressureAndDiabetes"),
        );
      } else {
        setHealthGoalsVerbiage(t("programSelection.managingBloodPressure"));
      }
    } else if (state.wmChosen) {
      setHealthGoalsVerbiage(t("programSelection.managingWeight"));
      setwmIncluded(true);
    } else {
      setHealthGoalsVerbiage(t("programSelection.managingDiabetes"));
    }
  }, [healthGoalsVerbiage]);

  useEffect(() => {
    auditTrail("FinalConfirmation");
  }, []);

  return (
    <div>
      <Typography>{t("thankYouMessage")}</Typography>

      {/* existing patients */}

      {state.hasMyChartAccount || state.oauth ? (
        state.obar ? (
          <>
            <Typography
              className="question-text"
              mb="15px"
              headingLevel="large"
              fontWeight={700}
            >
              {t("existingPatients.getStartedHeader")}
            </Typography>
            <DMApp
              wmIncluded={wmIncluded}
              healthGoalsVerbiage={healthGoalsVerbiage}
            />
          </>
        ) : //wm patients
        state.wmChosen && state.htnChosen ? (
          state.visitNeeded ? (
            state.scheduleChoice === "Now" ? (
              <>
                <NumericCircleConfirmation
                  number={1}
                  questionText={t("existingPatients.attendVideoConsultation")}
                />
                <AttendVV
                  state={state}
                  healthGoalsVerbiage={healthGoalsVerbiage}
                  apptDate={apptDate}
                  apptDateandTimeFormatted={apptDateandTimeFormatted}
                  apptTimeEnd={apptTimeEnd}
                  apptTimeStart={apptTimeStart}
                />
                <NumericCircleConfirmation
                  number={2}
                  questionText={t("existingPatients.getStartedHeader")}
                />
                <DMApp
                  wmIncluded={wmIncluded}
                  healthGoalsVerbiage={healthGoalsVerbiage}
                />
              </>
            ) : (
              <>
                <NumericCircleConfirmation
                  number={1}
                  questionText={t("existingPatients.scheduleVideoConsultation")}
                />
                <ScheduleVV
                  state={state}
                  healthGoalsVerbiage={healthGoalsVerbiage}
                  setStepCount={setStepCount}
                />
                <NumericCircleConfirmation
                  number={2}
                  questionText={t("existingPatients.getStartedHeader")}
                />
                <DMApp
                  wmIncluded={wmIncluded}
                  healthGoalsVerbiage={healthGoalsVerbiage}
                />
              </>
            )
          ) : (
            //WM + HTN, no VV needed
            <>
              <Typography
                className="question-text"
                mb="15px"
                headingLevel="large"
                fontWeight={700}
              >
                {t("existingPatients.getStartedHeader")}
              </Typography>
              <DMApp
                wmIncluded={wmIncluded}
                healthGoalsVerbiage={healthGoalsVerbiage}
              />
            </>
          ) //wm patients
        ) : state.wmChosen && state.htnChosen ? (
          state.visitNeeded ? (
            state.scheduleChoice === "Now" ? (
              <>
                <NumericCircleConfirmation
                  number={1}
                  questionText={t("existingPatients.attendVideoConsultation")}
                />
                <AttendVV
                  state={state}
                  healthGoalsVerbiage={healthGoalsVerbiage}
                  apptDateandTimeFormatted={apptDateandTimeFormatted}
                  apptDate={apptDate}
                  apptTimeStart={apptTimeStart}
                  apptTimeEnd={apptTimeEnd}
                />
                <NumericCircleConfirmation
                  number={2}
                  questionText={t("existingPatients.getStartedHeader")}
                />
                <DMApp
                  wmIncluded={wmIncluded}
                  healthGoalsVerbiage={healthGoalsVerbiage}
                />
              </>
            ) : (
              <>
                <NumericCircleConfirmation
                  number={1}
                  questionText={t("existingPatients.scheduleVideoConsultation")}
                />
                <ScheduleVV
                  state={state}
                  healthGoalsVerbiage={healthGoalsVerbiage}
                  setStepCount={setStepCount}
                />
                <NumericCircleConfirmation
                  number={2}
                  questionText={t("existingPatients.getStartedHeader")}
                />
                <DMApp
                  wmIncluded={wmIncluded}
                  healthGoalsVerbiage={healthGoalsVerbiage}
                />
              </>
            )
          ) : (
            //WM + HTN, no VV needed
            <>
              <NumericCircleConfirmation
                number={1}
                questionText={t("existingPatients.waitForDevices")}
              />
              <Typography>
                {t("existingPatients.deviceArrivalInstructions")}{" "}
                {healthGoalsVerbiage}.
              </Typography>
              <NumericCircleConfirmation
                number={2}
                questionText={t("existingPatients.getStartedHeader")}
              />
              <DMApp
                wmIncluded={wmIncluded}
                healthGoalsVerbiage={healthGoalsVerbiage}
              />
            </>
          )
        ) : state.wmChosen ? (
          <>
            <Typography
              className="question-text"
              mb="15px"
              headingLevel="large"
              fontWeight={700}
            >
              {t("existingPatients.getStartedHeader")}
            </Typography>
            <DMApp
              wmIncluded={wmIncluded}
              healthGoalsVerbiage={healthGoalsVerbiage}
            />
          </>
        ) : //DM II and every other combination
        state.visitNeeded ? (
          state.scheduleChoice === "Now" ? (
            <>
              <NumericCircleConfirmation
                number={1}
                questionText={t("existingPatients.attendVideoConsultation")}
              />
              <AttendVV
                state={state}
                healthGoalsVerbiage={healthGoalsVerbiage}
                apptDateandTimeFormatted={apptDateandTimeFormatted}
                apptDate={apptDate}
                apptTimeStart={apptTimeStart}
                apptTimeEnd={apptTimeEnd}
              />
              <NumericCircleConfirmation
                number={2}
                questionText={t("existingPatients.downloadApp")}
              />
              <MyOchApp />
            </>
          ) : (
            <>
              <NumericCircleConfirmation
                number={1}
                questionText={t("existingPatients.scheduleVideoConsultation")}
              />
              <ScheduleVV
                state={state}
                healthGoalsVerbiage={healthGoalsVerbiage}
                setStepCount={setStepCount}
              />
              <NumericCircleConfirmation
                number={2}
                questionText={t("existingPatients.downloadApp")}
              />
              <MyOchApp />
            </>
          )
        ) : (
          //WM + HTN, no VV needed
          <>
            <NumericCircleConfirmation
              number={1}
              questionText={t("existingPatients.waitForDevices")}
            />
            <Typography>
              {t("existingPatients.deviceArrivalInstructions")}{" "}
              {healthGoalsVerbiage}
            </Typography>
            <NumericCircleConfirmation
              number={2}
              questionText={t("existingPatients.downloadApp")}
            />
            <MyOchApp />
          </>
        )
      ) : //New Patients
      state.htnChosen && state.wmChosen ? (
        state.scheduleChoice === "Now" ? (
          <>
            <NumericCircleConfirmation
              number={1}
              questionText={t("existingPatients.attendVideoConsultation")}
            />

            <AttendVV
              state={state}
              healthGoalsVerbiage={healthGoalsVerbiage}
              apptDateandTimeFormatted={apptDateandTimeFormatted}
              apptDate={apptDate}
              apptTimeStart={apptTimeStart}
              apptTimeEnd={apptTimeEnd}
            />
            <NumericCircleConfirmation
              number={2}
              questionText={t("existingPatients.getStartedHeader")}
            />
            <DMApp
              wmIncluded={wmIncluded}
              healthGoalsVerbiage={healthGoalsVerbiage}
            />
          </>
        ) : (
          <>
            <NumericCircleConfirmation
              number={1}
              questionText={t("existingPatients.scheduleVideoConsultation")}
            />
            <ScheduleVV
              state={state}
              healthGoalsVerbiage={healthGoalsVerbiage}
              setStepCount={setStepCount}
            />
            <NumericCircleConfirmation
              number={2}
              questionText={t("existingPatients.getStartedHeader")}
            />
            <DMApp
              wmIncluded={wmIncluded}
              healthGoalsVerbiage={healthGoalsVerbiage}
            />
          </>
        )
      ) : state.wmChosen ? (
        <>
          <NumericCircleConfirmation
            number={1}
            questionText={t("newPatients.finishMyOchsnerSetup")}
          />
          <Typography>
            {t("newPatients.myOchsnerInstructions1")}
            <br />
            <a href={process.env.REACT_APP_MYOCH_URL}>
              {t("newPatients.signInMyOchsner")}
            </a>{" "}
            {t("newPatients.myOchsnerInstructions2")}
          </Typography>
          <NumericCircleConfirmation
            number={2}
            questionText={t("existingPatients.getStartedHeader")}
          />
          <DMApp
            wmIncluded={wmIncluded}
            healthGoalsVerbiage={healthGoalsVerbiage}
          />
        </>
      ) : state.scheduleChoice === "Now" ? (
        <>
          <NumericCircleConfirmation
            number={1}
            questionText={t("existingPatients.attendVideoConsultation")}
          />

          <AttendVV
            state={state}
            healthGoalsVerbiage={healthGoalsVerbiage}
            apptDateandTimeFormatted={apptDateandTimeFormatted}
            apptDate={apptDate}
            apptTimeStart={apptTimeStart}
            apptTimeEnd={apptTimeEnd}
          />
          <NumericCircleConfirmation
            number={2}
            questionText={t("existingPatients.downloadApp")}
          />

          <MyOchApp />
        </>
      ) : (
        <>
          <NumericCircleConfirmation
            number={1}
            questionText={t("existingPatients.scheduleVideoConsultation")}
          />
          <ScheduleVV
            state={state}
            healthGoalsVerbiage={healthGoalsVerbiage}
            setStepCount={setStepCount}
          />
          <NumericCircleConfirmation
            number={2}
            questionText={t("existingPatients.downloadApp")}
          />
          <MyOchApp />
        </>
      )}
    </div>
  );
};
