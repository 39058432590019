import { Typography } from "constants/Typography/Typography";
import "./style.css";
import useTranslation from "common/TranslationHook/useTranslation";

interface NoticeOfPrivacyConsentTextProps {
  forceStaticHTMLSupport?: boolean;
}

const NoticeOfPrivacyConsentText = ({
  forceStaticHTMLSupport = false,
}: NoticeOfPrivacyConsentTextProps) => {
  const { t } = useTranslation("noticeofprivacyconsenttext");

  const TypographyOrP = ({ children, ...rest }: any) =>
    forceStaticHTMLSupport ? (
      <p>{children}</p>
    ) : (
      <Typography {...rest}>{children}</Typography>
    );
  const legalDuties = Array.isArray(
    t("legalObligations.duties", { returnObjects: true }),
  )
    ? t("legalObligations.duties", { returnObjects: true })
    : [];

  const categories = Array.isArray(
    t("howOchsnerUsesAndDisclosesInfo.categories", { returnObjects: true }),
  )
    ? t("howOchsnerUsesAndDisclosesInfo.categories", { returnObjects: true })
    : [];
  const rights = Array.isArray(
    t("MedicalInfoRights.rights", { returnObjects: true }),
  )
    ? t("MedicalInfoRights.rights", { returnObjects: true })
    : [];
  return (
    <TypographyOrP fontSize="small">
      <div>
        {t("medicalInformation")} <br /> <br />
        {t("healthInformationSharing")} <br /> <br />
        {t("healthcarePersonnelAccess")}
        <ol>
          <li>
            {t("section4.listItem1")}

            <a href="https://www.ochsner.org/patients-visitors/complaints-and-grievances/">
              {t("section4.listItem1A")}
            </a>
          </li>
          <li>
            {t("section4.listItem2")}
            <a href={process.env.REACT_APP_MYOCH_URL}>
              {t("section4.listItem2A")}
            </a>
          </li>
        </ol>
      </div>
      <div>
        {t("disclosureOfMedicalInfo")} <br /> <br />
        {t("legalObligations.requiredByLaw")}
      </div>
      <ul>
        {legalDuties.map((duty: string, index: number) => (
          <li key={index}>{duty}</li>
        ))}
      </ul>
      <div>
        <b>{t("howOchsnerUsesAndDisclosesInfo.title")}</b>
        <br />
        {t("howOchsnerUsesAndDisclosesInfo.description")}
        <br />
        <br />
      </div>
      <div>
        <b>{t("howOchsnerUsesAndDisclosesInfo.categories[0].title")} </b>
        {t("howOchsnerUsesAndDisclosesInfo.categories[0].description")}
        <br />
      </div>
      <div>
        <b>{t("howOchsnerUsesAndDisclosesInfo.categories[1].title")} </b>
        {t("howOchsnerUsesAndDisclosesInfo.categories[0].description")}
        <br /> <br />
      </div>
      {categories.map((category: any, index: number) => (
        <div key={index}>
          <b>{category.title}</b> {category.description} <br />
          {Array.isArray(category.subpoints) && (
            <ul>
              {category.subpoints.map((subpoint: string, subIndex: number) => (
                <li key={subIndex}>{subpoint}</li>
              ))}
            </ul>
          )}
          <br />
        </div>
      ))}
      <div>
        <b>{t("MedicalInfoRights.title")}</b> <br />
        {t("MedicalInfoRights.intro")} <br /> <br />
      </div>
      {rights.map((right: any, index: number) => (
        <div key={index}>
          <b>{right.title}</b> {right.description} <br />
          {Array.isArray(right.subpoints) && (
            <ul>
              {right.subpoints.map((subpoint: string, subIndex: number) => (
                <li key={subIndex}>{subpoint}</li>
              ))}
            </ul>
          )}
          {right.additionalInfo && <div>{right.additionalInfo}</div>}
          <br />
        </div>
      ))}

      <div>
        <p>
          <i>
            For services provided at Ochsner Medical Center – Jefferson Highway
            and Ochsner Health Centers:{" "}
          </i>
        </p>
        Ochsner Medical Center – New Orleans <br />
        Patient and Provider Advocacy <br />
        1514 Jefferson Highway <br />
        New Orleans, LA 70121
        <br />
      </div>
      <div>
        <p>
          <i>
            Ochsner Medical Center – Kenner, Ochsner Medical Complex – River
            Parishes, and Ochsner Health Centers located in Kenner:{" "}
          </i>
        </p>
        Ochsner Medical Center – Kenner <br />
        Patient and Provider Advocacy <br />
        180 West Esplanade Avenue <br />
        Kenner, LA 70065
        <br />
      </div>
      <div>
        <p>
          <i>Ochsner Baptist and Ochsner Health Centers located at Baptist: </i>
        </p>
        Ochsner Baptist <br />
        Patient and Provider Advocacy <br />
        2700 Napoleon Avenue <br />
        New Orleans, LA 70115
        <br />
      </div>
      <div>
        <p>
          <i>
            For services provided at Ochsner Medical Center – Baton Rouge or the
            health centers located in the Baton Rouge area:{" "}
          </i>
        </p>
        <br />
        Ochsner Medical Center – Baton Rouge <br />
        Patient and Provider Advocacy <br />
        2700 Napoleon Avenue <br />
        New Orleans, LA 70115
        <br />
      </div>
      <div>
        <p>
          <i>
            For services provided at Ochsner Medical Center – St. Anne or health
            centers located in the Bayou Region:{" "}
          </i>
        </p>
        Ochsner Medical Center – St Anne <br />
        Patient and Provider Advocacy <br />
        4608 Highway 1 <br />
        Raceland, LA 70394 <br />
      </div>
      <div>
        <p>
          <i>
            For services provided at Ochsner Medical Center West Bank and health
            centers located on the West Bank of New Orleans and Jefferson
            Parish:{" "}
          </i>
        </p>
        Ochsner Medical Center – West Bank
        <br />
        Patient and Provider Advocacy <br />
        2500 Belle Chasse Highway <br />
        Gretna, LA 70056 <br />
      </div>
      <div>
        <p>
          <i>
            For services provided at Ochsner Medical Center – Northshore or the
            health centers located in Slidell, Covington, Mandeville, Abita
            Springs, and Hammond:{" "}
          </i>
        </p>
        Ochsner Medical Center – Northshore <br />
        Patient and Provider Advocacy <br />
        100 Medical Center Drive <br />
        Slidell, LA 70461 <br />
        <br />
      </div>
      <div>
        <b>{t("changesToThisNotice.title")}</b> <br />
        {t("changesToThisNotice.description")} <br /> <br />
      </div>
      <div>
        <b>{t("complaints.title")}</b> <br />
        {t("complaints.description")} <br /> <br />
      </div>
      <div>
        <b>{t("otherUsesOfMedicalInformation.title")}</b> <br />
        {t("otherUsesOfMedicalInformation.description")} <br /> <br />
      </div>
      <br />
      <br />
      <div>
        <b>{t("whoMustFollowThisNotice.title")}</b> <br />
        <br />
        {t("whoMustFollowThisNotice.description")}
        <ul>
          {Array.isArray(
            t("whoMustFollowThisNotice.subpoints", { returnObjects: true }),
          )
            ? t("whoMustFollowThisNotice.subpoints", {
                returnObjects: true,
              }).map((subpoint: string, index: number) => (
                <li key={index}>{subpoint}</li>
              ))
            : []}
        </ul>{" "}
        <br />
        {t("whoMustFollowThisNotice.additionalInfo")}
        <br />
        Ochsner Health <br />
        Compliance and Privacy Department <br />
        Attn: Privacy Officer <br />
        1450 Poydras St., Ste. 150 <br />
        New Orleans, LA 70112 <br />
        Telephone Number: 504-842-9323
        <br />
        <br />
        {t("whoMustFollowThisNotice.additionalInfoSecond")} <br />
        <br />
      </div>
      <div>
        {t("attention")} <br />
        <br />
        ATENCIÓN: si habla español, tiene a su disposición servicios gratuitos
        de asistencia lingüística. Llame al 1-800-928-6247. <br />
        <br />
        CHÚ Ý: Nếu bạn nói Tiếng Việt, có các dịch vụ hỗ trợ ngôn ngữ miễn phí
        dành cho bạn. Gọi số 1-800-928-6247.
      </div>
    </TypographyOrP>
  );
};

export default NoticeOfPrivacyConsentText;
