import { Typography } from "constants/Typography/Typography";
import BackForwardButton from "common/BackForwardButton";
import { IContextState } from "store/Context";
import { useEffect } from "react";
import { useApi } from "store/useApi";
import useTranslation from "common/TranslationHook/useTranslation";

interface ExclusionaryCriteriaErrorPageProps {
  nextStep: () => void;
  backStep: () => void;
  closeButtonAction: () => void;
  stepNumber: number;
  setStepCount: (step: number) => void;
  state: IContextState;
}

export const ExclusionaryCriteriaErrorPage = ({
  closeButtonAction,
}: ExclusionaryCriteriaErrorPageProps) => {
  const { t } = useTranslation("ExclusionaryCriteriaError");
  const { PatientProgress } = useApi();

  useEffect(() => {
    PatientProgress("WtMgmtIneligible").then(() => {});
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 140,
      }}
    >
      <div>
        <Typography>{t("errorMessage")}</Typography>
      </div>

      <BackForwardButton
        closeButtonAction={closeButtonAction}
        backVisible={false}
        frontVisible={false}
        closeButtonVisible={true}
      />
    </div>
  );
};
