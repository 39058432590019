// Button.tsx

interface ButtonProps {
  label: string;
  onClick: () => void;
  className?: string;
}

const Button = ({ label, onClick, className }: ButtonProps) => {
  return (
    <div className={className} onClick={onClick}>
      {label}
    </div>
  );
};

export default Button;
