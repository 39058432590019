import React, { createContext, useState, useContext, ReactNode } from "react";
type Languages = "en" | "es";
export interface IContextState {
  sessionId: string;
  patientId: string;
  FirstName: string;
  LastName: string;
  hasCgm: boolean;
  directEnroll: boolean;
  PhoneNumber: number;
  BirthDate: string;
  EmailAddress: string;
  gender: string;
  Last4SSN: string;
  LivingState: string;
  StreetAddress: string;
  ZipCode: number;
  City: string;
  streetAddressOne: string;
  streetAddressTwo: string;
  comingFromSignup: boolean;
  oauth: boolean;
  Capmet: string;
  directLink: string;
  sponsor: string;
  htn: boolean;
  diab: boolean;
  newFlow: boolean;
  alternateAddress: string;
  alternateCity: string;
  alternateState: string;
  alternateZipCode: string;
  userAccessCode: string;
  stepCount: number;
  diabChosen: boolean;
  htnChosen: boolean;
  fullAddress: string;
  appointmentDate: string;
  appointmentTime: string;
  appointmentTimeDisplay: string;
  appointmentDateTime: string;
  appointmentTimeZoneAbbr: string;
  providerId: string;
  appointmentDuration: number;
  obar: string;
  idTypePrompt: string;
  idType: string;
  phoneType: string;
  noCuffSize: boolean;
  cuffSize: string;
  businessAddress: boolean;
  glucEligible: boolean;
  cuffEligible: boolean;
  visitNeeded: boolean;
  htnProgramBenefits: boolean;
  diabProgramBenefits: boolean;
  wmMedsProgram: boolean;
  wmLifestyleProgram: boolean;
  scheduleChoice: string;
  hasMyChartAccount: string;
  sponsorId: string;
  AddressLine: string;
  chosenHealthCondition: string;
  BMI: number;
  threefourtyB: boolean;
  id: string;
  pageToNavigate: number;
  ACE: boolean;
  [key: string]: string | boolean | number | any;
}

interface MyProviderProps {
  children: ReactNode;
}
export interface IContextActions {
  setValue: (name: string, value: string | boolean | number | any) => void;
}

interface IMyContext {
  state: IContextState;
  actions: IContextActions;
  lang: Languages;
  setLang: (lang: Languages) => void;
}
const MyContext = createContext<IMyContext | undefined>(undefined);
export const MyProvider = ({ children }: MyProviderProps) => {
  console.log("Initializing MyProvider...");
  const [state, setState] = useState<IContextState>({
    sessionId: "",
    patientId: "",
    FirstName: "",
    LastName: "",
    hasCgm: false,
    directEnroll: false,
    PhoneNumber: 0,
    BirthDate: "",
    EmailAddress: "",
    gender: "",
    Last4SSN: "",
    LivingState: "",
    StreetAddress: "",
    ZipCode: 0,
    City: "",
    streetAddressOne: "",
    streetAddressTwo: "",
    comingFromSignup: false,
    oauth: false,
    Capmet: "",
    directLink: "",
    sponsor: "",
    htn: false,
    diab: false,
    newFlow: true,
    alternateAddress: "",
    alternateCity: "",
    alternateState: "",
    alternateZipCode: "",
    userAccessCode: "",
    stepCount: 0,
    diabChosen: false,
    htnChosen: false,
    wmChosen: false,
    fullAddress: "",
    appointmentDate: "",
    appointmentTime: "",
    appointmentTimeDisplay: "",
    appointmentDuration: 30,
    appointmentDateTime: "",
    appointmentTimeZoneAbbr: "",
    providerId: "",
    obar: "",
    idTypePrompt: "",
    idType: "",
    phoneType: "",
    noCuffSize: false,
    cuffSize: "",
    businessAddress: false,
    glucEligible: false,
    cuffEligible: false,
    visitNeeded: false,
    htnProgramBenefits: false,
    diabProgramBenefits: false,
    scheduleChoice: "",
    hasMyChartAccount: "",
    sponsorId: "",
    AddressLine: "",
    chosenHealthCondition: "",
    htnEnrolled: false,
    diabEnrolled: false,
    wmEnrolled: false,
    htnEligible: false,
    diabEligible: false,
    wmEligible: false,
    wmLifestyleProgram: false,
    wmMedsProgram: false,
    quizFailed: false,
    photoIdFailed: false,
    needToConsent: true,
    height: 0,
    weight: 0,
    BMI: 0,
    threefourtyB: false,
    wmMedication: "",
    id: "",
    pageToNavigate: 0,
    LastConsentDate: null,
    LastSponsor: null,
    ACE: false,
  });
  const [lang, setLang] = useState<Languages>("en");
  const setValue: IContextActions["setValue"] = (name, value) => {
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const contextValue: IMyContext = {
    state,
    actions: { setValue },
    lang,
    setLang,
  };
  return (
    <MyContext.Provider value={{ ...contextValue, lang, setLang }}>
      {children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyProvider");
  }
  return context;
};
