import { Typography } from "constants/Typography/Typography";
import { FontWeight } from "constants/Fonts";
import { IContextActions, IContextState } from "store/Context";
import useTranslation from "common/TranslationHook/useTranslation";
import "./style.css";

interface CostConsentHTML4Props {
  state: IContextState;
  actions: IContextActions;
  forceStaticHTMLSupport?: boolean;
}

export const CostConsentHTML4 = ({
  forceStaticHTMLSupport = false,
}: CostConsentHTML4Props) => {
  const { t } = useTranslation("CostConsentHTML4");

  const TypographyOrP = ({ children, ...rest }: any) =>
    forceStaticHTMLSupport ? (
      <p>{children}</p>
    ) : (
      <Typography {...rest}>{children}</Typography>
    );
  const subpoints = t("weightManagementDescription", { returnObjects: true });
  return (
    <TypographyOrP>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          alignSelf: "stretch",
        }}
      >
        <div>
          <TypographyOrP fontWeight={FontWeight.bold}>
            {t("hypertensionTitle")}
          </TypographyOrP>
          <Typography
            dangerouslySetInnerHTML={{
              __html: t("hypertensionDescription"),
            }}
          />

          <div>
            <TypographyOrP fontWeight={FontWeight.bold}>
              {t("type2DiabetesTitle")}
            </TypographyOrP>
            <Typography
              dangerouslySetInnerHTML={{
                __html: t("type2DiabetesDescription"),
              }}
            />
          </div>
          <div>
            <TypographyOrP fontWeight={FontWeight.bold}>
              {t("weightManagementTitle")}
            </TypographyOrP>
            <TypographyOrP>
              <ul>
                {Array.isArray(subpoints) &&
                  subpoints.map((point: string, idx: number) => (
                    <li key={idx}>{point}</li>
                  ))}
              </ul>
            </TypographyOrP>

            <Typography
              dangerouslySetInnerHTML={{
                __html: t("weightManagementReminder"),
              }}
            />
          </div>
        </div>
      </div>
    </TypographyOrP>
  );
};
