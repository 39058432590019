import styled from "styled-components";
import { Typography } from "constants/Typography/Typography";
import { ReactComponent as Bullet } from "../assets/svgs/ellipse.svg";
import ellipse from "../assets/svgs/ellipse.svg";

const GoldenBullets = styled.div`
  display: flex;
  flex-direction: column;
`;

const BulletLogoDiv = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
`;

const StyledBullet = styled(Bullet)`
  margin-right: 10px;
  fill: ${(props) => props.theme.colors.secondary};
`;

interface BulletPointsComponentProps {
  bulletPoints: string[];
  ellipse?: string;
}

const BulletPointsComponent = ({
  bulletPoints,
  ellipse: ellipsePath,
}: BulletPointsComponentProps) => {
  return (
    <GoldenBullets>
      {Array.isArray(bulletPoints) && bulletPoints.length > 0 ? (
        bulletPoints?.map((text: any, index) => (
          <BulletLogoDiv key={index}>
            <div>
              <StyledBullet />
            </div>
            <Typography mb="12px" style={{ marginTop: 0 }}>
              {text}
            </Typography>
          </BulletLogoDiv>
        ))
      ) : (
        <Typography>No bullet points available.</Typography>
      )}
    </GoldenBullets>
  );
};

BulletPointsComponent.defaultProps = {
  bulletPoints: [],
  ellipse: "",
};

export default BulletPointsComponent;
