import { Typography } from "constants/Typography/Typography";

import { useEffect, useState } from "react";
import Checkbox from "common/CheckboxNew";
import StateSelect from "common/StateSelect";
import Button from "common/Button";
import { IContextActions } from "store/Context";
import "./style.css";
import InputField from "common/InputField/InputField";
import PatternInputField from "common/InputField/PatternFormat";
import { FontWeight } from "constants/Fonts";
import { styled } from "styled-components";

interface PopUpChangeAddressProps {
  showPopup: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  actions: IContextActions;
}

export const PopUpChangeAddress = ({
  setShowPopup,
  actions,
}: PopUpChangeAddressProps) => {
  const [streetAddress, setStreetAddress] = useState("");
  const [addressLine, setAddressLine] = useState("");
  const [city, setCity] = useState("");
  const [livingState, setLivingstate] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [businessAddress, setBusinessAddress] = useState(false);
  const [streetAddressError, setStreetAddressError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [livingstateError, setLivingstateError] = useState(false);
  const [zipCodeError, setZipCodeError] = useState(false);
  const [birthdateError, setBirthdateError] = useState(false);
  const [last4SSNError, setLast4SSNError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [fullAddress, setFullAddress] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  const updateFullAddress = () => {
    const combinedAddress = addressLine
      ? `${streetAddress}, ${addressLine}`
      : streetAddress;
    setFullAddress(combinedAddress);
  };
  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [streetAddress, addressLine, city, livingState, zipCode]);

  const onChange = (event: any) => {
    const { name, value } = event.target;
    actions.setValue(name, value);
    switch (name) {
      case "StreetAddress":
        setStreetAddress(value);
        if (value.trim() !== "") setStreetAddressError(false);
        break;
      case "AddressLine":
        setAddressLine(value);
        break;
      case "City":
        setCity(value);
        if (value.trim() !== "") setCityError(false);
        break;
      case "LivingState":
        setLivingstate(value);
        if (value.trim() !== "") setLivingstateError(false);
        break;
      case "ZipCode":
        setZipCode(value);
        if (value.trim() !== "") setZipCodeError(false);
        break;
    }
    if (name === "StreetAddress" || name === "AddressLine") {
      updateFullAddress();
    }
  };

  const handleCheckBox = () => {
    setBusinessAddress(!businessAddress);
  };

  const checkFormValidity = () => {
    return (
      streetAddress.trim() !== "" &&
      city.trim() !== "" &&
      livingState.trim() !== "" &&
      zipCode.trim() !== ""
    );
  };

  const validateBasicForm = () => {
    let isValid = true;
    if (streetAddress === "") {
      setStreetAddressError(true);
      isValid = false;
    } else {
      setStreetAddressError(false);
    }
    if (city === "") {
      setCityError(true);
      isValid = false;
    } else {
      setCityError(false);
    }
    if (livingState === "") {
      setLivingstateError(true);
      isValid = false;
    } else {
      setLivingstateError(false);
    }
    if (zipCode === "") {
      setZipCodeError(true);
      isValid = false;
    } else {
      setZipCodeError(false);
    }

    return isValid;
  };

  const handleSave = () => {
    const isFormValid = validateBasicForm();

    if (isFormValid) {
      actions.setValue("fullAddress", fullAddress);
      actions.setValue("streetAddressOne", streetAddress);
      actions.setValue("streetAddressTwo", addressLine);
      actions.setValue("City", city);
      actions.setValue("LivingState", livingState);
      actions.setValue("ZipCode", zipCode);
      if (businessAddress) {
        actions.setValue("businessAddress", true);
      }
      setShowPopup(false);
    }
  };
  return (
    <div>
      <Typography
        headingLevel="h4"
        fontWeight={FontWeight.normal}
        style={{ marginTop: "20px" }}
      >
        Change Shipping Address
      </Typography>

      <div className="input-field-div">
        <InputField
          label="Street Address"
          name="StreetAddress"
          value={streetAddress}
          error={streetAddressError}
          onChange={onChange}
        />
        <InputField
          label="Address Line 2 (optional)"
          name="AddressLine"
          value={addressLine}
          onChange={onChange}
        />

        <div className="input-field-wrapper">
          <InputField
            label="City"
            name="City"
            error={cityError}
            value={city}
            onChange={onChange}
          />

          <StateSelect
            livingState={livingState}
            onChange={onChange}
            livingstateError={livingstateError}
          />
        </div>
        <PatternInputField
          label="Zip Code"
          name="ZipCode"
          value={zipCode}
          format="#####"
          error={zipCodeError}
          onChange={onChange}
        />
        <Checkbox
          checked={businessAddress}
          onChange={handleCheckBox}
          label="This is a business address"
          error={false}
          marginTop={true}
        />
        <StyledSaveButton label={"Save"} onClick={handleSave} />
      </div>
    </div>
  );
};

const StyledSaveButton = styled(Button)`
  display: flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;

  border: 2px solid ${(props) => props.theme.colors.buttonPrimary};
  background-color: ${(props) => props.theme.colors.buttonPrimary};
  color: ${(props) => props.theme.colors.buttonPrimaryText};
  transition:
    background-color 0.3s,
    border-color 0.3s;

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colors.buttonPrimaryHover};
    border-color: ${(props) => props.theme.colors.buttonPrimaryHover};
  }
`;
