import { Typography } from "constants/Typography/Typography";
import MyOchsnerApp from "../../assets/images/myOchApp.png";
import useTranslation from "common/TranslationHook/useTranslation";
import "../../common/style.css";
import "add-to-calendar-button";
import "moment-timezone";
import "../style.css";

const MyOchApp = () => {
  const { t } = useTranslation("Confirmation");

  return (
    <div
      style={{
        display: "flex",
        alignItems: "flex-start",
        gap: "24px",
        alignSelf: "stretch",
      }}
    >
      <img src={MyOchsnerApp} />
      <Typography
        dangerouslySetInnerHTML={{
          __html: t("downloadInstruction"),
        }}
      />
    </div>
  );
};

export default MyOchApp;
