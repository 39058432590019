import { FontFamily, FontWeight } from "constants/Fonts";
import { useRef, useState, useEffect, type ChangeEvent } from "react";
import { Typography } from "constants/Typography/Typography";
import { Color } from "constants/Colors";
import { styled } from "styled-components";
import addIcon from "../assets/svgs/add_icon.svg";
import useTranslation from "./TranslationHook/useTranslation";

interface FileUploaderProps {
  sideOfID: string;
  retry: boolean;
  handleUpload: (base64String: string, fileTooBig: boolean) => void;
  setFileUploadError: any;
}

const StyledAddPhotoButton = styled.button`
  width: 100%;
  text-align: center;
  padding: 12px 32px;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  border: 2px solid ${(props) => props.theme.colors.buttonPrimary};
  background-color: ${(props) => props.theme.colors.buttonPrimary};
  color: ${(props) => props.theme.colors.buttonPrimaryText};
  font-family: "Montserrat-Medium";
  cursor: pointer;
  transition:
    background-color 0.3s,
    border-color 0.3s;

  &:hover,
  :focus,
  :active {
    background-color: ${(props) => props.theme.colors.buttonPrimaryHover};
    border-color: ${(props) => props.theme.colors.buttonPrimaryHover};
    transition: color 0.3s ease;
    outline: none;
  }
  &.disabled {
    background-color: var(--disable-light-grey-color);
    border-color: var(--disable-light-grey-color);
    pointer-events: none;
    color: var(--disable-medium-grey-text-color);
  }
`;

const FileUploader = ({
  sideOfID,
  retry,
  handleUpload,
  setFileUploadError,
}: FileUploaderProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const [uploaded, setUploaded] = useState<boolean | undefined>(undefined);
  const [file, setFile] = useState<File | null>(null);
  const [fileTooBig, setFileTooBig] = useState<boolean>(false);
  const [preview, setPreview] = useState<string | undefined>(undefined);
  const { t } = useTranslation("photoidverification");

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files ? event.target.files[0] : null;
    if (newFile) {
      if (newFile.type === "image/jpeg" || newFile.type === "image/png") {
        setFile(newFile);

        const reader = new FileReader();
        reader.readAsDataURL(newFile);

        reader.onload = () => {
          const base64String = (reader.result as string)
            .replace("data:", "")
            .replace(/^.+,/, "");
          handleUpload(base64String, fileTooBig);
        };
        setFileUploadError(false);
        setUploaded(true);
      } else {
        // Handle the case for unsupported file types
        setFileUploadError(true);
        setUploaded(false);
      }
    }
  };

  useEffect(() => {
    if (!file) {
      setUploaded(undefined);
      return;
    }

    if (file.size >= 2000000) {
      setFileTooBig(true);
      setFileUploadError(true);
    } else {
      setFileTooBig(false);
      setFileUploadError(false);
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [file]);

  return (
    <div className="photoUploadContainer">
      {uploaded && preview && (
        <img className="licenseImg" src={preview} alt="" />
      )}
      {uploaded && <Typography>{file?.name}</Typography>}
      {uploaded ? (
        <>
          <Typography
            onClick={handleClick}
            textDecoration="underline"
            fontWeight={FontWeight.bold}
          >
            {t("change")}
          </Typography>
          <input
            type="file"
            accept="image/*;capture=camera, "
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
          />
        </>
      ) : (
        <div>
          <StyledAddPhotoButton
            onClick={handleClick}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px 0px",
              marginTop: 24,
            }}
          >
            <img src={addIcon} alt="Add" width="14px" height="14px" />
            <Typography
              color={Color.white}
              fontFamily={FontFamily.semiBold}
              style={{ marginLeft: "8px" }}
              mb="13px"
            >
              {t("addPhoto")}
            </Typography>
          </StyledAddPhotoButton>

          <input
            type="file"
            accept="image/*;capture=camera, image/jpeg, image/png"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
          />
        </div>
      )}
    </div>
  );
};

export default FileUploader;
