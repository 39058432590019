import { Typography } from "constants/Typography/Typography";
import styled, { useTheme } from "styled-components";
import { useSpring, animated } from "@react-spring/web";
import { ReactNode, useEffect, useRef, useState } from "react";
import useTranslation from "common/TranslationHook/useTranslation";

const ContentContainer = styled.div`
  padding: var(--content-gap) var(--container-padding);
  gap: ${(props) => (props.theme.format > 1 ? "32px" : "24px")};
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  max-width: var(--max-content-width);
  align-self: stretch;
  margin: auto;
`;

const Header = styled(Typography)`
  z-index: 5;
  text-align: ${(props) => (props.theme.format > 1 ? "center" : null)};
`;

const AccordionRect = styled.div`
  background-color: var(--bg-default);
`;

const StyledLink = styled.a`
  color: ${(props) => props.theme.colors.textPrimary};
  font-weight: 700;
  text-decoration-line: underline;
  &:hover {
    cursor: pointer;
  }
  &:active {
    color: ${(props) => props.theme.colors.textLink};
  }
`;

interface FAQProps {
  smoothScrolling: () => void;
}

const FAQ = ({ smoothScrolling }: FAQProps) => {
  const [selected, setSelected] = useState<number[]>([]);
  const { t } = useTranslation("faq");
  const signUpLink = (
    <StyledLink onClick={smoothScrolling}>{t("signUpLink")}</StyledLink>
  );
  const noOfficeVisitsRequired = <b>{t("noOfficeVisitsRequired")}</b>;
  const faqPageLink = (
    <StyledLink
      href="https://connectedhealth.ochsner.org/digital-medicine/faqs"
      target="_blank"
    >
      {t("faqPageLink")}
    </StyledLink>
  );
  const phoneLink = (
    <StyledLink href="tel:8662730548">{t("phoneLink")}</StyledLink>
  );

  const accordionElements: [header: string, body: ReactNode][] = [
    [
      t("faqItems[0].question"),
      <div>
        <Typography marginTop="0px" mb="0px">
          {t("faqItems[0].answer", { signUpLink })}
        </Typography>
      </div>,
    ],
    [
      t("faqItems[1].question"),
      <div>
        <Typography marginTop="0px" mb="0px">
          {t("faqItems[1].answer")}
        </Typography>
      </div>,
    ],
    [
      t("faqItems[2].question"),
      <div>
        <Typography marginTop="0px" mb="0px">
          {t("faqItems[2].answer", { signUpLink })}
        </Typography>
      </div>,
    ],
    [
      t("faqItems[3].question"),
      <div>
        <Typography marginTop="0px" mb="0px">
          {t("faqItems[3].answer")}
        </Typography>
      </div>,
    ],
    [
      t("faqItems[4].question"),
      <div>
        <Typography marginTop="0px" mb="0px">
          {t("faqItems[4].answer")}
        </Typography>
      </div>,
    ],
    [
      t("faqItems[5].question"),
      <div>
        <Typography marginTop="0px" mb="0px">
          {t("faqItems[5].answer", { noOfficeVisitsRequired })}
        </Typography>
      </div>,
    ],
    [
      t("faqItems[6].question"),
      <div>
        <Typography marginTop="0px" mb="0px">
          {t("faqItems[6].answer")}
        </Typography>
      </div>,
    ],
    [
      t("faqItems[7].question"),
      <div>
        <Typography marginTop="0px" mb="0px">
          {t("faqItems[7].answer", { faqPageLink, phoneLink })}
        </Typography>
      </div>,
    ],
  ];

  const select = (i: number) => {
    if (selected.includes(i)) {
      setSelected(selected.filter((v) => v != i));
    } else {
      setSelected([...selected, i]);
    }
  };

  return (
    <ContentContainer>
      <Header headingLevel="h2" marginTop="0px" mb={"0px"}>
        {t("faqHeader")}
      </Header>
      <AccordionRect style={{ borderRadius: 4 }}>
        {accordionElements.map((element, i) => (
          <AccordionElement
            key={element[0] + element[1]}
            header={element[0]}
            index={i}
            isLast={accordionElements.length - 1 === i}
            select={select}
            allSelected={selected}
          >
            {element[1]}
          </AccordionElement>
        ))}
      </AccordionRect>
    </ContentContainer>
  );
};

export default FAQ;

const AccordionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: ${(props) => (props.theme.format > 1 ? "24px" : "16px")};
  &:hover {
    cursor: pointer;
  }
`;

const AccordionBody = styled.div`
  padding: ${(props) => (props.theme.format > 1 ? "24px" : "16px")};
`;

const AccordionIconOuter = styled.div`
  display: grid;
  height: 18px;
  align-items: center;
  justify-items: center;
  > * {
    grid-area: 1/1/1/1;
  }
`;

const AccordionHeader = styled(Typography)`
  &:active {
    color: ${(props) => props.theme.colors.textLink};
  }
`;

type AccordionElementProps = {
  header: string;
  index: number;
  isLast: boolean;
  allSelected: number[];
  select: (_: number) => void;
  children: ReactNode;
};

export const AccordionElement = ({
  header,
  index,
  isLast,
  allSelected,
  select,
  children,
}: AccordionElementProps) => {
  const showDivider = () => {
    if (allSelected.includes(index)) {
      return true;
    }
    if (!isLast && !allSelected.includes(index + 1)) {
      return true;
    }
    return false;
  };

  const theme = useTheme();
  const ref = useRef<any>(null);
  const [style, animate] = useSpring(() => ({ height: "0px" }), []);
  const heightProps = useSpring({
    height: allSelected.includes(index) ? 2 : 18,
  });
  const containerProps = useSpring({
    backgroundColor: allSelected.includes(index)
      ? theme.colors.bgLight
      : theme.colors.bgWhite,
    marginBottom:
      allSelected.filter((e) => [index, index + 1].includes(e)).length == 2
        ? 16
        : 0,
    borderRadius: 16,
  });
  const iconColorProps = useSpring({
    backgroundColor: allSelected.includes(index)
      ? theme.colors.textLink
      : theme.colors.textPrimary,
  });
  const dividerProps = useSpring({ opacity: showDivider() ? 1 : 0 });

  useEffect(() => {
    animate.start({
      height:
        (allSelected.includes(index) ? ref.current.offsetHeight : 0) + "px",
    });
  }, [animate, ref, allSelected]);

  return (
    <div>
      <animated.div style={{ height: "100%", ...containerProps }}>
        <AccordionRow
          onClick={() => {
            select(index);
          }}
        >
          <AccordionHeader mb="0px" marginTop="0px" headingLevel="p24">
            {header}
          </AccordionHeader>
          <AccordionIconOuter>
            <animated.div
              style={{
                width: 2,
                borderRadius: 1,
                ...heightProps,
                ...iconColorProps,
              }}
            />
            <animated.div
              style={{
                height: 2,
                width: 18,
                borderRadius: 1,
                ...iconColorProps,
              }}
            />
          </AccordionIconOuter>
        </AccordionRow>
        <animated.div
          style={{
            borderTop: `0.5px solid ${theme.colors.tintPrimary20}`,
            ...dividerProps,
          }}
        ></animated.div>
        <animated.div style={{ overflow: "hidden", ...style }}>
          <AccordionBody ref={ref}>{children}</AccordionBody>
        </animated.div>
      </animated.div>
    </div>
  );
};
