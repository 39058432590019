import React from "react";
import { Typography } from "constants/Typography/Typography";
import { FontWeight } from "constants/Fonts";
import { Color } from "constants/Colors";
import { useEffect, useState } from "react";
import { IContextState } from "store/Context";
import { PAGE_TITLES } from "./wizardscreen/PageTitles";
import { ReactComponent as BPIcon } from "../assets/svgs/program_bp_icon.svg";
import { ReactComponent as GLIcon } from "../assets/svgs/program_gl_icon.svg";
import { ThemeContext } from "styled-components";
import { useContext } from "react";
import { StyledBulletCircle, StyledBulletNumber } from "common/NumericCircle";
import BackForwardButton from "common/BackForwardButton";
import useTranslation from "common/TranslationHook/useTranslation";
import "./style.css";

interface ThankYouSelectionProps {
  nextStep: () => void;
  backStep: () => void;
  HBPSelected: boolean;
  T2Selected: boolean;
  needToConsent: boolean;
  setT2Selected: React.Dispatch<React.SetStateAction<boolean>>;
  visitNeeded: string;
  setStepCount: (step: number) => void;
  setPrevStep: React.Dispatch<React.SetStateAction<number>>;
  setTitle: (title: string) => void;
  state: IContextState;
}

const BulletItem = ({ number, children }: any) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: "16px",
      alignSelf: "stretch",
    }}
  >
    <StyledBulletCircle style={{ height: 32, width: 32 }}>
      <StyledBulletNumber>{number}</StyledBulletNumber>
    </StyledBulletCircle>
    <Typography marginTop="0px" mb="0px">
      {children}
    </Typography>
  </div>
);
const ProgramOption = ({ icon: Icon, text }: any) => {
  const themeContext = useContext(ThemeContext)!;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "16px",
        alignSelf: "stretch",
        border: "1px solid #112E59",
        borderRadius: "8px",
        padding: "16px",
        flex: "1",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
          alignSelf: "stretch",
        }}
      >
        {React.createElement(Icon, {
          style: {
            minHeight: 56,
            minWidth: 56,
            flexShrink: 0,
            fill: themeContext.colors.tintSecondary50,
          },
        })}
        <Typography
          color={Color.strongDarkBlue}
          fontSize="18px"
          fontWeight={FontWeight.normal}
        >
          {text}
        </Typography>
      </div>
    </div>
  );
};

export const ThankYouSelection = ({
  backStep,
  T2Selected,
  HBPSelected,
  needToConsent,
  setStepCount,
  visitNeeded,
  setPrevStep,
  state,
}: ThankYouSelectionProps) => {
  const { t } = useTranslation("ThankYouSelection");

  const handleContinue = () => {
    setPrevStep(PAGE_TITLES.THANK_YOU_SELECTION.id);
    setStepCount(PAGE_TITLES.CONSOLIDATED_CONSENT.id);
  };

  const [directEnroll, setDirectEnroll] = useState(false);

  const themeContext = useContext(ThemeContext)!;

  useEffect(() => {
    if (state.directEnroll === true) {
      // setTitle("You Are Eligible For:");
      setDirectEnroll(true);
      console.log(state.htnChosen + " htn chosen??");
      console.log(state.diabChosen + " diab chosen??");
      // setHBPSelected(state.htnChosen)
      // setT2Selected(state.diabChosen)
    }
    console.log(HBPSelected + "  hbpselected?");
    console.log(T2Selected + "  t2selected?");
  }, []);

  const bulletPoints = [
    {
      condition: needToConsent,
      text: t("steps.reviewTerms"),
    },
    {
      condition: HBPSelected,
      text: t("steps.measureArm"),
    },
    { condition: T2Selected, text: t("steps.glucometerCheck") },
    {
      condition: visitNeeded === "yes" && HBPSelected,
      text: t("steps.scheduleVirtualVisit"),
    },
    {
      condition: visitNeeded === "yes" && T2Selected,
      text: t("steps.scheduleVirtualVisit"),
    },
  ];

  return (
    <div style={{ gap: "24px", marginTop: "24px" }}>
      {HBPSelected && (
        <ProgramOption
          icon={BPIcon}
          text={t("programOptions.highBloodPressure")}
        />
      )}
      {T2Selected && (
        <ProgramOption icon={GLIcon} text={t("programOptions.type2Diabetes")} />
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "16px",
        }}
      >
        <Typography mb="0px">{t("introText")}</Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "12px",
          }}
        >
          {bulletPoints
            .filter((item) => item.condition)
            .map((item, index) => (
              <BulletItem key={index} number={index + 1}>
                {item.text}
              </BulletItem>
            ))}
          <Typography
            color={Color.strongDarkBlue}
            fontSize="20px"
            fontWeight={FontWeight.bold}
          >
            {t("ctaText")}
          </Typography>
        </div>
      </div>
      <BackForwardButton
        backAction={backStep}
        forwardAction={handleContinue}
        backVisible={directEnroll ? false : true}
        frontVisible={true}
        disabled={false}
      />
    </div>
  );
};
