import { Typography } from "constants/Typography/Typography";
import { useEffect, useState } from "react";
import { IContextActions, IContextState } from "store/Context";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "../PageTitles";
import { InputFieldWrapper } from "./styled";
import { ReactComponent as LoadingSpinner } from "../../../assets/svgs/loadingSpinner.svg";
import { useTheme } from "styled-components";
import BackForwardButton from "common/BackForwardButton";
import StateSelect from "common/StateSelect";
import InputField from "common/InputField/InputField";
import PatternInputField from "common/InputField/PatternFormat";
import GenderSelect from "./GenderSelect";
import useTranslation from "common/TranslationHook/useTranslation";
import "./style.css";

interface BasicFormProps {
  nextStep: () => void;
  backStep: () => void;
  setStepCount: (step: number) => void;
  state: IContextState;
  actions: IContextActions;
}

export const BasicForm = ({
  backStep,
  setStepCount,
  actions,
  state,
  nextStep,
}: BasicFormProps) => {
  const [streetAddress, setStreetAddress] = useState("");
  const [addressLine, setAddressLine] = useState("");
  const [city, setCity] = useState("");
  const [livingState, setLivingstate] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [last4SSN, setLast4SSN] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [gender, setGender] = useState("");
  const [streetAddressError, setStreetAddressError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [livingstateError, setLivingstateError] = useState(false);
  const [zipCodeError, setZipCodeError] = useState(false);
  const [birthdateError, setBirthdateError] = useState(false);
  const [last4SSNError, setLast4SSNError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [fullAddress, setFullAddress] = useState("");
  const [birthdateErrorMsg, setBirthdateErrorMsg] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  const { PatientLookup, auditTrail, PatientPrograms } = useApi();
  const theme = useTheme();
  const { t } = useTranslation("basicForm");

  const [ready, setReady] = useState(true);

  const updateFullAddress = () => {
    const combinedAddress = addressLine
      ? `${streetAddress}, ${addressLine}`
      : streetAddress;
    setFullAddress(combinedAddress);
  };
  useEffect(() => {
    auditTrail("The Basics");
  }, []);

  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [
    streetAddress,
    addressLine,
    city,
    livingState,
    zipCode,
    last4SSN,
    birthdate,
    gender,
  ]);

  const checkFormValidity = () => {
    return (
      streetAddress.trim() !== "" &&
      city.trim() !== "" &&
      livingState.trim() !== "" &&
      zipCode.trim() !== "" &&
      last4SSN.trim() !== "" &&
      birthdate.trim() !== "" &&
      gender.trim() !== ""
    );
  };

  const onChange = (event: any) => {
    const { name, value } = event.target;
    actions.setValue(name, value);
    switch (name) {
      case "StreetAddress":
        setStreetAddress(value);
        if (value.trim() !== "") setStreetAddressError(false);
        break;
      case "AddressLine":
        setAddressLine(value);
        break;
      case "City":
        setCity(value);
        if (value.trim() !== "") setCityError(false);
        break;
      case "LivingState":
        setLivingstate(value);
        if (value.trim() !== "") setLivingstateError(false);
        break;
      case "ZipCode":
        setZipCode(value);
        if (value.trim() !== "") setZipCodeError(false);
        break;
      case "Last4SSN":
        setLast4SSN(value);
        if (value.trim() !== "") setLast4SSNError(false);
        break;
      case "BirthDate":
        const parts = value.split("/");
        const formattedValue = `${parts[2]}-${parts[0]}-${parts[1]}`;
        actions.setValue(name, formattedValue);
        setBirthdate(value);
        if (value.trim() !== "") setBirthdateError(false);
        break;
      case "Gender":
        setGender(value);
        if (value.trim() !== "") setGenderError(false);
        break;
      default:
        break;
    }
    if (name === "StreetAddress" || name === "AddressLine") {
      updateFullAddress();
    }
  };
  const calculateAge = (birthdate: string) => {
    const [month, day, year] = birthdate.split("/").map(Number);
    const today = new Date();
    const birthDate = new Date(year, month - 1, day);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };
  const validateBasicForm = () => {
    let isValid = true;
    if (streetAddress === "") {
      setStreetAddressError(true);
      isValid = false;
    } else {
      setStreetAddressError(false);
    }
    if (city === "") {
      setCityError(true);
      isValid = false;
    } else {
      setCityError(false);
    }
    if (livingState === "") {
      setLivingstateError(true);
      isValid = false;
    } else {
      setLivingstateError(false);
    }
    if (zipCode === "") {
      setZipCodeError(true);
      isValid = false;
    } else {
      setZipCodeError(false);
    }
    if (last4SSN === "") {
      setLast4SSNError(true);
      isValid = false;
    } else {
      setLast4SSNError(false);
    }
    if (birthdate === "") {
      setBirthdateError(true);
      isValid = false;
    } else {
      const age = calculateAge(birthdate);
      if (age < 18) {
        setBirthdateError(true);
        setBirthdateErrorMsg(t("birthdateErrorMsgUnder18"));
        isValid = false;
      } else {
        setBirthdateError(false);
      }
    }
    if (!gender) {
      setGenderError(true);
      isValid = false;
    } else {
      setGenderError(false);
    }
    return isValid;
  };

  const handleGenderChange = (selectedGender: string) => {
    setGender(selectedGender);
    setGenderError(false);
    actions.setValue("gender", selectedGender);
  };

  const handleContinue = () => {
    const isFormValid = validateBasicForm();

    if (isFormValid) {
      actions.setValue("fullAddress", fullAddress);
      actions.setValue("streetAddressOne", streetAddress);
      actions.setValue("streetAddressTwo", addressLine);
      actions.setValue("City", city);
      actions.setValue("LivingState", livingState);
      actions.setValue("ZipCode", zipCode);
      actions.setValue("gender", gender);

      setReady(false);
      PatientLookup().then((data) => {
        if (data.PatientId !== "") {
          // actions.setValue("htn", data.HTN);
          // actions.setValue("diab", data.DIAB);
          actions.setValue("patientId", data.PatientId);
          PatientPrograms(data.PatientId, state.oauth).then((data: any) => {
            if (
              data.CapMet === false &&
              !data.Enrolled.length &&
              !data.Eligible.length &&
              data.Sponsor === ""
            ) {
              auditTrail("Not Eligible");
              auditTrail("Came from MyOchsner");
              actions.setValue("comingFromSignup", true);
              setStepCount(PAGE_TITLES.ERROR_FINANCIAL_ELIGIBLE.id);
            } else if (data.CapMet === "true") {
              actions.setValue("comingFromSignup", true);
              actions.setValue("oauth", true);
              actions.setValue("hasMyChartAccount", true);
              auditTrail("Cap Met");
              setStepCount(PAGE_TITLES.ERROR_PATIENT_CAP.id);
            } else if (!data.Enrolled.length && !data.Eligible.length) {
              auditTrail("Not Eligible");
              setStepCount(PAGE_TITLES.ERROR_FINANCIAL_ELIGIBLE.id);
            } else {
              actions.setValue("Capmet", data.CapMet);
              actions.setValue("sponsor", data.Sponsor);
              actions.setValue("threefourtyB", data.Eligible340B);
              if (data.Enrolled.includes("Hypertension")) {
                actions.setValue("htnEnrolled", true);
              }
              if (data.Enrolled.includes("Type 2 Diabetes")) {
                actions.setValue("diabEnrolled", true);
              }
              if (data.Enrolled.includes("Weight Management")) {
                actions.setValue("wmEnrolled", true);
              }
              if (data.Eligible.includes("Hypertension")) {
                actions.setValue("htnEligible", true);
              }
              if (data.Eligible.includes("Type 2 Diabetes")) {
                actions.setValue("diabEligible", true);
              }
              if (data.Eligible.includes("Weight Management")) {
                actions.setValue("wmEligible", true);
              }
              if (data.WMType === "Lifestyle Only") {
                actions.setValue("wmEligible", true);
                actions.setValue("wmLifestyleProgram", true);
              }
              if (data.WMType === "Med Management") {
                actions.setValue("wmEligible", true);
                actions.setValue("wmMedsProgram", true);
              }
              setStepCount(PAGE_TITLES.SUCCESS_PAGE.id);
            }
          });
        } else {
          setReady(true);
          auditTrail("Patient Not Found");
          setStepCount(PAGE_TITLES.COVERAGE.id);
        }
      });
    }
    auditTrail("Submitted Additional Demographics");
  };
  return (
    <>
      <Typography>{t("basicFormTitle")}</Typography>
      <InputField
        label={t("streetAddressLabel")}
        name="StreetAddress"
        value={streetAddress}
        error={streetAddressError}
        onChange={onChange}
      />
      <InputField
        label={t("addressLine2Label")}
        name="AddressLine"
        value={addressLine}
        onChange={onChange}
      />

      <div className="input-field-wrapper">
        <InputField
          label={t("cityLabel")}
          name="City"
          error={cityError}
          value={city}
          onChange={onChange}
        />
        <StateSelect
          livingState={livingState}
          onChange={onChange}
          livingstateError={livingstateError}
        />
      </div>
      <InputFieldWrapper>
        <PatternInputField
          label={t("zipCodeLabel")}
          name="ZipCode"
          value={zipCode}
          format="#####"
          error={zipCodeError}
          onChange={onChange}
        />
        <PatternInputField
          label={t("last4SSNLabel")}
          name="Last4SSN"
          value={last4SSN}
          format="####"
          error={last4SSNError}
          onChange={onChange}
        />
      </InputFieldWrapper>
      <PatternInputField
        label={t("birthdateLabel")}
        name="BirthDate"
        value={birthdate}
        format="##/##/####"
        error={birthdateError}
        mask={["M", "M", "D", "D", "Y", "Y", "Y", "Y"]}
        placeholder="MM/DD/YYYY"
        onChange={onChange}
        width="47%"
        ErrorMsg={birthdateErrorMsg}
      />
      <GenderSelect
        gender={gender}
        setGender={handleGenderChange}
        genderError={genderError}
      />

      {ready ? (
        <BackForwardButton
          backAction={backStep}
          forwardAction={handleContinue}
          backVisible={false}
          disabled={!isFormValid}
        />
      ) : (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            padding: "16px 0px",
          }}
        >
          <LoadingSpinner
            fill={theme.colors.tertiary}
            style={{ height: 36 }}
            className="spinner"
          />
        </div>
      )}
    </>
  );
};
