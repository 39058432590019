import React from "react";
import { PatternFormat } from "react-number-format";
import { Typography } from "constants/Typography/Typography";
import {
  CommonStyledInput,
  ErrorMessage,
  InputFieldContainer,
  PlaceholderLabel,
} from "./sharedInputStyling";

interface PatternInputFieldProps {
  label?: string;
  name: string;
  value: string | number;
  format: string;
  placeholder?: string;
  mask?: string[];
  maskString?: string;
  error?: boolean;
  errorString?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onValueChange?: (values: { value: string; formattedValue: string }) => void;
  color?: string;
  width?: string;
  flexSize?: string;
  permanentPlaceholder?: boolean;
  ErrorMsg?: any;
}

const PatternInputField = ({
  label,
  name,
  value,
  format,
  placeholder,
  mask,
  error,
  maskString,
  errorString,
  onChange,
  onValueChange,
  color,
  width,
  flexSize,
  ErrorMsg,
  permanentPlaceholder,
}: PatternInputFieldProps) => {
  return (
    <InputFieldContainer flexSize={flexSize}>
      {label && <Typography color={color}>{label}</Typography>}

      <CommonStyledInput
        as={PatternFormat}
        format={format}
        mask={mask ?? maskString}
        name={name}
        value={value}
        placeholder={permanentPlaceholder ? "" : placeholder}
        onChange={onChange}
        onValueChange={onValueChange}
        error={error || !!errorString}
        width={width}
        flexSize={flexSize}
      />
      {permanentPlaceholder && (
        <PlaceholderLabel htmlFor={name}>{placeholder}</PlaceholderLabel>
      )}
      {error && (
        <ErrorMessage>{ErrorMsg ?? "This field is required"}</ErrorMessage>
      )}
      {errorString && <ErrorMessage>{errorString}</ErrorMessage>}
    </InputFieldContainer>
  );
};

export default PatternInputField;
