import { Typography } from "constants/Typography/Typography";
import { ReactComponent as QuoteAsset } from "../../../assets/svgs/quote.svg";
import useTranslation from "common/TranslationHook/useTranslation";
import CountUp from "react-countup";
import styled from "styled-components";

const ContentContainer = styled.div`
  padding: 0px var(--container-padding);
  gap: ${(props) => (props.theme.format > 1 ? "32px" : "24px")};
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  max-width: var(--max-content-width);
  align-self: stretch;
  margin: auto;
`;

const GridContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.theme.format > 1 ? "row" : "column")};
  gap: ${(props) => (props.theme.format > 1 ? "40px" : "24px")};
`;

const GridQuote = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.theme.format > 1 ? "50%" : null)};
  border-radius: 16px;
  background: ${(props) => props.theme.colors.tintSecondary10};
  padding: ${(props) => (props.theme.format > 1 ? "32px" : "24px")};
  gap: 16px;
`;

const StyledQuoteAsset = styled(QuoteAsset)`
  fill: ${(props) => props.theme.colors.secondary};
`;
const StyledQuoteAttribution = styled(Typography)`
  font-size: 18px;
  padding-bottom: 8px;
`;

const GridMetricsContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: ${(props) => (props.theme.format > 1 ? "row" : "column")};
  width: ${(props) => (props.theme.format > 1 ? "50%" : null)};
  align-items: flex-start;
  gap: ${(props) => (props.theme.format > 1 ? "40px" : "24px")};
  align-self: stretch;
`;

const GridMetric = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  align-self: stretch;
  border-radius: 16px;
  background: ${(props) => props.theme.colors.tintTertiary10};
  padding: ${(props) => (props.theme.format > 1 ? "32px" : "24px")};
`;

const MetricNumber = styled(Typography)`
  font-weight: 300;
  font-size: ${(props) => (props.theme.format > 1 ? "64px" : "56px")};
  color: ${(props) => props.theme.colors.tertiary};
  margin-top: -8px;
`;

const Header = styled(Typography)`
  z-index: 5;
  text-align: ${(props) => (props.theme.format > 1 ? "center" : null)};
`;

const CustomerReviewAndMetrics = () => {
  const { t } = useTranslation("customerreviewandmetrics");

  return (
    <ContentContainer>
      <Header headingLevel="h2" marginTop="0px" mb={"0px"}>
        {t("customerReviewAndMetricsHeader")}
      </Header>
      <GridContainer>
        <GridQuote>
          <StyledQuoteAsset />
          <Typography marginTop="0px" mb={"0px"} headingLevel="p24">
            {t("quoteText")}
          </Typography>
          <StyledQuoteAttribution marginTop="0px" mb={"0px"}>
            {t("quoteAttribution")}
          </StyledQuoteAttribution>
        </GridQuote>
        <GridMetricsContainer>
          <GridMetric>
            <MetricNumber marginTop="0px" mb={"0px"} headingLevel="h1">
              {
                <CountUp
                  enableScrollSpy={true}
                  scrollSpyOnce={true}
                  start={58}
                  end={78}
                >
                  {({ countUpRef }) => <span ref={countUpRef} />}
                </CountUp>
              }
              %
            </MetricNumber>
            <Typography marginTop="0px" mb={"0px"} headingLevel="p20">
              {t("metric1Text")}
            </Typography>
          </GridMetric>
          <GridMetric>
            <MetricNumber marginTop="0px" mb={"0px"} headingLevel="h1">
              {
                <CountUp
                  enableScrollSpy={true}
                  scrollSpyOnce={true}
                  start={61}
                  end={81}
                >
                  {({ countUpRef }) => <span ref={countUpRef} />}
                </CountUp>
              }
              %
            </MetricNumber>
            <Typography marginTop="0px" mb={"0px"} headingLevel="p20">
              {t("metric2Text")}
            </Typography>
          </GridMetric>
        </GridMetricsContainer>
      </GridContainer>
    </ContentContainer>
  );
};

export default CustomerReviewAndMetrics;
