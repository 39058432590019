import { Typography } from "constants/Typography/Typography";
import BackForwardButton from "common/BackForwardButton";
import success from "../assets/svgs/success.svg";
import { FontWeight } from "constants/Fonts";
import { IContextActions, IContextState } from "store/Context";
import "./style.css";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "./wizardscreen/PageTitles";
import { useEffect } from "react";
import useTranslation from "common/TranslationHook/useTranslation";

interface SuccessProps {
  setStepCount: (step: number) => void;
  nextStep: () => void;
  questionIdSuccess: boolean;
  photoIdSuccess: boolean;
  setQuestionIdSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setPhotoIdSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  state: IContextState;
  actions: IContextActions;
}

export const SuccessPage = ({
  setStepCount,
  questionIdSuccess,
  setQuestionIdSuccess,
  photoIdSuccess,
  setPhotoIdSuccess,
  state,
  actions,
}: SuccessProps) => {
  const { t } = useTranslation("SuccessPage");
  const { GetMyChartAccount, auditTrail, PatientSession } = useApi();

  const handleContinue = () => {
    if (state.oauth == true && state.directEnroll == false) {
      auditTrail("Came from MyOchsner");
      PatientSession();
      setStepCount(PAGE_TITLES.PROGRAM_SELECTION.id);
    } else if (state.directEnroll == true) {
      setStepCount(PAGE_TITLES.PROGRAM_SELECTION.id);
    } else {
      if (!questionIdSuccess && !photoIdSuccess) {
        if (state.patientId !== "") {
          PatientSession();
          GetMyChartAccount().then((data) => {
            if (data.HasAccount) {
              actions.setValue("hasMyChartAccount", true);
              auditTrail("Has My Chart Account");
              setStepCount(PAGE_TITLES.PROGRAM_SELECTION.id);
            } else if (!data.HasAccount) {
              actions.setValue("hasMyChartAccount", false);
              auditTrail("No My Chart Account");
              setStepCount(PAGE_TITLES.IDENTITY_CONFIRMATION.id);
            }
          });
        } else if (state.patientId === "") {
          setStepCount(PAGE_TITLES.ERROR_GENERAL.id);
        }
      } else {
        setQuestionIdSuccess(false);
        setPhotoIdSuccess(false);
        setStepCount(PAGE_TITLES.PROGRAM_SELECTION.id);
      }
    }
  };
  return (
    <div>
      <div style={{ marginBottom: "25%" }}>
        <Typography style={{ textAlign: "center" }}>
          {questionIdSuccess
            ? t("questionIdSuccess")
            : photoIdSuccess
            ? t("photoIdSuccess")
            : t("genericSuccess")}
        </Typography>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={success} alt="success" className="bounce" />
        </div>
      </div>
      {questionIdSuccess || photoIdSuccess ? (
        <Typography
          style={{ textAlign: "center" }}
          fontWeight={FontWeight.bold}
        >
          {t("successSubText")}
        </Typography>
      ) : null}

      <BackForwardButton forwardAction={handleContinue} backVisible={false} />
    </div>
  );
};
