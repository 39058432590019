import { Typography } from "constants/Typography/Typography";
import useTranslation from "common/TranslationHook/useTranslation";
import "./style.css";

interface FinancialConsentTextProps {
  forceStaticHTMLSupport?: boolean;
}

const FinancialConsentText = ({
  forceStaticHTMLSupport = false,
}: FinancialConsentTextProps) => {
  const { t } = useTranslation("financialconsenttext");

  const TypographyOrP = ({ children, ...rest }: any) =>
    forceStaticHTMLSupport ? (
      <p>{children}</p>
    ) : (
      <Typography {...rest}>{children}</Typography>
    );

  return (
    <TypographyOrP fontSize="small">
      <ol style={{ marginTop: "0px" }}>
        <li>{t("intro.0")}</li>
        <li>
          {t("intro.1")}
          <br />
          <br />
          {t("intro.2")}
          <a href="www.ochsner.org">www.ochsner.org</a>
        </li>
        <br />
        <li>{t("facilityUse")}</li>
      </ol>
      <p>
        {t("nonDiscriminationNotice.english")}
        <br />
        {t("nonDiscriminationNotice.spanish")}
        <br />
        {t("nonDiscriminationNotice.vietnamese")}
      </p>
      <p style={{ textAlign: "center" }}>
        <b>{t("hospitalListTitle")}</b>
      </p>
      <div style={{ display: "flex" }}>
        <div
          style={{
            border: "1px solid black",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            padding: "2px",
          }}
        >
          <u>
            <b>Ochsner Baptist Medical Center:</b>
          </u>
          Schumacher Clinical Partners <br />
          (800) 893-9698, press 1 <br />
          <br />
          <u>
            <b>Ochsner Medical Center Baton Rouge: </b>
          </u>
          <br />
          Diversified Professionals, Inc
          <br />
          (225) 923-0030 <br />
          <br />
          Infamedics
          <br />
          (225) 928-2555 <br />
          <br />
          Southern Radiology Consultants <br />
          (225) 769-6700 <br />
          <br />
          StatRad <br />
          (858) 546-3800 <br />
          <br />
          Schumacher Clinical Partners <br />
          (800) 893-9698, press 1 <br />
          <br />
          <u>
            <b>St. Charles Parish Hospital </b>
          </u>
          <br />
          Schumacher Clinical Partners <br />
          (800) 893-9698, press 1 <br />
          <br />
          Southern Radiology Consultants <br />
          (225) 769-6700
          <br />
          <br />
          <u>
            <b>Leonard J. Chabert Medical Center </b>
          </u>
          <br />
          South Louisiana Medical <br />
          Associates (985) 868-9300 <br />
          <br />
        </div>
        <div
          style={{
            border: "1px solid black",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            padding: "2px",
          }}
        >
          <u>
            <b>Ochsner Medical Center Kenner: </b>
          </u>
          <br />
          Schumacher Clinical Partners <br />
          (800) 893-9698, press 1 <br />
          <br />
          LSU Healthcare Network/Anesthesia Group <br />
          (855) 631-6628 <br />
          <br />
          Southern Radiology Consultants
          <br />
          (225) 769-6700 <br />
          <br />
          <u>
            <b>Ochsner Medical Center West Bank: </b>
          </u>
          <br />
          Pediatric Kid-Med <br />
          (504) 391-7690 <br />
          <br />
          Neonatal Medical Group
          <br />
          (504) 896-2229 <br />
          <br />
          Schumacher Clinical Partners <br />
          (800) 893-9698, press 1 <br />
          <br />
          <u>
            <b>St. Bernard Parish Hospital </b>
          </u>
          <br />
          Parish Anesthesia <br />
          (800) 242-1131 <br />
          <br />
          Schumacher Clinical Partners <br />
          (800) 893-9698, press 1 <br />
          <br />
          <u>
            <b>Ochsner St. Anne </b>
          </u>
          <br />
          Southern Radiology Consultants
          <br />
          (225) 769-6700 <br />
          <br />
        </div>
        <div
          style={{
            border: "1px solid black",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            padding: "2px",
          }}
        >
          <u>
            <b>St. Mary's Morgan City </b>
          </u>
          <br />
          Direct Radiology <br />
          (855)-687-7237 <br />
          <br />
          Houma Radiology Associates <br />
          (985)-876-2727 <br />
          <br />
          Relia-Path <br />
          (337)-233-1899 <br />
          <br />
          SCP Health <br />
          (800)-893-9698 <br />
          <br />
          Bertrand Anesthesia <br />
          (832)-623-8199 <br />
          <br />
          South Louisiana Anesthesia Associates <br />
          (337)-365-3168 <br />
          <br />
          YPS Anesthesia <br />
          (337)-692-3822 <br />
          <br />
          <u>
            <b>Ochsner Christus Lake Charles </b>
          </u>
          <br />
          Radiology Associates of Southwest Louisiana <br />
          800-841-4236 <br />
          <br />
          <u>
            <b>Ochsner Medical Center (Jeff Hwy) </b>
          </u>
          <br />
          Schumacher Clinical Partners <br />
          (800) 893-9698, press 1 <br />
          <br />
        </div>
      </div>
      <p style={{ textAlign: "center" }}>
        <b>
          Ochsner Lafayette General <br /> Provider based physician's billing
          contact information.{" "}
        </b>
      </p>
      <p style={{ textAlign: "center" }}>{t("hospitalListInfo")}</p>
      <div style={{ display: "flex" }}>
        <div
          style={{
            border: "1px solid black",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            padding: "2px",
          }}
        >
          <u>
            <b>Ochsner Acadia General Hospital </b>
          </u>
          <br />
          Preferred Anatomic (billed by ACS) <br />
          337-706-1601 or 261-5151 ext. 1528 <br />
          <br />
          Cardiovascular Institute of the South <br />
          (billed by ACS) <br />
          337-706-1601 or 261-5151 ext. 1528 <br />
          <br />
          Schumacher Group (billed by EMBCC) <br />
          888-703-3301 <br />
          <br />
          Regional Radiology <br />
          504-277-0191 <br />
          <br />
          Lafayette Health Ventures, Inc. <br />
          337-289-7287 <br />
          <br />
          <u>
            <b>Ochsner Abrom Kaplan Hospital </b>
          </u>
          <br />
          Preferred Anatomic (billed by ACS) <br />
          337-706-1601 or 261-5151 ext. 1528 <br />
          <br />
          Abrom Kaplan Anesthesia <br />
          337-643-5281 <br />
          <br />
          Regional Radiology <br />
          504-277-0191 <br />
          <br />
          Cardiovascular Institute of the South (billed by ACS) <br />
          337-706-1601 or 261-5151 ext. 1528 <br />
          <br />
        </div>
        <div
          style={{
            border: "1px solid black",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            padding: "2px",
          }}
        >
          <u>
            <b>Ochsner Lafayette General Medical Center: </b>
          </u>
          <br />
          Preferred Anatomic (billed by ACS) <br />
          337-706-1601 or 261-5151 ext. 1528 <br />
          <br />
          Acadiana Vascular (billed by ACS) <br />
          337-706-1601 or 261-5151 ext.1528 <br />
          <br />
          Southern Vascular Clinic (billed by ACS) <br />
          337-706-1601 or 261-5151 ext.1528 <br />
          <br />
          Cardiovascular Institute of the South(billed by ACS) <br />
          337-706-1601 or 261-5151 ext.1528 <br />
          <br />
          Schumacher Group (billed by EMBCC) <br />
          888-703-3301 <br />
          <br />
          Regional Radiology <br />
          504-277-0191 <br />
          <br />
          Lafayette Health Ventures, Inc. <br />
          337-289-7287 <br />
          <br />
          E+ Oncologics LA, LLC <br />
          888-353-4809 <br />
          <br />
        </div>
        <div
          style={{
            border: "1px solid black",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            padding: "2px",
          }}
        >
          <u>
            <b>Ochsner Lafayette General Orthopedic Hospital </b>
          </u>
          The Pathology Lab, Inc. <br />
          337-436-3688
          <br />
          <br />
          Acadiana Vascular (billed by ACS) <br />
          337-706-1601 or 261-5151 ext.1528 <br />
          <br />
          Southern Vascular Clinic (billed by ACS) <br />
          337-706-1601 or 261-5151 ext.1528 <br />
          <br />
          Cardiovascular Institute of the South(billed by ACS) <br />
          337-706-1601 or 261-5151 ext.1528 <br />
          <br />
          Schumacher Group (billed by EMBCC) <br />
          888-703-3301 <br />
          <br />
          Regional Radiology <br />
          504-277-0191 <br />
          <br />
          Lafayette Health Ventures, Inc. <br />
          337-289-7287 <br />
          <br />
        </div>
      </div>
      <p style={{ textAlign: "center" }}>
        <b>
          Ochsner Lafayette General <br /> Provider based physician's billing
          contact information.{" "}
        </b>
      </p>
      <p style={{ textAlign: "center" }}>{t("hospitalListInfo")}</p>
      <div style={{ display: "flex" }}>
        <div
          style={{
            border: "1px solid black",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            padding: "2px",
          }}
        >
          <u>
            <b>Ochsner Lafayette General Surgical Hospital </b>
          </u>
          <br />
          Preferred Anatomic (billed by ACS) <br />
          337-706-1601 or 261-5151 ext. 1528 <br />
          <br />
          Cardiovascular Institute of the South <br />
          (billed by ACS) <br />
          337-706-1601 or 261-5151 ext. 1528 <br />
          <br />
          Schumacher Group (billed by EMBCC) <br />
          888-703-3301 <br />
          <br />
          <br />
          Regional Radiology <br />
          504-277-0191 <br />
          <br />
          <br />
          Lafayette Health Ventures, Inc. <br />
          337-289-7287 <br />
          <br />
          <u>
            <b>Ochsner St. Martin Hospital</b>
          </u>
          <br />
          Preferred Anatomic (billed by ACS) <br />
          337-706-1601 or 261-5151 ext. 1528 <br />
          <br />
          Schumacher Group (billed by EMBCC) <br />
          888-703-3301 <br />
          <br />
          Regional Radiology <br />
          504-277-0191 <br />
          <br />
          Lafayette Health Ventures, Inc. <br />
          337-289-7287 <br />
          <br />
        </div>
        <div
          style={{
            border: "1px solid black",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            padding: "2px",
          }}
        >
          <u>
            <b>Ochsner University Hospital and Clinics </b>
          </u>
          <br />
          Preferred Anatomic (billed by ACS) <br />
          337-706-1601 or 261-5151 ext. 1528 <br />
          <br />
          Acadiana Vascular (billed by ACS) <br />
          337-706-1601 or 261-5151 ext.1528 <br />
          <br />
          Vein Center of LA (billed by ACS) <br />
          337-706-1601 or 261-5151 ext.1528 <br />
          <br />
          Cardiovascular Institute of the South(billed by ACS) <br />
          337-706-1601 or 261-5151 ext.1528 <br />
          <br />
          LSUHN Billing, LLC <br />
          800-375-2271 or 337-981-2494 <br />
          <br />
          Schumacher Group (billed by EMBCC) <br />
          888-703-3301 <br />
          <br />
          Regional Radiology <br />
          504-277-0191 <br />
          <br />
          Lafayette Health Ventures, Inc. <br />
          337-289-7287 <br />
          <br />
        </div>
        <div
          style={{
            border: "1px solid black",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            padding: "2px",
          }}
        >
          <u>
            <b>Oil Center Surgical Plaza </b>
          </u>
          Preferred Anatomic (billed by ACS) <br />
          337-706-1601 or 261-5151 ext. 1528 <br />
          <br />
          Cardiovascular Institute of the South(billed by ACS) <br />
          337-706-1601 or 261-5151 ext.1528 <br />
          <br />
          Schumacher Group (billed by EMBCC) <br />
          888-703-3301 <br />
          <br />
          Regional Radiology <br />
          504-277-0191 <br />
          <br />
          Lafayette Health Ventures, Inc. <br />
          337-289-7287 <br />
          <br />
        </div>
      </div>
    </TypographyOrP>
  );
};

export default FinancialConsentText;
