import { Typography } from "constants/Typography/Typography";
import BackForwardButton from "common/BackForwardButton";
import { useEffect } from "react";
import { IContextState } from "store/Context";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "../PageTitles";
import { StyledLink } from "screens/landingPage/newLandingPageComponents/JoinTheProgram";
import useTranslation from "common/TranslationHook/useTranslation";

interface IDVerificationErrorProps {
  closeButtonAction: () => void;
  setStepCount: (step: number) => void;
  state: IContextState;
}

export const IDVerificationError = ({
  setStepCount,
  closeButtonAction,
  state,
}: IDVerificationErrorProps) => {
  const { auditTrail } = useApi();

  const { t } = useTranslation("IDVerificationError");
  useEffect(() => {
    auditTrail("Id verification error");
  }, []);

  const handleNav = () => {
    if (!state.quizFailed) {
      setStepCount(PAGE_TITLES.IDENTITY_VERIFICATION_QUESTIONS.id);
    } else if (!state.photoIdFailed) {
      setStepCount(PAGE_TITLES.UPLOAD_PHOTO_ID.id);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 140,
      }}
    >
      <div>
        <Typography>
          {t("idVerificationErrorHeader", {
            supportPhoneLink: (
              <StyledLink
                href="tel:866-273-0548"
                style={{ fontFamily: "Montserrat-Bold" }}
              >
                {t("supportPhoneLink")}
              </StyledLink>
            ),
          })}
        </Typography>

        {!state.photoIdFailed ? (
          <Typography>
            {t("uploadPhotoText", {
              uploadPhotoLink: (
                <StyledLink
                  onClick={handleNav}
                  style={{ fontFamily: "Montserrat-Bold" }}
                >
                  {t("uploadPhotoLink")}
                </StyledLink>
              ),
            })}
          </Typography>
        ) : null}
        {!state.quizFailed ? (
          <Typography>
            {t("answerQuestionsText", {
              answerQuestionsLink: (
                <StyledLink
                  onClick={handleNav}
                  style={{ fontFamily: "Montserrat-Bold" }}
                >
                  {t("answerQuestionsLink")}
                </StyledLink>
              ),
            })}
          </Typography>
        ) : null}
      </div>

      <BackForwardButton
        closeButtonAction={closeButtonAction}
        backVisible={false}
        frontVisible={false}
        closeButtonVisible={true}
      />
    </div>
  );
};
