import React from "react";
import { Typography } from "constants/Typography/Typography";
// import { ReactComponent as Checkmark } from '../../../assets/svgs/check_logo_new.svg'
import { ReactComponent as Checkmark } from "../assets/svgs/check_logo_new.svg";
import { FontSize, FontStyle, FontWeight } from "constants/Fonts";
import { styled } from "styled-components";

interface QualificationProps {
  title: string;
  qualifications: string[];
  checkLogo?: string;
  footerNote?: string;
}

const StyledCheckmark = styled(Checkmark)`
  fill: ${(props) => props.theme.colors.tertiary};
  width: 16px;
  margin-right: 10px;
`;

export const StyledQualificationBox = styled.div`
  padding: 24px;
  font-family: "Montserrat-Medium";
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.secondary};
  background: ${(props) => props.theme.colors.tintSecondary10};
  margin-top: 24px;
  margin-bottom: 40px;
`;

const QualificationComponent: React.FC<QualificationProps> = ({
  title,
  qualifications,
  checkLogo,
  footerNote,
}) => {
  return (
    <StyledQualificationBox>
      <Typography
        fontWeight={FontWeight.bold}
        mb="16px"
        style={{ marginTop: 0 }}
      >
        {title}
      </Typography>
      {Array.isArray(qualifications) && qualifications.length > 0 ? (
        qualifications?.map((text, index) => (
          <div
            className="check-logo-text"
            style={{ alignItems: "flex-start" }}
            key={index}
          >
            <div>
              <StyledCheckmark />
            </div>
            <div>
              <Typography
                style={{ marginTop: 0 }}
                fontSize={FontSize.base}
                mb="12px"
              >
                {text}
              </Typography>
            </div>
          </div>
        ))
      ) : (
        <Typography>No qualifications available.</Typography>
      )}
      {footerNote && (
        <Typography fontStyle={FontStyle.italic} style={{ marginTop: 4 }}>
          {footerNote}
        </Typography>
      )}
    </StyledQualificationBox>
  );
};

export default QualificationComponent;
