import { useState, useEffect } from "react";
import { useMyContext } from "store/Context";
import { translationFiles } from "../../constants/Translations/translationFiles";
import { Namespaces } from "constants/Translations/translationTypes";

type Languages = "en" | "es";

const useTranslation = (namespace: Namespaces) => {
  const { lang, setLang } = useMyContext() as {
    lang: Languages;
    setLang: (lang: Languages) => void;
  };
  const [texts, setTexts] = useState<any>({});

  useEffect(() => {
    const loadTranslation = () => {
      try {
        const translations = translationFiles[lang][namespace];
        setTexts(translations?.default || translations || {});
      } catch {
        console.error(`Translation file not found for ${lang}/${namespace}`);
        setTexts({});
      }
    };

    loadTranslation();
  }, [lang, namespace]);

  const resolveKey = (path: string, obj: any): any => {
    return path.split(".").reduce((acc, key) => {
      const arrayMatch = key.match(/(.+)\[(\d+)\]/);
      if (arrayMatch) {
        const [, arrayKey, indexStr] = arrayMatch;
        const index = parseInt(indexStr, 10);
        return acc?.[arrayKey]?.[index];
      }
      return acc?.[key];
    }, obj);
  };

  const t = (
    key: string,
    components?: { [key: string]: React.ReactNode },
    options: { returnObjects?: boolean } = {},
  ) => {
    const { returnObjects = false } = options;
    let translation = resolveKey(key, texts);

    if (
      returnObjects &&
      (Array.isArray(translation) || typeof translation === "object")
    ) {
      return translation;
    }

    if (!translation) return key;

    if (components && typeof translation === "string") {
      return translation.split(/({[^}]+})/g).map((part, i) => {
        if (part.startsWith("{") && part.endsWith("}")) {
          const componentKey = part.slice(1, -1);
          return components[componentKey] || part;
        }
        return part;
      });
    }

    return translation;
  };

  return { t, setLang, lang };
};

export default useTranslation;
