import { FontFamily } from "constants/Fonts";
import { Typography } from "constants/Typography/Typography";
import { type SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { WelcomeScreen } from "screens/WelcomeScreen";
import { BasicForm } from "screens/wizardscreen/BasicForm/BasicForm";
import { Coverage } from "screens/Coverage";
import { UniqueIdCoverage } from "screens/UniqueIdCoverage";
import { SuccessPage } from "screens/SuccessPage";
import { ConfirmIdentity } from "screens/ConfirmIdentity";
import { QuestionnaireVerification } from "screens/QuestionnaireVerification";
import { PhotoIDVerification } from "screens/UploadPhotoIds/PhotoIDVerification";
import { ProgramSelection } from "screens/ProgramSelection";
import { HBPCuffSize } from "screens/HBPCuffSize";
import { GlucometerSelection } from "screens/GlucometerSelection";
import { SelectShippingAddress } from "screens/SelectShippingAddress";
import { ShippingAddressConfirmed } from "screens/ShippingAddressConfirmed";
import { ScheduleVideoConsultation_2 } from "screens/ScheduleVideoConsultation_2";
import { Confirmation } from "screens/ConfirmationPages/Confirmation";
import WizardHeader from "./Header";

import {
  NoVirtualVisitBOTH,
  NoVirtualVisitDIAB,
  NoVirtualVisitHTN,
  VirtualVisitBOTH,
  VirtualVisitDIAB,
  VirtualVisitHTN,
} from "common/ProgressBar/progressSteps";
import "./style.css";
import { useMyContext } from "store/Context";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "./PageTitles";
import { ExclusionaryCriteria } from "../ExclusionaryCriteria";
import { HeightWeight } from "screens/HeightWeight";
import { WMMedication } from "screens/WMMedication";
import { WMErrorPageProps } from "screens/WMError";
import { ProgramValidation } from "./ProgramValidation/ProgramValidation";
import { ConsolidatedConsent } from "screens/ConsolidatedConsent";
import { styled, useTheme } from "styled-components";
import APiLoader from "./Loader/APiLoader";
import { WMDMIIErrorPageProps } from "screens/WMDMIIError";
import { ExclusionaryCriteriaErrorPage } from "screens/ExclusionaryCriteriaError";
import OauthAPiLoader from "./Loader/OauthAPiLoader";
import { FinancialError } from "./ErrorPages/FinancialError";
import { IDVerificationError } from "./ErrorPages/IDVerificationError";
import { PatientCapError } from "./ErrorPages/PatientCapError";
import { GeneralError } from "./ErrorPages/GeneralError";
import { ScheduleVisitError } from "./ErrorPages/ScheduleVisitError";
import { ThankYouSelection } from "screens/ThankYouSelection";
import { UnderAgeError } from "./ErrorPages/UnderAgeError";
import { UserInfoError } from "./ErrorPages/UserInfoError";
import { ProgramConsentDetails } from "./ConsentForms/ProgramConsentDetails";
import useTranslation from "common/TranslationHook/useTranslation";

export const WizardScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    WELCOME,
    YOUR_INFORMATION,
    COVERAGE,
    IDENTITY_CONFIRMATION,
    IDENTITY_VERIFICATION_QUESTIONS,
    UPLOAD_PHOTO_ID,
    PROGRAM_SELECTION,
    HEIGHT_WEIGHT,
    WM_MEDICATION,
    EXCLUSIONARY_CRITERIA,
    API_LOADER,
    PROGRAM_COST_CONSENT,
    OAUTH_API_LOADER,
    CONSOLIDATED_CONSENT,
    CUFF_SIZE_SELECTION,
    GLUCOMETER_SELECTION,
    SHIPPING_ADDRESS,
    THANK_YOU_SELECTION,
    ADDRESS_CONFIRMATION,
    SCHEDULE_CONSULTATION_SECOND,
    COVERAGE_UNIQUE_ID,
    SUCCESS_PAGE,
    ERROR_FINANCIAL_ELIGIBLE,
    ERROR_ID_VERIFY,
    ERROR_GENERAL,
    ERROR_WM,
    ERROR_WM_DMII,
    ERROR_USERINFO,
    ERROR_EXCLUSIONARYCRITERIA,
    CONFIRMATION_PAGE,
    PROGRAM_VALIDATION,
    ERROR_PATIENT_CAP,
    SCHEDULE_VISIT_ERROR,
    ERROR_UNDERAGE,
  } = PAGE_TITLES;
  const stepCountParam = location.state?.stepCount;
  const { t } = useTranslation("PageTitles");
  const [title, setTitle] = useState(t("welcome.title"));
  const [stepCount, setStepCount] = useState(stepCountParam ?? WELCOME.id);
  const [HBPProgramSelected, setHBPProgramSelected] = useState(false);
  const [T2ProgramSelected, setT2ProgramSelected] = useState(false);
  const [WMProgramSelected, setWMProgramSelected] = useState(false);
  const [disableTitle, setDisableTitle] = useState(false);
  const [questionIdSuccess, setQuestionIdSuccess] = useState(false);
  const [photoIdSuccess, setPhotoIdSuccess] = useState(false);
  const [visitNeeded, setVisitNeeded] = useState("");
  const [scheduleNowChoice, setScheduleNowChoice] = useState(false);
  const [prevStep, setPrevStep] = useState(0);
  const [prevStepCost, setPrevStepCost] = useState(0);
  const [prevStepDevice, setPrevStepDevice] = useState(0);

  const context = useMyContext();
  const { AbandonCartEmail } = useApi();

  if (!context)
    throw new Error("useMyContext must be used within a MyProvider");
  const { state, actions } = context;
  type PageTitleKey = keyof typeof PAGE_TITLES;
  useEffect(() => {
    const currentPageKey = Object.keys(PAGE_TITLES).find(
      (key) => PAGE_TITLES[key as PageTitleKey].id === stepCount,
    ) as PageTitleKey | undefined;

    if (currentPageKey && !disableTitle) {
      setTitle(t(`${currentPageKey}.title`));
    }
  }, [stepCount, disableTitle, t]);

  const nextStep = () => {
    if (stepCount < 24) {
      setStepCount(stepCount + 1);
    }
  };
  const setStep = (nextStep: any | SetStateAction<number>) => {
    setStepCount(nextStep);
    actions.setValue("stepCount", nextStep);
  };

  const backStep = () => {
    if (stepCount == ERROR_GENERAL.id) {
      setStepCount(YOUR_INFORMATION.id);
    } else if (stepCount == UPLOAD_PHOTO_ID.id) {
      setStepCount(IDENTITY_CONFIRMATION.id);
    } else if (stepCount == IDENTITY_CONFIRMATION.id) {
      setStepCount(COVERAGE.id);
    } else if (stepCount == COVERAGE_UNIQUE_ID.id) {
      setStepCount(COVERAGE.id);
    } else if (stepCount == ERROR_FINANCIAL_ELIGIBLE.id) {
      setStepCount(YOUR_INFORMATION.id);
    } else if (stepCount == COVERAGE.id) {
      setStepCount(YOUR_INFORMATION.id);
    } else {
      setStepCount(stepCount - 1);
    }
  };

  const backStepPrev = () => {
    setStepCount(prevStep);
  };

  const backStepPrevCost = () => {
    setStepCount(prevStepCost);
  };

  const backStepPrevDevice = () => {
    setStepCount(prevStepDevice);
  };

  const closeButtonAction = () => {
    actions.setValue("quizFailed", false);
    actions.setValue("photoIdFailed", false);
    navigate("/signup");
  };

  const retryScheduleAction = () => {
    setStepCount(PAGE_TITLES.SCHEDULE_CONSULTATION_SECOND.id);
  };
  useEffect(() => {
    const matchingTitle = Object.values(PAGE_TITLES).find(
      (page) => page.id === stepCount,
    );
    if (matchingTitle) {
      setTitle(matchingTitle.title);
      setTitle(matchingTitle.title);
    }
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    const errorSteps = [
      ERROR_WM.id,
      ERROR_WM_DMII.id,
      ERROR_PATIENT_CAP.id,
      ERROR_EXCLUSIONARYCRITERIA.id,
      ERROR_UNDERAGE.id,
      ERROR_USERINFO.id,
      ERROR_FINANCIAL_ELIGIBLE.id,
      ERROR_GENERAL.id,
    ];

    const excludedSteps = [
      IDENTITY_VERIFICATION_QUESTIONS.id,
      UPLOAD_PHOTO_ID.id,
      OAUTH_API_LOADER.id,
      API_LOADER.id,
    ];
    if (!excludedSteps.includes(stepCount)) {
      const adjustedStepCount = errorSteps.includes(stepCount) ? -1 : stepCount;
      AbandonCartEmail(
        state.EmailAddress,
        adjustedStepCount,
        null,
        state.LastName,
      );
    }

    if (state.diabChosen === true) {
      setT2ProgramSelected(true);
    } else if (state.htnChosen === true) {
      setHBPProgramSelected(true);
    }
  }, [stepCount]);

  useEffect(() => {
    const isValidStepCount = stepCountParam !== undefined;

    if (!state.comingFromSignup || !isValidStepCount) {
      navigate("/signup");
    }
  }, [
    state.comingFromSignup,
    stepCountParam,
    state.scheduleChoice,
    state.quizFailed,
    state.photoIdFailed,
    state.needToConsent,
    state.ACE,
  ]);

  const getStepMap = () => {
    if (visitNeeded) {
      if (HBPProgramSelected && T2ProgramSelected) return VirtualVisitBOTH;
      if (HBPProgramSelected) return VirtualVisitHTN;
      return VirtualVisitDIAB;
    } else {
      if (HBPProgramSelected && T2ProgramSelected) return NoVirtualVisitBOTH;
      if (HBPProgramSelected) return NoVirtualVisitHTN;
      return NoVirtualVisitDIAB;
    }
  };

  const shouldShowProgressBar =
    (stepCount >= 9 && stepCount < 18) || stepCount === CONFIRMATION_PAGE.id;
  const stepMap = getStepMap();
  const theme = useTheme();

  return (
    <StyledMainBody>
      <WizardHeader />
      <div className="cardMain">
        <div className="inner">
          <div
            style={{
              display: "flex",
              justifyContent:
                stepCount === SUCCESS_PAGE.id || stepCount === API_LOADER.id
                  ? "center"
                  : "flex-start",
            }}
          >
            {!disableTitle ? (
              <Typography
                headingLevel="h2"
                marginTop="0px"
                mb="0px"
                color={theme.colors.textPrimary}
              >
                {title}
              </Typography>
            ) : null}
          </div>

          {stepCount === WELCOME.id ? (
            <WelcomeScreen
              setStepCount={setStep}
              backStep={backStep}
              state={state}
            />
          ) : null}

          {stepCount === YOUR_INFORMATION.id ? (
            <BasicForm
              setStepCount={setStep}
              nextStep={nextStep}
              backStep={backStep}
              actions={actions}
              state={state}
            />
          ) : null}

          {stepCount === COVERAGE.id ? (
            <Coverage
              setStepCount={setStep}
              nextStep={nextStep}
              backStep={backStep}
              actions={actions}
              state={state}
            />
          ) : null}

          {stepCount === IDENTITY_CONFIRMATION.id ? (
            <ConfirmIdentity
              setStepCount={setStep}
              nextStep={nextStep}
              backStep={backStep}
              state={state}
            />
          ) : null}
          {stepCount === IDENTITY_VERIFICATION_QUESTIONS.id ? (
            <QuestionnaireVerification
              setStepCount={setStep}
              nextStep={nextStep}
              backStep={backStep}
              setQuestionIdSuccess={setQuestionIdSuccess}
              actions={actions}
            />
          ) : null}
          {stepCount === UPLOAD_PHOTO_ID.id ? (
            <PhotoIDVerification
              setStepCount={setStep}
              nextStep={nextStep}
              backStep={backStep}
              setDisableTitle={setDisableTitle}
              setPhotoIdSuccess={setPhotoIdSuccess}
              setTitle={setTitle}
              actions={actions}
              state={state}
            />
          ) : null}
          {stepCount === PROGRAM_SELECTION.id ? (
            <ProgramSelection
              setStepCount={setStep}
              nextStep={nextStep}
              backStep={backStep}
              setHBPSelected={setHBPProgramSelected}
              setT2Selected={setT2ProgramSelected}
              setWMSelected={setWMProgramSelected}
              setVisitNeeded={setVisitNeeded}
              setPrevStep={setPrevStep}
              state={state}
              actions={actions}
            />
          ) : null}
          {stepCount === THANK_YOU_SELECTION.id ? (
            <ThankYouSelection
              setStepCount={setStep}
              nextStep={nextStep}
              backStep={backStep}
              HBPSelected={HBPProgramSelected}
              T2Selected={T2ProgramSelected}
              visitNeeded={visitNeeded}
              setPrevStep={setPrevStep}
              setTitle={setTitle}
              state={state}
              setT2Selected={setHBPProgramSelected}
              needToConsent={state.needToConsent}
            />
          ) : null}
          {stepCount === HEIGHT_WEIGHT.id ? (
            <HeightWeight
              setStepCount={setStep}
              nextStep={nextStep}
              backStep={backStep}
              HBPSelected={HBPProgramSelected}
              T2Selected={T2ProgramSelected}
              visitNeeded={visitNeeded}
              setPrevStep={setPrevStep}
              setTitle={setTitle}
              actions={actions}
              state={state}
            />
          ) : null}
          {stepCount === PROGRAM_COST_CONSENT.id ? (
            <ProgramConsentDetails
              setStepCount={setStep}
              HBPSelected={HBPProgramSelected}
              T2Selected={T2ProgramSelected}
              prevStep={prevStep}
              state={state}
              setPrevStepDevice={setPrevStepDevice}
            />
          ) : null}

          {stepCount === WM_MEDICATION.id && (
            <WMMedication
              setStepCount={setStep}
              nextStep={nextStep}
              backStep={backStepPrev}
              setPrevStep={setPrevStepCost}
              state={state}
              actions={actions}
              setVisitNeeded={setVisitNeeded}
            />
          )}

          {stepCount === EXCLUSIONARY_CRITERIA.id && (
            <ExclusionaryCriteria
              setStepCount={setStep}
              nextStep={nextStep}
              backStep={backStepPrev}
              setPrevStep={setPrevStepCost}
              state={state}
              actions={actions}
            />
          )}
          {stepCount === ERROR_EXCLUSIONARYCRITERIA.id && (
            <ExclusionaryCriteriaErrorPage
              setStepCount={setStep}
              nextStep={nextStep}
              backStep={backStep}
              closeButtonAction={closeButtonAction}
              stepNumber={stepCount}
              state={state}
            />
          )}
          {stepCount === API_LOADER.id && (
            <APiLoader setStepCount={setStep} actions={actions} state={state} />
          )}
          {stepCount === OAUTH_API_LOADER.id && (
            <OauthAPiLoader
              setStepCount={setStep}
              actions={actions}
              state={state}
            />
          )}
          {stepCount === CONSOLIDATED_CONSENT.id && (
            <ConsolidatedConsent
              nextStep={nextStep}
              backStep={backStepPrevCost}
              setStepCount={setStep}
              actions={actions}
              state={state}
              setPrevStep={setPrevStepDevice}
              HBPSelected={HBPProgramSelected}
              T2Selected={T2ProgramSelected}
              theme={theme}
            />
          )}
          {stepCount === CUFF_SIZE_SELECTION.id && (
            <HBPCuffSize
              setStepCount={setStep}
              nextStep={nextStep}
              backStep={backStep}
              HBPSelected={HBPProgramSelected}
              T2Selected={T2ProgramSelected}
              setPrevStep={setPrevStep}
              setPrevStepDevice={setPrevStepDevice}
              actions={actions}
              state={state}
            />
          )}
          {stepCount === GLUCOMETER_SELECTION.id && (
            <GlucometerSelection
              setStepCount={setStep}
              nextStep={nextStep}
              backStep={backStepPrevDevice}
              HBPSelected={HBPProgramSelected}
              T2Selected={T2ProgramSelected}
              setPrevStep={setPrevStep}
              actions={actions}
            />
          )}
          {stepCount === SHIPPING_ADDRESS.id && (
            <SelectShippingAddress
              setStepCount={setStep}
              nextStep={nextStep}
              backStep={backStepPrev}
              HBPSelected={HBPProgramSelected}
              T2Selected={T2ProgramSelected}
              visitNeeded={visitNeeded}
              state={state}
              actions={actions}
            />
          )}
          {stepCount === ADDRESS_CONFIRMATION.id && (
            <ShippingAddressConfirmed
              setStepCount={setStep}
              nextStep={nextStep}
              backStep={backStep}
              HBPSelected={HBPProgramSelected}
              T2Selected={T2ProgramSelected}
              visitNeeded={visitNeeded}
              state={state}
              actions={actions}
            />
          )}

          {stepCount === SCHEDULE_CONSULTATION_SECOND.id && (
            <ScheduleVideoConsultation_2
              setStepCount={setStep}
              nextStep={nextStep}
              backStep={backStep}
              actions={actions}
              setScheduleNowChoice={setScheduleNowChoice}
              state={state}
            />
          )}
          {stepCount === COVERAGE_UNIQUE_ID.id ? (
            <UniqueIdCoverage
              setStepCount={setStep}
              nextStep={nextStep}
              backStep={backStep}
              state={state}
              actions={actions}
            />
          ) : null}
          {stepCount === SUCCESS_PAGE.id ? (
            <SuccessPage
              setStepCount={setStep}
              nextStep={nextStep}
              questionIdSuccess={questionIdSuccess}
              setQuestionIdSuccess={setQuestionIdSuccess}
              photoIdSuccess={photoIdSuccess}
              setPhotoIdSuccess={setPhotoIdSuccess}
              state={state}
              actions={actions}
            />
          ) : null}
          {stepCount === ERROR_FINANCIAL_ELIGIBLE.id ? (
            <FinancialError
              backStep={backStep}
              closeButtonAction={closeButtonAction}
              state={state}
            />
          ) : null}
          {stepCount === ERROR_ID_VERIFY.id ? (
            <IDVerificationError
              setStepCount={setStep}
              closeButtonAction={closeButtonAction}
              state={state}
            />
          ) : null}
          {stepCount === ERROR_GENERAL.id ? (
            <GeneralError closeButtonAction={closeButtonAction} />
          ) : null}

          {stepCount === ERROR_PATIENT_CAP.id ? (
            <PatientCapError closeButtonAction={closeButtonAction} />
          ) : null}

          {stepCount === SCHEDULE_VISIT_ERROR.id ? (
            <ScheduleVisitError
              closeButtonAction={retryScheduleAction}
              actions={actions}
              setStepCount={setStep}
            />
          ) : null}

          {stepCount === ERROR_WM.id ? (
            <WMErrorPageProps
              setStepCount={setStep}
              nextStep={nextStep}
              backStep={backStep}
              closeButtonAction={closeButtonAction}
              stepNumber={stepCount}
              state={state}
            />
          ) : null}
          {stepCount === ERROR_WM_DMII.id && (
            <WMDMIIErrorPageProps
              setStepCount={setStep}
              nextStep={nextStep}
              backStep={backStep}
              closeButtonAction={closeButtonAction}
              stepNumber={stepCount}
              state={state}
            />
          )}
          {stepCount === ERROR_UNDERAGE.id ? (
            <UnderAgeError closeButtonAction={closeButtonAction} />
          ) : null}
          {stepCount === ERROR_USERINFO.id ? (
            <UserInfoError closeButtonAction={closeButtonAction} />
          ) : null}
          {stepCount === CONFIRMATION_PAGE.id && (
            <Confirmation
              setStepCount={setStep}
              nextStep={nextStep}
              scheduleNowChoice={scheduleNowChoice}
              backStep={backStep}
              HBPSelected={HBPProgramSelected}
              T2Selected={T2ProgramSelected}
              visitNeeded={visitNeeded}
              actions={actions}
              state={state}
            />
          )}
          {stepCount === PROGRAM_VALIDATION.id && (
            <ProgramValidation
              setStepCount={setStep}
              nextStep={nextStep}
              state={state}
            />
          )}
          <hr
            className="hr-line"
            style={{ backgroundColor: theme.colors.secondary }}
          />
          <div className="footer-container">
            <Typography mb="8px" fontFamily={FontFamily.bold}>
              {t("Needhelp")}
            </Typography>
            <Typography marginTop="8px">
              {t("CallUs", {
                supportPhoneLink: (
                  <StyledPhoneNumber className="contact-info">
                    {t("supportPhoneLink")}
                  </StyledPhoneNumber>
                ),
              })}
            </Typography>
          </div>
        </div>
      </div>
    </StyledMainBody>
  );
};

const StyledMainBody = styled.div`
  background-color: ${(props) => props.theme.colors.bgLight};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const StyledPhoneNumber = styled.span`
  font-family: "Montserrat-Bold";
  color: ${(props) => props.theme.colors.textLink};
  transition: all 03s;
  &:hover {
    color: ${(props) => props.theme.colors.textPrimary};
  }
`;
