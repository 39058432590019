const CalculateAge = (birthDate: any) => {
  const birthDateObj = new Date(birthDate);
  const today = new Date();

  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = today.getMonth() - birthDateObj.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDateObj.getDate())
  ) {
    age--;
  }

  return age;
};

const isUnderAge = (birthDate: any) => {
  console.log("isUnderAge FULL DATE ", birthDate);

  const age = CalculateAge(birthDate);
  console.log("isUnderAge ", age < 18);
  return age < 18;
};
export default isUnderAge;
