import { Typography } from "constants/Typography/Typography";
import styled from "styled-components";
import closeIcon from "../../../assets/svgs/close_icon.svg";
import { SetStateAction, useState } from "react";
import { useApi } from "store/useApi";
import InputField from "common/InputField/InputField";
import PatternInputField from "common/InputField/PatternFormat";
import { Color } from "constants/Colors";
import PopupComponent from "common/PopupComponent";
import useTranslation from "common/TranslationHook/useTranslation";

const ContentContainer = styled.div`
  padding: 40px var(--container-padding);
  gap: 16px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  margin: auto;
  background-color: ${(props) => props.theme.colors.bgDark};
`;

const GridContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.theme.format > 1 ? "row" : "column")};
  gap: 24px;
  padding-top: 8px;
  width: 100%;
  max-width: var(--max-content-width);
  margin: auto;
`;

const Header = styled(Typography)`
  z-index: 5;
  text-align: ${(props) => (props.theme.format > 1 ? "center" : null)};
  color: ${(props) => props.theme.colors.textWhite};
`;

const SubHeader = styled(Typography)`
  color: ${(props) => props.theme.colors.textWhite};
  max-width: var(--max-content-width);
  margin: ${(props) => (props.theme.format > 1 ? "auto" : null)};
`;

const SubmitButton = styled.div`
  box-sizing: border-box;
  height: 46px;
  display: flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  border: 2px solid;
  border-color: ${(props) => props.theme.colors.buttonPrimary};
  background: ${(props) => props.theme.colors.buttonPrimary};
  color: ${(props) => props.theme.colors.buttonPrimaryText};
  font-weight: 700;
  font-size: 17px;
  font-family: "Montserrat-Medium";
  transition: all 0.5s;
  &:hover {
    cursor: pointer;
    border-color: ${(props) => props.theme.colors.buttonPrimaryHover};
    background: ${(props) => props.theme.colors.buttonPrimaryHover};
  }
`;

interface MoreInformationFormProps {
  queryString: string | null;
}

const MoreInformationForm = ({ queryString }: MoreInformationFormProps) => {
  const { t } = useTranslation("moreinformationform");

  const [FName, setFName] = useState("");
  const [FEmailAddress, setFEmailAddress] = useState("");
  const [FPhoneNumber, setFPhoneNumber] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [FNameError, setFNameError] = useState(false);
  const [FEmailAddressError, setFEmailAddressError] = useState(false);
  const [FPhoneNumberError, setFPhoneNumberError] = useState(false);
  const { ContactInfo } = useApi();
  const validateEmail = (email: string) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const valueChangeFPhone = (formattedValue: SetStateAction<string>) => {
    setFPhoneNumber(formattedValue);
  };

  const onChangeBottom = (event: any) => {
    const { name, value } = event.target;
    switch (name) {
      case "FName":
        setFName(value);
        break;
      case "FEmailAddress":
        setFEmailAddress(value);
        break;
      case "FPhoneNumber":
        setFPhoneNumber(value);
        break;
      default:
        break;
    }
  };

  const validateBottomForm = () => {
    let isValid = true;

    if (FName === "") {
      setFNameError(true);
      isValid = false;
    } else {
      setFNameError(false);
    }
    if (FEmailAddress.trim() === "") {
      setFEmailAddressError(true);
      isValid = false;
    } else if (!validateEmail(FEmailAddress.trim())) {
      setFEmailAddressError(true);
      isValid = false;
    } else {
      setFEmailAddressError(false);
    }
    if (FPhoneNumber === "") {
      setFPhoneNumberError(true);
      isValid = false;
    } else {
      setFPhoneNumberError(false);
    }

    return isValid;
  };

  const handleSubmit = () => {
    const isFormValid = validateBottomForm();
    if (isFormValid) {
      ContactInfo(FName, FPhoneNumber, FEmailAddress, queryString);
      setFEmailAddress("");
      setFName("");
      setFPhoneNumber("");
      setShowPopup(true);
    }
  };

  const handleBottomValueChange = (values: {
    value: string | any[];
    formattedValue: SetStateAction<string>;
  }) => {
    if (values.value.length <= 10) {
      valueChangeFPhone(values.formattedValue);
    }
  };

  return (
    <div>
      <ContentContainer>
        <Header headingLevel="h2" marginTop="0px" mb={"0px"}>
          {t("moreInformationForm.header")}
        </Header>
        <SubHeader marginTop="0px" mb="0px" headingLevel="large">
          {t("moreInformationForm.subHeader")}
        </SubHeader>
        <GridContainer>
          <InputField
            placeholder={t("moreInformationForm.placeholders.name")}
            name="FName"
            type="Name"
            value={FName}
            error={FNameError}
            onChange={onChangeBottom}
            color={Color.white}
          />
          <InputField
            placeholder={t("moreInformationForm.placeholders.email")}
            type="email"
            name="FEmailAddress"
            value={FEmailAddress}
            error={FEmailAddressError}
            onChange={onChangeBottom}
            color={Color.white}
          />
          <PatternInputField
            name="FPhoneNumber"
            value={FPhoneNumber}
            format="###-###-####"
            error={FPhoneNumberError}
            placeholder={t("moreInformationForm.placeholders.phoneNumber")}
            maskString="_"
            onValueChange={handleBottomValueChange}
            color={Color.white}
          />
          <SubmitButton onClick={handleSubmit}>
            {t("moreInformationForm.submitButton")}
          </SubmitButton>
        </GridContainer>
      </ContentContainer>
      <PopupComponent
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        content={t("moreInformationForm.popupContent")}
        closeIcon={closeIcon}
      />
    </div>
  );
};

export default MoreInformationForm;
