import { Typography } from "constants/Typography/Typography";
import { IContextState } from "store/Context";
import { PAGE_TITLES } from "../wizardscreen/PageTitles";
import { useTheme } from "styled-components";
import BulletPointsComponent from "common/BulletPointsComponent";
import useTranslation from "common/TranslationHook/useTranslation";
import ellipse from "../../assets/svgs/ellipse.svg";
import "../../common/style.css";
import "add-to-calendar-button";
import "moment-timezone";
import "../style.css";

interface ScheduleVVProps {
  state: IContextState;
  healthGoalsVerbiage: string;
  setStepCount: (step: number) => void;
}

const ScheduleVV = ({
  state,
  healthGoalsVerbiage,
  setStepCount,
}: ScheduleVVProps) => {
  const theme = useTheme();
  const emailAddress = state.EmailAddress;
  const { t } = useTranslation("Confirmation");

  const bulletPointsData = state.oauth
    ? [
        t("scheduleVV.callToSchedule"),
        <Typography mb="12px" style={{ marginTop: 0 }} key="skipWait">
          {t("scheduleVV.skipTheWait")}{" "}
          <a
            style={{
              cursor: "pointer",
              color: theme.colors.textLink,
              textDecoration: "underline",
            }}
            onClick={() => {
              setStepCount(PAGE_TITLES.SCHEDULE_CONSULTATION_SECOND.id);
            }}
          >
            {t("scheduleVV.scheduleConsultationLinkText")}
          </a>
          .
        </Typography>,
      ]
    : [
        t("scheduleVV.callToSchedule"),
        <Typography mb="12px" style={{ marginTop: 0 }} key="skipWait">
          {t("scheduleVV.skipTheWait")}{" "}
          <a
            style={{
              cursor: "pointer",
              color: theme.colors.textLink,
              textDecoration: "underline",
            }}
            onClick={() => {
              setStepCount(PAGE_TITLES.SCHEDULE_CONSULTATION_SECOND.id);
            }}
          >
            {t("scheduleVV.scheduleConsultationLinkText")}
          </a>
          .
        </Typography>,
        <Typography
          key="myOchsner-instruction"
          style={{ marginBottom: "12px", marginTop: 0 }}
          dangerouslySetInnerHTML={{
            __html: t("scheduleVV.myOchsnerUsernameInstruction").replace(
              "{emailAddress}",
              `<b>${emailAddress}</b>`,
            ),
          }}
        />,
      ];

  return (
    <div>
      <div
        style={{
          backgroundColor: theme.colors.tintSecondary10,
          border: `1px solid ${theme.colors.secondary}`,
          borderRadius: "8px",
          padding: "24px",
          marginBottom: "16px",
        }}
      >
        <Typography mb="0px" marginTop="0px">
          {t("scheduleVV.requiredVideoConsultation")} {healthGoalsVerbiage}.
        </Typography>
      </div>
      <BulletPointsComponent
        bulletPoints={bulletPointsData}
        ellipse={ellipse}
      />
    </div>
  );
};

export default ScheduleVV;
