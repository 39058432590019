import { Typography } from "constants/Typography/Typography";
import "./style.css";
import { FontSize, FontWeight } from "constants/Fonts";
import BackForwardButton from "common/BackForwardButton";

import closeIcon from "../assets/svgs/close_icon.svg";
import { useEffect, useState } from "react";
import PopupComponent from "common/PopupComponent";
import RadioButton from "common/RadioButton";
import { PopUpChangeAddress } from "./PopUpChangeAddress";
import { IContextActions, IContextState } from "store/Context";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "./wizardscreen/PageTitles";
import { StyledLink } from "./landingPage/newLandingPageComponents/JoinTheProgram";
import useTranslation from "common/TranslationHook/useTranslation";

interface ShippingAddressConfirmedProps {
  nextStep: () => void;
  backStep: () => void;
  HBPSelected: boolean;
  T2Selected: boolean;
  visitNeeded: string;
  setStepCount: (step: number) => void;
  state: IContextState;
  actions: IContextActions;
}

export const ShippingAddressConfirmed = ({
  backStep,
  T2Selected,
  HBPSelected,
  setStepCount,
  visitNeeded,
  state,
  actions,
}: ShippingAddressConfirmedProps) => {
  const { DeviceDetail, auditTrail } = useApi();
  const { t } = useTranslation("ShippingAddressConfirmed");

  useEffect(() => {
    auditTrail("ShippingAddressConfirmation");
  }, []);

  const handleContinue = () => {
    actions.setValue("cuffSize", cuffSize);

    const cuffSizeParam: any = cuffSize;
    const shippingAddressParam: any = {
      StreetAddressOne: state.streetAddressOne,
      StreetAddressTwo: state.streetAddressTwo,
      City: state.City,
      State: state.LivingState,
      ZipCode: state.ZipCode,
    };
    const businessAddressParam: any = state.businessAddress;

    DeviceDetail({
      cuffSize: cuffSizeParam,
      shippingAddress: shippingAddressParam,
      businessAddress: businessAddressParam,
    });
    console.log("visit needed?: " + visitNeeded);

    if (visitNeeded) {
      setStepCount(PAGE_TITLES.SCHEDULE_CONSULTATION_SECOND.id);
    } else {
      setStepCount(PAGE_TITLES.CONFIRMATION_PAGE.id);
    }
  };

  const HBPShipping = require("../assets/gifs/htn_shipping.gif");
  const T2Shipping = require("../assets/gifs/dmii_shipping.gif");
  const HBPT2Shipping = require("../assets/gifs/htn_dmii_shipping.gif");

  const [HBPCuffText, setHBPCuffText] = useState("");
  const [cuffSize, setCuffSize] = useState("");

  const popUpChangeCuff = (
    <div>
      <Typography headingLevel="h4" fontWeight={FontWeight.normal}>
        {t("popup.cuffSizeTitle")}
      </Typography>
      <div className="cuffsize-radio-group">
        <RadioButton
          value="Standard"
          id="standardInput"
          checked={cuffSize === "standard"}
          onChange={() => {
            setCuffSize("standard");
            setHBPCuffText(t("popup.cuffSizes.standard"));
          }}
          label={t("popup.cuffSizes.standard")}
        />
        <RadioButton
          value="Large"
          id="largeInput"
          checked={cuffSize === "large"}
          onChange={() => {
            setCuffSize("large");
            setHBPCuffText(t("popup.cuffSizes.large"));
          }}
          label={t("popup.cuffSizes.large")}
        />

        <RadioButton
          value="ExtraLarge"
          id="xlargeInput"
          checked={cuffSize === "ExtraLarge"}
          onChange={() => {
            setCuffSize("ExtraLarge");
            setHBPCuffText(t("popup.cuffSizes.extraLarge"));
          }}
          label={t("popup.cuffSizes.extraLarge")}
        />
      </div>
    </div>
  );

  const [changeCuffSizePopup, setChangeCuffSizePopup] = useState(false);
  const [changeAddressPopup, setChangeAddressPopup] = useState(false);

  const popUpChangeAddress = (
    <PopUpChangeAddress
      showPopup={changeAddressPopup}
      setShowPopup={setChangeAddressPopup}
      actions={actions}
    />
  );

  const userAddress = (
    <div>
      {state.FirstName} {state.LastName}
      <br />
      {state.streetAddressOne}
      {state.streetAddressTwo !== "" &&
        state.streetAddressTwo !== null &&
        state.streetAddressTwo !== "null" &&
        state.streetAddressTwo &&
        " " + state.streetAddressTwo}
      , {state.City}, {state.LivingState} {state.ZipCode}
    </div>
  );

  useEffect(() => {
    if (HBPSelected) {
      const hbpcuffsize = state.cuffSize?.toLowerCase();
      if (hbpcuffsize === "standard") {
        setHBPCuffText(t("popup.cuffSizes.standard"));
        setCuffSize("standard");
      } else if (hbpcuffsize === "large") {
        setHBPCuffText(t("popup.cuffSizes.large"));
        setCuffSize("large");
      } else {
        setHBPCuffText(t("popup.cuffSizes.extraLarge"));
        setCuffSize("ExtraLarge");
      }
    }
  }, []);

  const handleBloodPressureClick = () => {
    setChangeCuffSizePopup(true);
  };

  const handleAddressClick = () => {
    setChangeAddressPopup(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "stretch",
      }}
    >
      {HBPSelected && T2Selected ? (
        <img
          src={HBPT2Shipping}
          alt={t("imageAltText.HBP_T2_Selected")}
          style={{ width: "140px", height: "151px", alignSelf: "center" }}
        />
      ) : T2Selected ? (
        <img
          src={T2Shipping}
          alt={t("imageAltText.T2_Selected")}
          style={{ width: "140px", height: "151px", alignSelf: "center" }}
        />
      ) : (
        <img
          src={HBPShipping}
          alt={t("imageAltText.HBP_Selected")}
          style={{ width: "140px", height: "151px", alignSelf: "center" }}
        />
      )}
      <div>
        <Typography
          fontWeight={FontWeight.bold}
          headingLevel="h4"
          style={{ marginTop: "40px" }}
        >
          {t("ShippingAddressConfirmed.reviewItems")}
        </Typography>
        <div>
          {HBPSelected && (
            <div
              style={{
                display: "flex",
                gap: "32px",
                alignItems: "flex-start",
                justifyContent: "space-between",
                borderBottom: "1px solid #A9B9D2",
                paddingBottom: "16px",
              }}
            >
              <div>
                <Typography fontWeight={FontWeight.bold}>
                  {t("bloodPressureSection.title")}
                </Typography>
                <Typography>{HBPCuffText}</Typography>
              </div>
              <Typography
                onClick={handleBloodPressureClick}
                style={{ textDecorationLine: "underline", cursor: "pointer" }}
              >
                <StyledLink>{t("bloodPressureSection.changeLink")}</StyledLink>
              </Typography>
            </div>
          )}
          {T2Selected && (
            <div
              style={{
                display: "flex",
                gap: "32px",
                alignItems: "flex-start",
                justifyContent: "space-between",
                borderBottom: "1px solid #A9B9D2",
                paddingBottom: "16px",
              }}
            >
              <div>
                <Typography fontWeight={FontWeight.bold}>
                  {" "}
                  {t("glucometerSection.title")}
                </Typography>
                <Typography>{t("glucometerSection.description")}</Typography>
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              gap: "32px",
              alignItems: "flex-start",
              justifyContent: "space-between",
              paddingBottom: "16px",
            }}
          >
            <div>
              <Typography fontWeight={FontWeight.bold}>
                {t("shippingAddressSection.title")}
              </Typography>
              <Typography>{userAddress}</Typography>
            </div>
            <Typography
              onClick={handleAddressClick}
              style={{ textDecorationLine: "underline", cursor: "pointer" }}
            >
              <StyledLink>{t("shippingAddressSection.changeLink")}</StyledLink>
            </Typography>
          </div>
        </div>
      </div>
      {changeAddressPopup && (
        <PopupComponent
          showPopup={changeAddressPopup}
          setShowPopup={setChangeAddressPopup}
          content={popUpChangeAddress}
          closeIcon={closeIcon}
          shippingPopup={true}
        />
      )}

      {changeCuffSizePopup && (
        <PopupComponent
          showPopup={changeCuffSizePopup}
          setShowPopup={setChangeCuffSizePopup}
          content={popUpChangeCuff}
          closeIcon={closeIcon}
        />
      )}
      <Typography>{t("ShippingAddressConfirmed.instructions")}</Typography>
      <BackForwardButton
        backAction={backStep}
        forwardAction={handleContinue}
        backVisible={true}
        frontVisible={true}
        disabled={false}
      />
    </div>
  );
};
