import { Typography } from "constants/Typography/Typography";
import { FontWeight } from "constants/Fonts";
import { IContextActions, IContextState } from "store/Context";
// import costConsentTable from "../assets/images/costConsentTable.png";
import useTranslation from "common/TranslationHook/useTranslation";
import "./style.css";

interface CostConsentHTML3Props {
  state: IContextState;
  actions: IContextActions;
  forceStaticHTMLSupport?: boolean;
}

export const CostConsentHTML3 = ({
  forceStaticHTMLSupport = false,
}: CostConsentHTML3Props) => {
  const { t } = useTranslation("CostConsentHTML3");

  const TypographyOrP = ({ children, ...rest }: any) =>
    forceStaticHTMLSupport ? (
      <p>{children}</p>
    ) : (
      <Typography {...rest}>{children}</Typography>
    );

  return (
    <TypographyOrP>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          alignSelf: "stretch",
        }}
      >
        <div>
          <TypographyOrP fontWeight={FontWeight.bold}>
            {t("hypertensionTitle")}
          </TypographyOrP>
          <Typography
            dangerouslySetInnerHTML={{
              __html: t("hypertensionDescription"),
            }}
          />

          <div>
            <TypographyOrP fontWeight={FontWeight.bold}>
              {t("type2DiabetesTitle")}
            </TypographyOrP>
            <Typography
              dangerouslySetInnerHTML={{
                __html: t("type2DiabetesDescription"),
              }}
            />
          </div>
        </div>
      </div>
    </TypographyOrP>
  );
};
