import { Typography } from "constants/Typography/Typography";
import "./style.css";
import { FontWeight } from "constants/Fonts";
import BackForwardButton from "common/BackForwardButton";
import { Color } from "constants/Colors";
import { useEffect, useState } from "react";
import { IContextActions, IContextState, useMyContext } from "store/Context";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "./wizardscreen/PageTitles";
import InputField from "common/InputField/InputField";
import PatternInputField from "common/InputField/PatternFormat";
import { ViewPortFormat } from "constants/Themes";
import useTranslation from "common/TranslationHook/useTranslation";

interface HeightWeightProps {
  nextStep: () => void;
  backStep: () => void;
  HBPSelected: boolean;
  T2Selected: boolean;
  visitNeeded: string;
  setStepCount: (step: number) => void;
  setPrevStep: React.Dispatch<React.SetStateAction<number>>;
  setTitle: (title: string) => void;
  state: IContextState;
  actions: IContextActions;
}

export const HeightWeight = ({
  backStep,
  T2Selected,
  HBPSelected,
  setStepCount,
  visitNeeded,
  setPrevStep,
  setTitle,
  state,
  actions,
}: HeightWeightProps) => {
  const [heightFt, setHeightFt] = useState<number | any>();
  const [heightIn, setHeightIn] = useState<number | any>();
  const [weight, setWeight] = useState<number | any>();
  const [heightInError, setHeightInError] = useState(false);
  const [heightFtError, setHeightFtError] = useState(false);
  const [weightError, setWeightError] = useState(false);
  const [format, setFormat] = useState(0);
  const [filledIn, setFilledIn] = useState(false);
  const { t } = useTranslation("Misc");
  const { QuestionAnswer } = useApi();

  const onChange = (event: any) => {
    const { name, value } = event.target;
    actions.setValue(name, value);
    switch (name) {
      case "HeightFt":
        setHeightFt(value);
        if (value.trim() !== "") setHeightFtError(false);
        break;
      case "Weight":
        setWeight(value);
        if (value.trim() !== "") setWeightError(false);
        break;
      case "HeightIn":
        setHeightIn(value);
        if (value.trim() !== "") setHeightInError(false);
        break;
      default:
        break;
    }
  };

  const styles: { [key: string]: React.CSSProperties } = {
    mobileStyles: {
      display: "flex",
      alignItems: "flex-start",
      gap: "8px",
      alignSelf: "stretch",
      flexDirection: "column",
      width: "80%",
    },
    desktopStyles: {
      display: "flex",
      alignItems: "flex-start",
      gap: "8px",
      alignSelf: "stretch",
      flexDirection: "column",
      width: "50%",
    },
  };

  useEffect(() => {
    if (heightIn && heightFt && weight) {
      setFilledIn(true);
    } else setFilledIn(false);
  }, [heightIn, heightFt, weight]);

  useEffect(() => {
    const getFormat = (): ViewPortFormat => {
      const width = window.innerWidth;
      if (width < 768) {
        return ViewPortFormat.phone;
      }
      if (width < 1024) {
        return ViewPortFormat.tablet;
      }
      return ViewPortFormat.desktop;
    };
    const newFormat = getFormat();
    setFormat(newFormat);
    console.log("Format? " + format);
  }, [window.innerWidth]);

  const handleContinue = () => {
    const heightTotal: number = heightFt * 12 + parseInt(heightIn);
    console.log("HEIGHT in" + heightIn);
    console.log("weight " + weight);
    console.log("height total " + heightTotal);
    let BMI = 703 * (parseInt(weight) / (heightTotal * heightTotal));
    actions.setValue("height", heightTotal);
    actions.setValue("weight", weight);
    actions.setValue("BMI", BMI);
    console.log("BMI = " + BMI);
    QuestionAnswer("Height", heightTotal.toString());
    QuestionAnswer("Weight", weight.toString());
    QuestionAnswer("BMI", BMI.toString());
    setPrevStep(PAGE_TITLES.HEIGHT_WEIGHT.id);
    setStepCount(PAGE_TITLES.WM_MEDICATION.id);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography>{t("heightWeight.introText")}</Typography>

        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            alignSelf: "stretch",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {/* <div style={{display: "flex", alignItems: "flex-start", gap: "8px", alignSelf: "stretch", flexDirection: "column", width: "50%"}}> */}
          <div style={format == 0 ? styles.mobileStyles : styles.desktopStyles}>
            <Typography>{t("heightWeight.heightLabel")}</Typography>

            <div
              style={{
                display: "flex",
                gap: "16px",
                flex: "1 1 0",
                width: "50%",
              }}
            >
              <PatternInputField
                label=""
                name="HeightFt"
                value={heightFt}
                format="#"
                error={heightFtError}
                placeholder={t("heightWeight.heightPlaceholderFeet")}
                onChange={onChange}
                flexSize="1"
                permanentPlaceholder={true}
              />

              <PatternInputField
                label=""
                name="HeightIn"
                value={heightIn}
                format="##"
                error={heightInError}
                placeholder={t("heightWeight.heightPlaceholderInches")}
                onChange={onChange}
                flexSize="1"
                permanentPlaceholder={true}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: "8px",
              alignSelf: "stretch",
              flexDirection: "column",
            }}
          >
            <Typography>{t("heightWeight.weightLabel")}</Typography>

            <div style={{ display: "flex", gap: "16px", flex: "1 0 0" }}>
              <PatternInputField
                label=""
                name="Weight"
                value={weight}
                format="###"
                error={weightError}
                placeholder={t("heightWeight.weightPlaceholderPounds")}
                onChange={onChange}
                flexSize="1"
                permanentPlaceholder={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "60px" }}>
        <BackForwardButton
          forwardAction={handleContinue}
          backVisible={false}
          frontVisible={true}
          disabled={!filledIn}
        />
      </div>
    </>
  );
};
