import { Typography } from "constants/Typography/Typography";
import { useState, useEffect } from "react";
import { IContextActions, IContextState } from "store/Context";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "./wizardscreen/PageTitles";
import { styled, useTheme } from "styled-components";
import { StyledLink } from "./landingPage/newLandingPageComponents/JoinTheProgram";
import { ReactComponent as LoadingSpinner } from "../assets/svgs/loadingSpinner.svg";
import BackForwardButton from "common/BackForwardButton";
import InputField from "common/InputField/InputField";
import useTranslation from "common/TranslationHook/useTranslation";
import "./style.css";

interface CoverageProps {
  nextStep: () => void;
  backStep: () => void;
  setStepCount: (step: number) => void;
  actions: IContextActions;
  state: IContextState;
}
interface DropDownItem {
  Name: string;
  Synonyms: string[];
}

const StyledDropdownItem = styled.div`
  z-index: 99;
  padding: 8px 16px;
  gap: 10px;
  cursor: pointer;
  font-family: "Montserrat-Medium";

  &:hover {
    background-color: ${(props) => props.theme.colors.tintSecondary10};
  }
`;

export const Coverage = ({
  backStep,
  setStepCount,
  actions,
  state,
}: CoverageProps) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItemName, setSelectedItemName] = useState("");
  const [dropDownItems, setDropDownItems] = useState<DropDownItem[]>([]);
  const [filteredItems, setFilteredItems] = useState<DropDownItem[]>([]);
  const [ready, setReady] = useState(true);
  const { PatientLookup, auditTrail, clientData, clients, PatientPrograms } =
    useApi();
  const loader = require("../assets/gifs/loader.gif");
  const theme = useTheme();
  const { t } = useTranslation("Coverage");

  useEffect(() => {
    auditTrail("Coverage");

    clients().then((data) => {
      setDropDownItems(data);
    });
  }, []);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredItems(dropDownItems);
    } else {
      const filtered = dropDownItems.filter(
        (item) =>
          item.Name.toUpperCase().startsWith(searchTerm.trim().toUpperCase()) ||
          item.Synonyms.some((synonym) =>
            synonym.toUpperCase().startsWith(searchTerm.trim().toUpperCase()),
          ),
      );
      setFilteredItems(filtered);
    }
  }, [searchTerm, dropDownItems]);

  const handleContinue = () => {
    auditTrail(selectedItemName);
    setReady(false);

    clientData(selectedItemName)
      .then((data) => {
        if (data.IdType !== "") {
          actions.setValue("idTypePrompt", data.IdPrompt);
          actions.setValue("idType", data.IdType);
          setSelectedItemName("");
          auditTrail("Id Type" + data.IdType);
          auditTrail("Id Prompt" + data.IdPrompt);
          setReady(true);
          setStepCount(PAGE_TITLES.COVERAGE_UNIQUE_ID.id);
        } else {
          PatientLookup().then((data: any) => {
            if (data.PatientId !== "") {
              // actions.setValue("htn", data.HTN);
              // actions.setValue("diab", data.DIAB);
              actions.setValue("patientId", data.PatientId);
              PatientPrograms(data.PatientId, state.oauth).then((data: any) => {
                if (
                  data.CapMet === false &&
                  !data.Enrolled.length &&
                  !data.Eligible.length &&
                  data.Sponsor === ""
                ) {
                  auditTrail("Not Eligible");
                  auditTrail("Came from MyOchsner");
                  actions.setValue("comingFromSignup", true);
                  setStepCount(PAGE_TITLES.ERROR_FINANCIAL_ELIGIBLE.id);
                } else if (data.CapMet === "true") {
                  actions.setValue("comingFromSignup", true);
                  actions.setValue("oauth", true);
                  actions.setValue("hasMyChartAccount", true);
                  auditTrail("Cap Met");
                  setStepCount(PAGE_TITLES.ERROR_PATIENT_CAP.id);
                } else if (!data.Enrolled.length && !data.Eligible.length) {
                  auditTrail("Not Eligible");
                  setStepCount(PAGE_TITLES.ERROR_FINANCIAL_ELIGIBLE.id);
                } else {
                  actions.setValue("Capmet", data.CapMet);
                  actions.setValue("sponsor", data.Sponsor);
                  actions.setValue("threefourtyB", data.Eligible340B);
                  if (data.Enrolled.includes("Hypertension")) {
                    actions.setValue("htnEnrolled", true);
                  }
                  if (data.Enrolled.includes("Type 2 Diabetes")) {
                    actions.setValue("diabEnrolled", true);
                  }
                  if (data.Enrolled.includes("Weight Management")) {
                    actions.setValue("wmEnrolled", true);
                  }
                  if (data.Eligible.includes("Hypertension")) {
                    actions.setValue("htnEligible", true);
                  }
                  if (data.Eligible.includes("Type 2 Diabetes")) {
                    actions.setValue("diabEligible", true);
                  }
                  if (data.Eligible.includes("Weight Management")) {
                    actions.setValue("wmEligible", true);
                  }
                  if (data.WMType === "Lifestyle Only") {
                    actions.setValue("wmEligible", true);
                    actions.setValue("wmLifestyleProgram", true);
                  }
                  if (data.WMType === "Med Management") {
                    actions.setValue("wmEligible", true);
                    actions.setValue("wmMedsProgram", true);
                  }
                  setStepCount(PAGE_TITLES.SUCCESS_PAGE.id);
                }
              });
            } else {
              auditTrail("Patient Not Found");
              setReady(true);
              setStepCount(PAGE_TITLES.ERROR_GENERAL.id);
            }
          });
        }
        setSearchTerm("");
        setSelectedItemName("");
        setFilteredItems([]);
      })
      .catch((err) => {
        console.error("Error retrieving client data:", err);
        setReady(true);
        setStepCount(PAGE_TITLES.ERROR_GENERAL.id);
      });
  };

  const findItemBySynonym = (synonym: any) => {
    const item = dropDownItems.find((i) =>
      i.Synonyms.includes(synonym.toUpperCase()),
    );
    return item ? item.Name : "Not found";
  };

  const onSearchTermChange = (event: any) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value.trim() === "") {
      setSelectedItemName("");
    } else {
      const itemName = findItemBySynonym(value.trim());
      setSelectedItemName(itemName);
    }
  };
  return (
    <div>
      <Typography>{t("coverageHeader")}</Typography>
      <InputField
        label={t("employerInsurerLabel")}
        name="EmployerName"
        value={searchTerm}
        onChange={onSearchTermChange}
        onFocus={() => {
          setIsDropdownVisible(true);
        }}
        onBlur={() =>
          setTimeout(() => {
            setIsDropdownVisible(false);
          }, 150)
        }
      />
      {isDropdownVisible && (
        <div className="dropdown">
          {filteredItems.map((item) => (
            <StyledDropdownItem
              key={item.Name}
              onClick={() => {
                setSearchTerm(item.Name);
                setSelectedItemName(item.Name);
                setIsDropdownVisible(false);
              }}
            >
              {item.Name}
            </StyledDropdownItem>
          ))}
        </div>
      )}
      <Typography>
        {t("coverageHelpText", {
          supportPhoneLink: (
            <StyledLink
              href="tel:866-273-0548"
              style={{ fontFamily: "Montserrat-Bold" }}
            >
              {t("supportPhoneLink")}
            </StyledLink>
          ),
        })}
      </Typography>
      {ready ? (
        <BackForwardButton
          backAction={backStep}
          forwardAction={handleContinue}
          backVisible={true}
          frontVisible={true}
          disabled={selectedItemName === ""}
        />
      ) : (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
            padding: "16px 0px",
          }}
        >
          <LoadingSpinner
            fill={theme.colors.tertiary}
            style={{ height: 36 }}
            className="spinner"
          />
        </div>
      )}
    </div>
  );
};
