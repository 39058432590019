import React from "react";
import closeIcon from "../../assets/svgs/close_icon.svg";
import "./PopupComponent.css";
import BulletPointsComponent from "common/BulletPointsComponent";
import { Typography } from "constants/Typography/Typography";
import { FontFamily, FontSize, FontWeight } from "constants/Fonts";
import licenseFront from "../../assets/images/licenseExampleFront.png";
import licenseBack from "../../assets/images/licenseExampleBack.png";
import { CloseBtn } from "common/PopupComponent";
import { useTheme } from "styled-components";
import useTranslation from "common/TranslationHook/useTranslation";

interface PopupComponentProps {
  showPopup: boolean;
  setShowPopup: (show: boolean) => void;
}

const PopupComponent = ({ showPopup, setShowPopup }: PopupComponentProps) => {
  const { t } = useTranslation("photoidverification");
  const bulletPoints = t("popupComponent.bulletPoints");

  if (!showPopup) return null;
  const theme = useTheme();

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <CloseBtn
          onClick={() => {
            setShowPopup(false);
          }}
        />
        <div className="popup-body">
          <div className="popup-header">
            <Typography
              fontFamily={FontFamily.secondary}
              fontSize={FontSize.h4}
              fontWeight={FontWeight.bold}
            >
              {t("popupComponent.title")}
            </Typography>
          </div>
          <BulletPointsComponent bulletPoints={bulletPoints} />
        </div>
        <div
          className="popup-examples"
          style={{ backgroundColor: theme.colors.bgLight }}
        >
          <img
            src={licenseFront}
            alt={t("popupComponent.images.frontExampleAlt")}
          />
          <img
            src={licenseBack}
            alt={t("popupComponent.images.backExampleAlt")}
          />
        </div>
      </div>
    </div>
  );
};

export default PopupComponent;
