import { Typography } from "constants/Typography/Typography";
import BackForwardButton from "common/BackForwardButton";
import { useEffect } from "react";
import { useApi } from "store/useApi";
import { useTheme } from "styled-components";
import useTranslation from "common/TranslationHook/useTranslation";

interface UnderAgeErrorProps {
  closeButtonAction: () => void;
}

export const UnderAgeError = ({ closeButtonAction }: UnderAgeErrorProps) => {
  const { auditTrail } = useApi();
  const { t } = useTranslation("UnderAgeError");
  const theme = useTheme();
  useEffect(() => {
    auditTrail("Patient underage error");
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 140,
      }}
    >
      <div>
        <Typography
          dangerouslySetInnerHTML={{
            __html: t("underAgeErrorHeader"),
          }}
        />
      </div>

      <BackForwardButton
        closeButtonAction={closeButtonAction}
        backVisible={false}
        frontVisible={false}
        closeButtonVisible={true}
      />
    </div>
  );
};
