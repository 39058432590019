// Button.tsx
import { Typography } from "constants/Typography/Typography";
import React from "react";
import { styled } from "styled-components";

interface NumericCircleProps {
  number: number;
  questionText: string;
}

export const StyledBulletCircle = styled.div`
  height: 36px;
  width: 36px;
  font-weight: 600;
  border-color: ${(props) => props.theme.colors.secondary};
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  position: relative;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledBulletNumber = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.colors.textPrimary};
  text-align: center;
  position: relative;
  font-family: "Montserrat-SemiBold";
`;

const NumericCircle = ({ number, questionText }: NumericCircleProps) => {
  return (
    <div className="question-container">
      <StyledBulletCircle>
        <StyledBulletNumber>{number}</StyledBulletNumber>
      </StyledBulletCircle>
      <Typography className="question-text" mb="15px">
        {questionText}
      </Typography>
    </div>
  );
};

export default NumericCircle;
