import { Typography } from "constants/Typography/Typography";
import "./style.css";
import BackForwardButton from "common/BackForwardButton";
import { useEffect, useState } from "react";
import BoxedRadioButton from "common/BoxedRadiotButton";
import NumericCircle from "common/NumericCircle";
import CircularProgress from "@mui/material/CircularProgress";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "./wizardscreen/PageTitles";
import { IContextActions } from "store/Context";
import useTranslation from "common/TranslationHook/useTranslation";

interface QuestionnaireVerificationProps {
  nextStep: () => void;
  backStep: () => void;
  setStepCount: (step: number) => void;
  setQuestionIdSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  actions: IContextActions;
}
interface QuestionOption {
  id: number;
  option: string;
}

interface Question {
  QuestionId: number;
  Question: string;
  QuestionType: string;
  Options: QuestionOption[];
}

interface SelectedAnswers {
  [questionId: number]: number;
}

export const QuestionnaireVerification = ({
  backStep: originalBackStep,
  setStepCount,
  setQuestionIdSuccess,
  actions,
}: QuestionnaireVerificationProps) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [selectedAnswers, setSelectedAnswers] = useState<SelectedAnswers>({});
  const [iDMSessionId, setIDMSessionId] = useState("");
  const [userAccessCode, setUserAccessCode] = useState("");
  const [ready, setReady] = useState(false);
  const [quizFailed, setQuizFailed] = useState(false);
  const [quizAttempt, setQuizAttempt] = useState(0);
  const {
    CreateMyChartAccount,
    GetIdentityQuiz,
    VerifyIdentityQuiz,
    auditTrail,
  } = useApi();

  const { t } = useTranslation("QuestionnaireVerification");

  useEffect(() => {
    auditTrail("Questionnaire");
  }, []);

  const fetchQuiz = () => {
    GetIdentityQuiz().then((response) => {
      if (response.success && response.data?.IDMKBAResponse?.KBAQuestion) {
        setQuestions(response.data.IDMKBAResponse.KBAQuestion as Question[]);
        setIDMSessionId(response.data.IDMSessionId);
        if (response.userAccessCode) {
          setUserAccessCode(response.userAccessCode);
        }
        setReady(true);
      }
      //  else if (!response.success) {
      //   console.log("response.success", response.success);
      //   setStepCount(PAGE_TITLES.ERROR_VERIFICATION_FAILED.id);
      // }
    });
  };
  useEffect(() => {
    fetchQuiz();
  }, []);

  const handleOptionChange = (questionId: number, optionId: number) => {
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: optionId,
    }));
  };

  const handleContinue = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      const answersArray = Object.keys(selectedAnswers)
        .map((key) => {
          const questionId = parseInt(key, 10);
          const answerId = selectedAnswers[questionId];
          if (!isNaN(questionId) && typeof answerId === "number") {
            return { questionId, answerId };
          }
          return null;
        })
        .filter((item) => item !== null);
      VerifyIdentityQuiz(answersArray, iDMSessionId, userAccessCode).then(
        (data) => {
          if (data) {
            if (!data.Success) {
              auditTrail(`Questionnaire Failed ${quizAttempt} times`);
              setQuizFailed(true);
              if (quizAttempt > 3) {
                actions.setValue("quizFailed", true);
                setStepCount(PAGE_TITLES.ERROR_ID_VERIFY.id);
              }
            } else {
              CreateMyChartAccount().then((response) => {
                if (response === 200 || response < 300 || response === 204) {
                  setQuestionIdSuccess(true);
                  setStepCount(PAGE_TITLES.SUCCESS_PAGE.id);
                }
              });
            }
          }
        },
      );
    }
  };

  const backStep = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    } else {
      originalBackStep();
    }
  };

  const currentQuestion = questions[currentQuestionIndex];
  if (ready && questions.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  if (quizFailed && quizAttempt < 4) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 140,
        }}
      >
        <Typography mb="24px">{t("quizFailedMessage")}</Typography>
        <BackForwardButton
          forwardAction={() => {
            setQuizFailed(false);
            setCurrentQuestionIndex(0);
            setSelectedAnswers({});
            setQuizAttempt((prevAttempt) => prevAttempt + 1);
            fetchQuiz();
          }}
          backVisible={false}
          frontVisible={true}
          forwardText={t("tryAgainButtonText")}
        />
      </div>
    );
  }

  return (
    <div>
      <Typography mb="24px">{t("questionnaireVerificationHeader")}</Typography>
      {currentQuestion && (
        <>
          <NumericCircle
            number={currentQuestionIndex + 1}
            questionText={currentQuestion.Question}
          />
          {currentQuestion.Options.map((option) => (
            <BoxedRadioButton
              key={option.id}
              value={option.id.toString()}
              checked={
                selectedAnswers[currentQuestion.QuestionId] === option.id
              }
              onChange={() =>
                handleOptionChange(currentQuestion.QuestionId, option.id)
              }
              label={option.option}
            />
          ))}
        </>
      )}
      <BackForwardButton
        backAction={backStep}
        forwardAction={handleContinue}
        backVisible={currentQuestionIndex > 0}
        frontVisible={true}
        disabled={selectedAnswers[currentQuestion?.QuestionId] == null}
        forwardText={
          currentQuestionIndex < questions.length - 1
            ? t("nextQuestionButtonText")
            : t("continueButtonText")
        }
      />
    </div>
  );
};
