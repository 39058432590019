import React, { useEffect, useState } from "react";
import useTranslation from "./useTranslation";
import { ReactComponent as LanguageIcon } from "assets/svgs/language_switch.svg";
import styled from "styled-components";
import { Typography } from "constants/Typography/Typography";
import { FontWeight } from "constants/Fonts";
import { useApi } from "store/useApi";
import { useMyContext } from "store/Context";

type Languages = "en" | "es";

const LanguageSwitcher: React.FC = () => {
  const { t, setLang } = useTranslation("header");
  const [language, setLanguage] = useState<Languages>(
    (localStorage.getItem("language") as Languages) || "en",
  );
  const [confirmTimer, setConfirmTimer] = useState<NodeJS.Timeout | null>(null);
  const { QuestionAnswer } = useApi();
  const { state } = useMyContext();

  const toggleLanguage = () => {
    const newLang: Languages = language === "en" ? "es" : "en";
    setLang(newLang);
    setLanguage(newLang);
    localStorage.setItem("language", newLang);
    if (newLang === "es") {
      if (confirmTimer) clearTimeout(confirmTimer);
      const timer = setTimeout(() => {
        if (state.patientId) {
          QuestionAnswer("Language", "Spanish");
        }
      }, 3000);
      setConfirmTimer(timer);
    } else {
      if (confirmTimer) clearTimeout(confirmTimer);
      setConfirmTimer(null);
    }
  };

  useEffect(() => {
    if (language === "es" && state.patientId && !confirmTimer) {
      const timer = setTimeout(() => {
        QuestionAnswer("Language", "Spanish");
      }, 10000);
      setConfirmTimer(timer);
    }

    return () => {
      if (confirmTimer) clearTimeout(confirmTimer);
    };
  }, [state.patientId, language, confirmTimer]);

  const StyledDiv = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
  `;

  const StyledIcon = styled(LanguageIcon)`
    margin-right: 10px;
  `;
  return (
    <StyledDiv onClick={toggleLanguage}>
      <StyledIcon />
      <Typography
        textDecoration="underline"
        fontWeight={FontWeight.bold}
        marginTop="0px"
        mb="0px"
      >
        {language === "en" ? t("spanish") : t("english")}
      </Typography>
    </StyledDiv>
  );
};

export default LanguageSwitcher;
