// constants/Translations/translationFiles.ts
import { Namespaces } from "./translationTypes";

type Languages = "en" | "es";

type TranslationFiles = Record<Languages, Record<Namespaces, any>>;

export const translationFiles: TranslationFiles = {
  en: {
    header: require("../../constants/Translations/en/header.json"),
    signupherotext: require("../../constants/Translations/en/signupherotext.json"),
    programs: require("../../constants/Translations/en/programs.json"),
    faq: require("../../constants/Translations/en/faq.json"),
    moreinformationform: require("../../constants/Translations/en/moreinformationform.json"),
    customerreviewandmetrics: require("../../constants/Translations/en/customerreviewandmetrics.json"),
    jointheprogram: require("../../constants/Translations/en/jointheprogram.json"),
    videowithcheckitems: require("../../constants/Translations/en/videowithcheckitems.json"),
    welcomescreentranslate: require("../../constants/Translations/en/welcomescreentranslate.json"),
    basicForm: require("../../constants/Translations/en/basicForm.json"),
    Coverage: require("../../constants/Translations/en/Coverage.json"),
    UniqueIdCoverage: require("../../constants/Translations/en/UniqueIdCoverage.json"),
    SuccessPage: require("../../constants/Translations/en/SuccessPage.json"),
    ConfirmIdentity: require("../../constants/Translations/en/ConfirmIdentity.json"),
    photoidverification: require("../../constants/Translations/en/photoidverification.json"),
    ProgramSelection: require("../../constants/Translations/en/ProgramSelection.json"),
    ThankYouSelection: require("../../constants/Translations/en/ThankYouSelection.json"),
    HBPCuffSize: require("../../constants/Translations/en/HBPCuffSize.json"),
    SelectShippingAddress: require("../../constants/Translations/en/SelectShippingAddress.json"),
    ShippingAddressConfirmed: require("../../constants/Translations/en/ShippingAddressConfirmed.json"),
    NoticeOfPrivacyConsent: require("../../constants/Translations/en/NoticeOfPrivacyConsent.json"),
    WMDMIIError: require("../../constants/Translations/en/WMDMIIError.json"),
    WMError: require("../../constants/Translations/en/WMError.json"),
    WMMedication: require("../../constants/Translations/en/WMMedication.json"),
    CostConsentHTML1: require("../../constants/Translations/en/CostConsentHTML1.json"),
    CostConsentHTML1A: require("../../constants/Translations/en/CostConsentHTML1A.json"),
    CostConsentHTML2: require("../../constants/Translations/en/CostConsentHTML2.json"),
    CostConsentHTML3: require("../../constants/Translations/en/CostConsentHTML3.json"),
    CostConsentHTML4: require("../../constants/Translations/en/CostConsentHTML4.json"),
    CostConsentHTML5: require("../../constants/Translations/en/CostConsentHTML5.json"),
    ConsolidatedConsent: require("../../constants/Translations/en/ConsolidatedConsent.json"),
    ExclusionaryCriteria: require("../../constants/Translations/en/ExclusionaryCriteria.json"),
    ExclusionaryCriteriaError: require("../../constants/Translations/en/ExclusionaryCriteriaError.json"),
    financialconsenttext: require("../../constants/Translations/en/financialconsenttext.json"),
    GeneralConsentText: require("../../constants/Translations/en/GeneralConsentText.json"),
    GlucometerSelection: require("../../constants/Translations/en/GlucometerSelection.json"),
    HBPConsentText: require("../../constants/Translations/en/HBPConsentText.json"),
    programconsenttext: require("../../constants/Translations/en/programconsenttext.json"),
    QuestionnaireVerification: require("../../constants/Translations/en/QuestionnaireVerification.json"),
    ScheduleVideoConsultation_2: require("../../constants/Translations/en/ScheduleVideoConsultation_2.json"),
    telemedicineconsenttext: require("../../constants/Translations/en/telemedicineconsenttext.json"),
    financialError: require("../../constants/Translations/en/financialError.json"),
    programConsentDetails: require("../../constants/Translations/en/programConsentDetails.json"),
    noticeofprivacyconsenttext: require("../../constants/Translations/en/noticeofprivacyconsenttext.json"),
    Confirmation: require("../../constants/Translations/en/ConfirmationPage.json"),
    ScheduleVisitError: require("../../constants/Translations/en/ScheduleVisitError.json"),
    Misc: require("../../constants/Translations/en/Misc.json"),
    PatientCapError: require("../../constants/Translations/en/patientCapError.json"),
    GeneralError: require("../../constants/Translations/en/generalError.json"),
    UserInfoError: require("../../constants/Translations/en/UserInfoError.json"),
    ProgramValidation: require("../../constants/Translations/en/ProgramValidation.json"),
    IDVerificationError: require("../../constants/Translations/en/IDVerificationError.json"),
    UnderAgeError: require("../../constants/Translations/en/UnderAgeError.json"),
    PageTitles: require("../../constants/Translations/en/pageTitles.json"),
    BackForwardButton: require("../../constants/Translations/en/BackForwardButton.json"),
    CombinedConsentText: require("../../constants/Translations/en/combinedconsenttext.json"),
  },
  es: {
    header: require("../../constants/Translations/es/header.json"),
    signupherotext: require("../../constants/Translations/es/signupherotext.json"),
    programs: require("../../constants/Translations/es/programs.json"),
    faq: require("../../constants/Translations/es/faq.json"),
    moreinformationform: require("../../constants/Translations/es/moreinformationform.json"),
    customerreviewandmetrics: require("../../constants/Translations/es/customerreviewandmetrics.json"),
    jointheprogram: require("../../constants/Translations/es/jointheprogram.json"),
    videowithcheckitems: require("../../constants/Translations/es/videowithcheckitems.json"),
    welcomescreentranslate: require("../../constants/Translations/es/welcomescreentranslate.json"),
    basicForm: require("../../constants/Translations/es/basicForm.json"),
    Coverage: require("../../constants/Translations/es/Coverage.json"),
    UniqueIdCoverage: require("../../constants/Translations/es/UniqueIdCoverage.json"),
    SuccessPage: require("../../constants/Translations/es/SuccessPage.json"),
    ConfirmIdentity: require("../../constants/Translations/es/ConfirmIdentity.json"),
    photoidverification: require("../../constants/Translations/es/photoidverification.json"),
    ProgramSelection: require("../../constants/Translations/es/ProgramSelection.json"),
    ThankYouSelection: require("../../constants/Translations/es/ThankYouSelection.json"),
    HBPCuffSize: require("../../constants/Translations/es/HBPCuffSize.json"),
    SelectShippingAddress: require("../../constants/Translations/es/SelectShippingAddress.json"),
    ShippingAddressConfirmed: require("../../constants/Translations/es/ShippingAddressConfirmed.json"),
    NoticeOfPrivacyConsent: require("../../constants/Translations/es/NoticeOfPrivacyConsent.json"),
    WMDMIIError: require("../../constants/Translations/es/WMDMIIError.json"),
    WMError: require("../../constants/Translations/es/WMError.json"),
    WMMedication: require("../../constants/Translations/es/WMMedication.json"),
    CostConsentHTML1: require("../../constants/Translations/es/CostConsentHTML1.json"),
    CostConsentHTML1A: require("../../constants/Translations/es/CostConsentHTML1A.json"),
    CostConsentHTML2: require("../../constants/Translations/es/CostConsentHTML2.json"),
    CostConsentHTML3: require("../../constants/Translations/es/CostConsentHTML3.json"),
    CostConsentHTML4: require("../../constants/Translations/es/CostConsentHTML4.json"),
    CostConsentHTML5: require("../../constants/Translations/es/CostConsentHTML5.json"),
    ConsolidatedConsent: require("../../constants/Translations/es/ConsolidatedConsent.json"),
    ExclusionaryCriteria: require("../../constants/Translations/es/ExclusionaryCriteria.json"),
    ExclusionaryCriteriaError: require("../../constants/Translations/es/ExclusionaryCriteriaError.json"),
    financialconsenttext: require("../../constants/Translations/es/financialconsenttext.json"),
    GeneralConsentText: require("../../constants/Translations/es/GeneralConsentText.json"),
    GlucometerSelection: require("../../constants/Translations/es/GlucometerSelection.json"),
    HBPConsentText: require("../../constants/Translations/es/HBPConsentText.json"),
    programconsenttext: require("../../constants/Translations/es/programconsenttext.json"),
    QuestionnaireVerification: require("../../constants/Translations/es/QuestionnaireVerification.json"),
    ScheduleVideoConsultation_2: require("../../constants/Translations/es/ScheduleVideoConsultation_2.json"),
    telemedicineconsenttext: require("../../constants/Translations/es/telemedicineconsenttext.json"),
    financialError: require("../../constants/Translations/es/financialError.json"),
    programConsentDetails: require("../../constants/Translations/es/programConsentDetails.json"),
    noticeofprivacyconsenttext: require("../../constants/Translations/es/noticeofprivacyconsenttext.json"),
    Confirmation: require("../../constants/Translations/es/ConfirmationPage.json"),
    Misc: require("../../constants/Translations/es/Misc.json"),
    ScheduleVisitError: require("../../constants/Translations/es/ScheduleVisitError.json"),
    PatientCapError: require("../../constants/Translations/es/patientCapError.json"),
    GeneralError: require("../../constants/Translations/es/generalError.json"),
    UserInfoError: require("../../constants/Translations/es/UserInfoError.json"),
    ProgramValidation: require("../../constants/Translations/es/ProgramValidation.json"),
    IDVerificationError: require("../../constants/Translations/es/IDVerificationError.json"),
    UnderAgeError: require("../../constants/Translations/es/UnderAgeError.json"),
    PageTitles: require("../../constants/Translations/es/pageTitles.json"),
    BackForwardButton: require("../../constants/Translations/es/BackForwardButton.json"),
    CombinedConsentText: require("../../constants/Translations/es/combinedconsenttext.json"),
  },
};
