import { Typography } from "constants/Typography/Typography";
import "../../common/style.css";
import "moment-timezone";
import { IContextState } from "store/Context";
import { useApi } from "store/useApi";
import ellipse from "../../assets/svgs/ellipse.svg";
import calendarIcon from "../../assets/svgs/calendar_icon.svg";
import "./styles.css";
import { useTheme } from "styled-components";
import { useEffect } from "react";
import BulletPointsComponent from "common/BulletPointsComponent";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import useTranslation from "common/TranslationHook/useTranslation";

interface AttendVVProps {
  state: IContextState;
  healthGoalsVerbiage: string;
  apptDateandTimeFormatted: string;
  apptDate: string;
  apptTimeStart: string;
  apptTimeEnd: string;
}

const AttendVV = ({
  state,
  healthGoalsVerbiage,
  apptDateandTimeFormatted,
  apptDate,
  apptTimeStart,
  apptTimeEnd,
}: AttendVVProps) => {
  const theme = useTheme();
  const { auditTrail } = useApi();
  const emailAddress = state.EmailAddress;
  const { t } = useTranslation("Confirmation");

  useEffect(() => {
    auditTrail("FinalConfirmation");
  }, [auditTrail]);

  // Build bullet points data dynamically
  const myOchsnerUrl = process.env.REACT_APP_MYOCH_URL || "";
  const bulletPointsData = state.hasMyChartAccount
    ? [
        t("attendVV.videoConsultationLocation"),
        `${t("attendVV.deviceShippingInfo")} ${healthGoalsVerbiage}`,
      ]
    : [
        t("attendVV.videoConsultationLocation"),
        <Typography
          mb="12px"
          style={{ marginTop: 0 }}
          dangerouslySetInnerHTML={{
            __html: t("attendVV.myOchsnerUsernameReminder")
              .replace("{emailAddress}", `<b>${emailAddress}</b>`)
              .replace("{link}", myOchsnerUrl),
          }}
        />,
        `${t("attendVV.deviceShippingInfo")} ${healthGoalsVerbiage}.`,
      ];

  return (
    <div>
      <div
        style={{
          backgroundColor: theme.colors.tintSecondary10,
          border: `1px solid ${theme.colors.secondary}`,
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <Typography>
          <b>{apptDateandTimeFormatted}</b>
        </Typography>
        <div
          style={{
            display: "flex",
            cursor: "pointer",
          }}
        >
          <img src={calendarIcon} alt="Calendar Icon" />
          <AddToCalendarButton
            name={t("attendVV.calendarEvent.title")}
            startDate={apptDate}
            startTime={apptTimeStart}
            endTime={apptTimeEnd}
            options={[
              "Apple",
              "Google",
              "Microsoft365",
              "Outlook.com",
              "Yahoo",
              "iCal",
            ]}
            iCalFileName="Reminder-Event"
            timeZone="currentBrowser"
            location="Online"
            description={t("attendVV.calendarEvent.description")}
            hideIconButton
            buttonStyle="text"
            hideCheckmark
            styleLight=" --font:  'Montserrat-Medium'; "
            id="css-part-example"
          />
        </div>
      </div>
      <BulletPointsComponent
        bulletPoints={bulletPointsData}
        ellipse={ellipse}
      />
    </div>
  );
};

export default AttendVV;
