import { Typography } from "constants/Typography/Typography";
import BackForwardButton from "common/BackForwardButton";
import { useEffect } from "react";
import { useApi } from "store/useApi";
import { StyledLink } from "screens/landingPage/newLandingPageComponents/JoinTheProgram";
import useTranslation from "common/TranslationHook/useTranslation";

interface UserInfoErrorProps {
  closeButtonAction: () => void;
}

export const UserInfoError = ({ closeButtonAction }: UserInfoErrorProps) => {
  const { auditTrail } = useApi();
  const { t } = useTranslation("UserInfoError");

  useEffect(() => {
    auditTrail("Could not locate Patient");
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 140,
      }}
    >
      <Typography>
        {t("userInfoErrorHeader", {
          supportPhoneLink: (
            <StyledLink
              href="tel:866-273-0548"
              style={{ fontFamily: "Montserrat-Bold" }}
            >
              {t("supportPhoneLink")}
            </StyledLink>
          ),
        })}
      </Typography>

      <BackForwardButton
        closeButtonAction={closeButtonAction}
        backVisible={false}
        frontVisible={false}
        closeButtonVisible={true}
      />
    </div>
  );
};
