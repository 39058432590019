import { Typography } from "constants/Typography/Typography";
import "../screens/style.css";
import { FontWeight } from "constants/Fonts";
import useTranslation from "common/TranslationHook/useTranslation";

interface CombinedConsentTextProps {
  forceStaticHTMLSupport?: boolean;
}

const CombinedConsentText = ({
  forceStaticHTMLSupport = false,
}: CombinedConsentTextProps) => {
  const { t } = useTranslation("CombinedConsentText");

  const TypographyOrP = ({ children, ...rest }: any) =>
    forceStaticHTMLSupport ? (
      <p>{children}</p>
    ) : (
      <Typography {...rest}>{children}</Typography>
    );
  const subpoints = t("consentForExaminationAndTreatment.subpoints", {
    returnObjects: true,
  });
  const secondSubpoints = t("subpoints", {
    returnObjects: true,
  });

  return (
    <TypographyOrP>
      <TypographyOrP fontSize="small">
        <ol type="A">
          <li>
            <b>{t("consentForExaminationAndTreatment.title")}</b>{" "}
            {t("consentForExaminationAndTreatment.description")}
          </li>
          <ol type="1">
            {Array.isArray(subpoints) &&
              subpoints.map(
                (subpoint: { description: string }, index: number) => (
                  <li key={index}>{subpoint.description}</li>
                ),
              )}
          </ol>
          <li>
            <b>{t("authorizationForReleaseOfInformation.title")}</b>{" "}
            {t("authorizationForReleaseOfInformation.description")}
          </li>
        </ol>
        <div>{t("continuedCareAuthorization")}</div>
        <ol type="A" start={3}>
          <li>
            <b>{t("medicarePatientCertification.title")}</b>{" "}
            {t("medicarePatientCertification.description")}
          </li>
          <li>
            <b>{t("insuranceBenefitsAssignment.title")}</b>{" "}
            {t("insuranceBenefitsAssignment.description")}
          </li>
          <li>
            <b>{t("financialResponsibilityAcceptance.title")}</b>{" "}
            {t("financialResponsibilityAcceptance.description")}
          </li>
          <li>
            <b>{t("communicationAuthorization.title")}</b>{" "}
            {t("communicationAuthorization.description")}
          </li>
          <li>
            <b>{t("relationshipBetweenFacilityAndPhysician.title")}</b>{" "}
            {t("relationshipBetweenFacilityAndPhysician.description")}
          </li>
          <li>
            <b>{t("privacyPractices.title")}</b>{" "}
            {t("privacyPractices.description")}
          </li>
          <li>
            <b>{t("term.title")}</b> {t("term.description")}
          </li>
          <li>
            <b>{t("ochsnerDefinition.title")}</b>{" "}
            {t("ochsnerDefinition.description")}
          </li>
        </ol>
        <TypographyOrP style={{ fontStyle: "italic" }}>
          {t("nonDiscrimination.description")}
          <br />
          <br />
          {t("languageAssistance.spanish")}
          <br />
          <br />
          {t("languageAssistance.vietnamese")}
        </TypographyOrP>
      </TypographyOrP>

      <TypographyOrP fontSize="small">
        <div>
          <b>{t("purpose.title")}</b> {t("purpose.description")}
        </div>
        <br />
        <div>
          <b>{t("description.title")}</b> {t("description.introduction")}
        </div>
        <div>
          <br />
          {t("description.additionalInfo")}
        </div>
        <ol>
          {Array.isArray(secondSubpoints) &&
            secondSubpoints.map((point: any, index: number) => (
              <li key={index}>
                {point.title ? <b>{point.title}:</b> : null} {point.description}
                {point.risks && (
                  <ul>
                    {point.risks.map((risk: string, i: number) => (
                      <li key={i}>{risk}</li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
        </ol>
        <div>
          <b>{t("consent.title")}</b> {t("consent.description")}
        </div>
        <br />
        {t("consent.conclusion")}
      </TypographyOrP>

      <TypographyOrP fontSize="small">
        <div>
          <b>{t("medicalInformation.title")}</b>{" "}
          {t("medicalInformation.description")}
          <br /> <br />
          {t("healthInformationSharing.description")}
          <br /> <br />
          {t("hipaaCompliance.description")}
          {t("optOutInformation.description")}
          <ol>
            <li>
              Connecting with{" "}
              <a href="https://www.ochsner.org/patients-visitors/complaints-and-grievances/">
                Patient and Provider Advocacy
              </a>
            </li>
            <li>
              Send a message via your{" "}
              <a href={process.env.REACT_APP_MYOCH_URL}>MyOchsner account</a>
            </li>
          </ol>
        </div>
        <div>
          {t("noticeDetails.description")}
          <br /> <br />
          {t("noticeDetails.description2")}
        </div>
        <ul>
          <li>{t("legalRequirements.requirements[0]")}</li>
          <li>{t("legalRequirements.requirements[1]")}</li>
          <li>{t("legalRequirements.requirements[2]")}</li>
        </ul>
        <div>
          <b>{t("howOchsnerUsesInfo.title")}</b>
          <br />
          {t("howOchsnerUsesInfo.description")}
          <br />
          <br />
        </div>
        <div>
          <b>{t("treatment.title")}</b> {t("treatment.description")} <br />
          {t("treatment.examples[0]")}
          <br />
          {t("treatment.examples[1]")} <br />
          <br />
        </div>
        <div>
          <b>{t("payment.title")}</b> {t("payment.description")}
          <br />
          <br />
          {t("payment.example")}
          <br /> <br />
        </div>
        <div>
          <b>{t("operations.title")}</b> {t("operations.description")} <br />
          For example, Ochsner may use medical information to:
          <ul>
            <li>{t("operations.examples[0]")}</li>
            <li>{t("operations.examples[1]")}</li>
            <li>{t("operations.examples[2]")}</li>
            <li>{t("operations.examples[3]")}</li>
            <li>{t("operations.examples[4]")}</li>
          </ul>
          {t("operations.subDescription")}
          <br />
          <br />
        </div>
        <div>
          <b>{t("businessAssociates.title")}</b>{" "}
          {t("businessAssociates.description")}{" "}
          {t("businessAssociates.examples[0]")}
          <br />
          <br />
        </div>
        <div>
          <b>{t("treatmentAlternatives.title")}</b>{" "}
          {t("treatmentAlternatives.description")}
          <br />
          <br />
        </div>
        <div>
          <b>{t("healthBenefits.title")}</b> {t("healthBenefits.description")}
          <br />
          <br />
        </div>
        <div>
          <b>{t("fundraisingActivities.title")}</b>{" "}
          {t("fundraisingActivities.description")}{" "}
          {t("fundraisingActivities.optOutInfo")}
          <br />
          <br />
        </div>
        <div>
          <b>{t("hospitalDirectory.title")}</b>{" "}
          {t("hospitalDirectory.description")}. <br />
          <br />
        </div>
        <div>
          <b>{t("individualsInvolvedInCare.title")}</b>{" "}
          {t("individualsInvolvedInCare.description")} <br />
          <br />
        </div>
        <div>
          <b>{t("research.title")}</b> {t("research.description")} <br />
          {t("research.examples")}
          <br />
          {t("research.approvalProcess")}
          <br />
          {t("research.approvalProcessB")} <br />
          {t("research.approvalProcessC")} <br />
        </div>
        <div>
          <b>{t("legalRequirementsB.requiredByLaw.title")}</b>{" "}
          {t("legalRequirementsB.requiredByLaw.description")} <br />
          <br />
        </div>
        <div>
          <b>{t("legalRequirementsB.threatToHealthOrSafety.title")}</b>{" "}
          {t("legalRequirementsB.threatToHealthOrSafety.description")} <br />
          <br />
        </div>
        <div>
          <b>{t("legalRequirementsB.disasterRelief.title")}</b>{" "}
          {t("legalRequirementsB.disasterRelief.description")} <br /> <br />
          <br />
        </div>
        <b>{t("specialSituations.title")}</b> <br />
        <div>
          <b>{t("specialSituations.organDonation.title")}</b>{" "}
          {t("specialSituations.organDonation.description")}
          <br />
        </div>
        <div>
          <b>{t("specialSituations.militaryAndVeterans.title")}</b>{" "}
          {t("specialSituations.militaryAndVeterans.description")}
          <br />
          <br />
        </div>
        <div>
          <b>{t("specialSituations.workersCompensation.title")}</b>{" "}
          {t("specialSituations.workersCompensation.description")}
          <br />
          <br />
        </div>
        <div>
          <b>{t("specialSituations.publicHealth.title")}</b>{" "}
          {t("specialSituations.publicHealth.description")}
          <ul>
            <li>{t("specialSituations.publicHealth.description[0]")}</li>
            <li>{t("specialSituations.publicHealth.description[1]")}</li>
            <li>{t("specialSituations.publicHealth.description[2]")}</li>
            <li>{t("specialSituations.publicHealth.description[3]")}</li>
            <li>{t("specialSituations.publicHealth.description[4]")}</li>
            <li>{t("specialSituations.publicHealth.description[5]")}</li>
            <li>{t("specialSituations.publicHealth.description[6]")}</li>
          </ul>{" "}
          <br />
          <br />
        </div>
        <div>
          <b>{t("specialSituations.healthOversight.title")}</b>{" "}
          {t("specialSituations.healthOversight.description")} <br />
          <br />
        </div>
        <div>
          <b>{t("specialSituations.legalDisputes.title")}</b>{" "}
          {t("specialSituations.legalDisputes.description")} <br />
          <br />
        </div>
        <div>
          <b>{t("specialSituations.coroner.title")}</b>{" "}
          {t("specialSituations.coroner.description")}
          <br />
          <br />
        </div>
        <div>
          <b>{t("specialSituations.lawEnforcement.title")}</b>{" "}
          {t("specialSituations.lawEnforcement.description")}
          <br />
          <ul>
            <li>{t("specialSituations.lawEnforcement.reasons[0]")}</li>
            <li>{t("specialSituations.lawEnforcement.reasons[1]")}</li>
            <li>{t("specialSituations.lawEnforcement.reasons[2]")}</li>
            <li>{t("specialSituations.lawEnforcement.reasons[3]")}</li>
            {t("specialSituations.lawEnforcement.reasons[4]")}
            <li>{t("specialSituations.lawEnforcement.reasons[5]")}</li>
          </ul>
        </div>
        <div>
          <b>{t("specialSituations.nationalSecurity.title")}</b>{" "}
          {t("specialSituations.nationalSecurity.description")} <br /> <br />
        </div>
        <div>
          <b>{t("protectiveServices.title")}</b>{" "}
          {t("protectiveServices.description")} <br />
          <br />
        </div>
        <div>
          <b>{t("inmates.title")}</b> {t("inmates.description")} <br />
          <br />
        </div>
        <div>
          <b>{t("situationsRequiringAuthorization.title")}</b>
          <br />
          <b>{t("situationsRequiringAuthorization.marketing.title")}</b>{" "}
          {t("situationsRequiringAuthorization.marketing.description")}
          <ul>
            <li>
              {t("situationsRequiringAuthorization.marketing.reasons[0]")}
            </li>
            <li>
              {t("situationsRequiringAuthorization.marketing.reasons[1]")}
            </li>
            <li>
              {t("situationsRequiringAuthorization.marketing.reasons[2]")}
            </li>
          </ul>
        </div>
        <div>
          <b>{t("saleOfPHI.title")}</b> {t("saleOfPHI.description")}
          <ul>
            <li>{t("saleOfPHI.reasons[0]")}</li>
            <li>{t("saleOfPHI.reasons[1]")}</li>
            <li>{t("saleOfPHI.reasons[2]")}</li>
            <li>{t("saleOfPHI.reasons[3]")}</li>
            <li>{t("saleOfPHI.reasons[4]")}</li>
            <li>{t("saleOfPHI.reasons[5]")}</li>
            <li>{t("saleOfPHI.reasons[6]")}</li>
          </ul>
        </div>
        <div>
          <b>{t("disclosurePsychotherapyNotes.title")}</b>
          {t("disclosurePsychotherapyNotes.description")} <br /> <br />
        </div>
        <div>
          <b>{t("yourRightsRegardingMedicalInfo.title")}</b> <br />
          {t("yourRightsRegardingMedicalInfo.description")}
        </div>
        <div>
          <b>{t("rightToInspectCopy.title")}</b>
          {t("rightToInspectCopy.description")} <br />
          {t("rightToInspectCopy.details")} <br />
          {t("rightToInspectCopy.deny")}
        </div>
        <div>
          <b>{t("rightToRequestAmendment.title")}</b>
          {t("rightToRequestAmendment.description")} <br />
          {t("rightToRequestAmendment.details")} <br />
          {t("rightToRequestAmendment.deny")}
          <ul>
            <li>{t("rightToRequestAmendment.reasons[0]")}</li>
            <li>{t("rightToRequestAmendment.reasons[1]")}</li>
            <li>{t("rightToRequestAmendment.reasons[2]")}</li>
            <li>{t("rightToRequestAmendment.reasons[3]")}</li>
          </ul>
          {t("rightToRequestAmendment.statement")} <br />
          <br />
        </div>
        <div>
          <b>{t("rightToAccountingDisclosures.title")}</b>
          {t("rightToAccountingDisclosures.description")} <br />
          {t("rightToAccountingDisclosures.exclude")}
          <ul>
            <li>{t("rightToAccountingDisclosures.exclusions[0]")}</li>
            <li>{t("rightToAccountingDisclosures.exclusions[1]")}</li>
            <li>{t("rightToAccountingDisclosures.exclusions[2]")}</li>
            <li>{t("rightToAccountingDisclosures.exclusions[3]")}</li>
            <li>{t("rightToAccountingDisclosures.exclusions[4]")}</li>
            <li>{t("rightToAccountingDisclosures.exclusions[5]")}</li>
            <li>{t("rightToAccountingDisclosures.exclusions[6]")}</li>
            <li>{t("rightToAccountingDisclosures.exclusions[7]")}</li>
          </ul>
          {t("rightToAccountingDisclosures.requestDetails")} <br />
          {t("rightToAccountingDisclosures.firstRequest")} <br /> <br />
          <br />
        </div>
        <b>{t("rightToRequestRestrictions.title")}</b>
        <div>
          {t("rightToRequestRestrictions.description")} <br />
          {t("rightToRequestRestrictions.notRequiredToAgree")}{" "}
          <b>{t("rightToRequestRestrictions.theFullCost")}</b>{" "}
          {t("rightToRequestRestrictions.agreementInWriting")} <br />
          {t("rightToRequestRestrictions.requestInstructions")} <br />
          <br />
        </div>
        <div>
          <b>{t("rightToRequestConfidentialCommunications.title")}</b>
          {t("rightToRequestConfidentialCommunications.description")} <br />
          {t(
            "rightToRequestConfidentialCommunications.requestInstructions",
          )}{" "}
          <br />
          <br />
        </div>
        <div>
          <b>{t("rightToNotificationOfBreach.title")}</b>{" "}
          {t("rightToNotificationOfBreach.description")} <br />
          <br />
        </div>
        <div>
          <b>{t("rightToPaperCopy.title")}</b>
          {t("rightToPaperCopy.description")} <br />
          {t("rightToPaperCopy.reviewWebsite")} <br />
          {t("rightToPaperCopy.patientRightsRequest")}
        </div>
        <div>
          <p>
            <i>{t("addressJeffersonHighway.title")}</i>
          </p>
          {t("addressJeffersonHighway.name")} <br />
          {t("addressJeffersonHighway.department")} <br />
          {t("addressJeffersonHighway.street")} <br />
          {t("addressJeffersonHighway.cityStateZip")}
          <br />
        </div>
        <div>
          <p>
            <i>{t("addressKenner.title")}</i>
          </p>
          {t("addressKenner.name")} <br />
          {t("addressKenner.department")} <br />
          {t("addressKenner.street")} <br />
          {t("addressKenner.cityStateZip")}
          <br />
        </div>
        <div>
          <p>
            <i>{t("addressBaptist.title")}</i>
          </p>
          {t("addressBaptist.name")} <br />
          {t("addressBaptist.department")} <br />
          {t("addressBaptist.street")} <br />
          {t("addressBaptist.cityStateZip")}
          <br />
        </div>
        <div>
          <p>
            <i>{t("addressBatonRouge.title")}</i>
          </p>
          {t("addressBatonRouge.name")} <br />
          {t("addressBatonRouge.department")} <br />
          {t("addressBatonRouge.street")} <br />
          {t("addressBatonRouge.cityStateZip")}
          <br />
        </div>
        <div>
          <p>
            <i>{t("addressStAnne.title")}</i>
          </p>
          {t("addressStAnne.name")} <br />
          {t("addressStAnne.department")} <br />
          {t("addressStAnne.street")} <br />
          {t("addressStAnne.cityStateZip")}
          <br />
        </div>
        <div>
          <p>
            <i>{t("addressWestBank.title")}</i>
          </p>
          {t("addressWestBank.name")}
          <br />
          {t("addressWestBank.department")} <br />
          {t("addressWestBank.street")} <br />
          {t("addressWestBank.cityStateZip")}
          <br />
        </div>
        <div>
          <p>
            <i>{t("addressNorthshore.title")}</i>
          </p>
          {t("addressNorthshore.name")} <br />
          {t("addressNorthshore.department")} <br />
          {t("addressNorthshore.street")} <br />
          {t("addressNorthshore.cityStateZip")}
          <br />
        </div>
        <div>
          <b>{t("changesNotice.title")}</b>
          <br />
          {t("changesNotice.text")} <br />
          <br />
        </div>
        <div>
          <b>{t("complaints.title")}</b>
          <br />
          {t("complaints.text")} <br />
          {t("complaints.noPenalty")} <br />
          <br />
        </div>
        <div>
          <b>{t("otherUses.title")}</b>
          <br />
          {t("otherUses.text")}
        </div>
        <br />
        <br />
        <div>
          <b>{t("whoMustFollow.title")}</b>
          <br />
          {t("whoMustFollow.text")}
          <ul>
            <li>{t("whoMustFollow.list1")}</li>
            <li>{t("whoMustFollow.list2")}</li>
            <li>{t("whoMustFollow.list3")}</li>
            <li>{t("whoMustFollow.list4")}</li>
          </ul>
          <br />
          {t("whoMustFollow.additionalInfo")} <br />
          {t("whoMustFollow.contactTitle")} <br />
          <br />
          {t("whoMustFollow.contactName")} <br />
          {t("whoMustFollow.contactDept")} <br />
          {t("whoMustFollow.contactAttn")} <br />
          {t("whoMustFollow.contactAddress1")} <br />
          {t("whoMustFollow.contactCityZip")} <br />
          {t("whoMustFollow.contactPhone")}
          <br />
          <br />
          {t("whoMustFollow.nonDiscrimination")} <br />
          <br />
        </div>
        <div>
          {t("languageAssistance.english")} <br />
          <br />
          {t("languageAssistance.spanish")} <br />
          <br />
          {t("languageAssistance.vietnamese")}
        </div>
        <TypographyOrP fontSize="small">
          <ol>
            <li>{t("infoDisclaimer.item1")}</li>
            <br />
            <li>
              {t("infoDisclaimer.item2")} <br />
              <br />
              {t("infoDisclaimer.item2Note")}
              <br />
              <br />
              {t("infoDisclaimer.item2Explanation")} <br />
              <br />
              {t("infoDisclaimer.contactHealthPlan")}
              <br />
              {t("infoDisclaimer.providerList")} <br />
              <br />
              {t("infoDisclaimer.contactPatientService")}
            </li>
            <br />
            <li>
              <b>{t("facilityUse.title")}</b> {t("facilityUse.text")}
            </li>
          </ol>
        </TypographyOrP>
        <p>
          Ochsner Health System complies with applicable Federal civil rights
          laws and does not discriminate on the basis of race, color, national
          origin,age, disability, or sex. ATENCIÓN: si habla español, tiene a su
          disposición servicios gratuitos de asistencia lingüística. Llame al
          1-800-928-6247. CHÚ Ý: Nếu bạn nói Tiếng Việt, có các dịch vụ hỗ trợ
          ngôn ngữ miễn phí dành cho bạn. Gọi số 1-800-928-6247.{" "}
        </p>
        <p style={{ textAlign: "center" }}>
          <b>Independent Healthcare Professionals </b>
        </p>
        <div style={{ display: "flex" }}>
          <div
            style={{
              border: "1px solid black",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              padding: "2px",
            }}
          >
            <u>
              <b>Ochsner Baptist Medical Center:</b>
            </u>
            Schumacher Clinical Partners <br />
            (800) 893-9698, press 1 <br />
            <br />
            <u>
              <b>Ochsner Medical Center Baton Rouge: </b>
            </u>
            <br />
            Diversified Professionals, Inc
            <br />
            (225) 923-0030 <br />
            <br />
            Infamedics
            <br />
            (225) 928-2555 <br />
            <br />
            Southern Radiology Consultants <br />
            (225) 769-6700 <br />
            <br />
            StatRad <br />
            (858) 546-3800 <br />
            <br />
            Schumacher Clinical Partners <br />
            (800) 893-9698, press 1 <br />
            <br />
            <u>
              <b>St. Charles Parish Hospital </b>
            </u>
            <br />
            Schumacher Clinical Partners <br />
            (800) 893-9698, press 1 <br />
            <br />
            Southern Radiology Consultants <br />
            (225) 769-6700
            <br />
            <br />
            <u>
              <b>Leonard J. Chabert Medical Center </b>
            </u>
            <br />
            South Louisiana Medical <br />
            Associates (985) 868-9300 <br />
            <br />
          </div>
          <div
            style={{
              border: "1px solid black",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              padding: "2px",
            }}
          >
            <u>
              <b>Ochsner Medical Center Kenner: </b>
            </u>
            <br />
            Schumacher Clinical Partners <br />
            (800) 893-9698, press 1 <br />
            <br />
            LSU Healthcare Network/Anesthesia Group <br />
            (855) 631-6628 <br />
            <br />
            Southern Radiology Consultants
            <br />
            (225) 769-6700 <br />
            <br />
            <u>
              <b>Ochsner Medical Center West Bank: </b>
            </u>
            <br />
            Pediatric Kid-Med <br />
            (504) 391-7690 <br />
            <br />
            Neonatal Medical Group
            <br />
            (504) 896-2229 <br />
            <br />
            Schumacher Clinical Partners <br />
            (800) 893-9698, press 1 <br />
            <br />
            <u>
              <b>St. Bernard Parish Hospital </b>
            </u>
            <br />
            Parish Anesthesia <br />
            (800) 242-1131 <br />
            <br />
            Schumacher Clinical Partners <br />
            (800) 893-9698, press 1 <br />
            <br />
            <u>
              <b>Ochsner St. Anne </b>
            </u>
            <br />
            Southern Radiology Consultants
            <br />
            (225) 769-6700 <br />
            <br />
          </div>
          <div
            style={{
              border: "1px solid black",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              padding: "2px",
            }}
          >
            <u>
              <b>St. Mary's Morgan City </b>
            </u>
            <br />
            Direct Radiology <br />
            (855)-687-7237 <br />
            <br />
            Houma Radiology Associates <br />
            (985)-876-2727 <br />
            <br />
            Relia-Path <br />
            (337)-233-1899 <br />
            <br />
            SCP Health <br />
            (800)-893-9698 <br />
            <br />
            Bertrand Anesthesia <br />
            (832)-623-8199 <br />
            <br />
            South Louisiana Anesthesia Associates <br />
            (337)-365-3168 <br />
            <br />
            YPS Anesthesia <br />
            (337)-692-3822 <br />
            <br />
            <u>
              <b>Ochsner Christus Lake Charles </b>
            </u>
            <br />
            Radiology Associates of Southwest Louisiana <br />
            800-841-4236 <br />
            <br />
            <u>
              <b>Ochsner Medical Center (Jeff Hwy) </b>
            </u>
            <br />
            Schumacher Clinical Partners <br />
            (800) 893-9698, press 1 <br />
            <br />
          </div>
        </div>
        <p style={{ textAlign: "center" }}>
          <b>
            Ochsner Lafayette General <br /> Provider based physician's billing
            contact information.{" "}
          </b>
        </p>
        <p style={{ textAlign: "center" }}>
          These providers are not billed by the Hospital; please use this
          contact information should you have questions about these services.{" "}
        </p>
        <div style={{ display: "flex" }}>
          <div
            style={{
              border: "1px solid black",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              padding: "2px",
            }}
          >
            <u>
              <b>Ochsner Acadia General Hospital </b>
            </u>
            <br />
            Preferred Anatomic (billed by ACS) <br />
            337-706-1601 or 261-5151 ext. 1528 <br />
            <br />
            Cardiovascular Institute of the South <br />
            (billed by ACS) <br />
            337-706-1601 or 261-5151 ext. 1528 <br />
            <br />
            Schumacher Group (billed by EMBCC) <br />
            888-703-3301 <br />
            <br />
            Regional Radiology <br />
            504-277-0191 <br />
            <br />
            Lafayette Health Ventures, Inc. <br />
            337-289-7287 <br />
            <br />
            <u>
              <b>Ochsner Abrom Kaplan Hospital </b>
            </u>
            <br />
            Preferred Anatomic (billed by ACS) <br />
            337-706-1601 or 261-5151 ext. 1528 <br />
            <br />
            Abrom Kaplan Anesthesia <br />
            337-643-5281 <br />
            <br />
            Regional Radiology <br />
            504-277-0191 <br />
            <br />
            Cardiovascular Institute of the South (billed by ACS) <br />
            337-706-1601 or 261-5151 ext. 1528 <br />
            <br />
          </div>
          <div
            style={{
              border: "1px solid black",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              padding: "2px",
            }}
          >
            <u>
              <b>Ochsner Lafayette General Medical Center: </b>
            </u>
            <br />
            Preferred Anatomic (billed by ACS) <br />
            337-706-1601 or 261-5151 ext. 1528 <br />
            <br />
            Acadiana Vascular (billed by ACS) <br />
            337-706-1601 or 261-5151 ext.1528 <br />
            <br />
            Southern Vascular Clinic (billed by ACS) <br />
            337-706-1601 or 261-5151 ext.1528 <br />
            <br />
            Cardiovascular Institute of the South(billed by ACS) <br />
            337-706-1601 or 261-5151 ext.1528 <br />
            <br />
            Schumacher Group (billed by EMBCC) <br />
            888-703-3301 <br />
            <br />
            Regional Radiology <br />
            504-277-0191 <br />
            <br />
            Lafayette Health Ventures, Inc. <br />
            337-289-7287 <br />
            <br />
            E+ Oncologics LA, LLC <br />
            888-353-4809 <br />
            <br />
          </div>
          <div
            style={{
              border: "1px solid black",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              padding: "2px",
            }}
          >
            <u>
              <b>Ochsner Lafayette General Orthopedic Hospital </b>
            </u>
            The Pathology Lab, Inc. <br />
            337-436-3688
            <br />
            <br />
            Acadiana Vascular (billed by ACS) <br />
            337-706-1601 or 261-5151 ext.1528 <br />
            <br />
            Southern Vascular Clinic (billed by ACS) <br />
            337-706-1601 or 261-5151 ext.1528 <br />
            <br />
            Cardiovascular Institute of the South(billed by ACS) <br />
            337-706-1601 or 261-5151 ext.1528 <br />
            <br />
            Schumacher Group (billed by EMBCC) <br />
            888-703-3301 <br />
            <br />
            Regional Radiology <br />
            504-277-0191 <br />
            <br />
            Lafayette Health Ventures, Inc. <br />
            337-289-7287 <br />
            <br />
          </div>
        </div>
        <p style={{ textAlign: "center" }}>
          <b>
            Ochsner Lafayette General <br /> Provider based physician's billing
            contact information.{" "}
          </b>
        </p>
        <p style={{ textAlign: "center" }}>
          These providers are not billed by the Hospital; please use this
          contact information should you have questions about these services.{" "}
        </p>
        <div style={{ display: "flex" }}>
          <div
            style={{
              border: "1px solid black",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              padding: "2px",
            }}
          >
            <u>
              <b>Ochsner Lafayette General Surgical Hospital </b>
            </u>
            <br />
            Preferred Anatomic (billed by ACS) <br />
            337-706-1601 or 261-5151 ext. 1528 <br />
            <br />
            Cardiovascular Institute of the South <br />
            (billed by ACS) <br />
            337-706-1601 or 261-5151 ext. 1528 <br />
            <br />
            Schumacher Group (billed by EMBCC) <br />
            888-703-3301 <br />
            <br />
            <br />
            Regional Radiology <br />
            504-277-0191 <br />
            <br />
            <br />
            Lafayette Health Ventures, Inc. <br />
            337-289-7287 <br />
            <br />
            <u>
              <b>Ochsner St. Martin Hospital</b>
            </u>
            <br />
            Preferred Anatomic (billed by ACS) <br />
            337-706-1601 or 261-5151 ext. 1528 <br />
            <br />
            Schumacher Group (billed by EMBCC) <br />
            888-703-3301 <br />
            <br />
            Regional Radiology <br />
            504-277-0191 <br />
            <br />
            Lafayette Health Ventures, Inc. <br />
            337-289-7287 <br />
            <br />
          </div>
          <div
            style={{
              border: "1px solid black",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              padding: "2px",
            }}
          >
            <u>
              <b>Ochsner University Hospital and Clinics </b>
            </u>
            <br />
            Preferred Anatomic (billed by ACS) <br />
            337-706-1601 or 261-5151 ext. 1528 <br />
            <br />
            Acadiana Vascular (billed by ACS) <br />
            337-706-1601 or 261-5151 ext.1528 <br />
            <br />
            Vein Center of LA (billed by ACS) <br />
            337-706-1601 or 261-5151 ext.1528 <br />
            <br />
            Cardiovascular Institute of the South(billed by ACS) <br />
            337-706-1601 or 261-5151 ext.1528 <br />
            <br />
            LSUHN Billing, LLC <br />
            800-375-2271 or 337-981-2494 <br />
            <br />
            Schumacher Group (billed by EMBCC) <br />
            888-703-3301 <br />
            <br />
            Regional Radiology <br />
            504-277-0191 <br />
            <br />
            Lafayette Health Ventures, Inc. <br />
            337-289-7287 <br />
            <br />
          </div>
          <div
            style={{
              border: "1px solid black",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              padding: "2px",
            }}
          >
            <u>
              <b>Oil Center Surgical Plaza </b>
            </u>
            Preferred Anatomic (billed by ACS) <br />
            337-706-1601 or 261-5151 ext. 1528 <br />
            <br />
            Cardiovascular Institute of the South(billed by ACS) <br />
            337-706-1601 or 261-5151 ext.1528 <br />
            <br />
            Schumacher Group (billed by EMBCC) <br />
            888-703-3301 <br />
            <br />
            Regional Radiology <br />
            504-277-0191 <br />
            <br />
            Lafayette Health Ventures, Inc. <br />
            337-289-7287 <br />
            <br />
          </div>
        </div>
      </TypographyOrP>

      <TypographyOrP fontSize="small">
        <TypographyOrP fontWeight={FontWeight.bold}>
          {t("hypertensionConsent.title")}
        </TypographyOrP>
        <p>{t("hypertensionConsent.description1")}</p>
        <p>{t("hypertensionConsent.description2")}</p>
        <TypographyOrP fontStyle="h4">
          {t("informedConsent.title")}
        </TypographyOrP>
        <p>
          <span>{t("informedConsent.description1")}</span>
          <strong>
            <span>{t("informedConsent.healthServices")}</span>
          </strong>
          <span>{t("informedConsent.description2")}</span>
          <strong>
            <span>{t("informedConsent.careTeam")}</span>
          </strong>
          <span>{t("informedConsent.description3")}</span>
        </p>
        <ul>
          <li>
            <strong>
              <u>{t("enrollingPhysician.title")}</u>
            </strong>
            <strong>
              <span>:</span>
            </strong>
            <span> {t("enrollingPhysician.description")}</span>
          </li>
          <li>
            <strong>
              <u>{t("orderingProvider.title")}</u>
            </strong>
            <u>
              <span>:</span>
            </u>
            <span> {t("orderingProvider.description")}</span>
          </li>
          <li>
            <strong>
              <u>{t("clinician.title")}</u>
            </strong>
            <strong>
              <span>:</span>
            </strong>
            <span> {t("clinician.description")}</span>
          </li>
          <li>
            <strong>
              <u>{t("healthCoaches.title")}</u>
            </strong>
            <strong>
              <span>:</span>
            </strong>
            <span> {t("healthCoaches.description")}</span>
          </li>
        </ul>
        <p>{t("participation.description1")}</p>
        <p>{t("participation.description2")}</p>
        <p>{t("reviewDescription")}</p>
        <p>
          <span>{t("monitoringDescription")}</span>
          <strong>
            <span>{t("routineCareNote")}</span>
          </strong>
          <span> {t("contactInstructions")}</span>
          <strong>
            <span>{t("emergencyWarning")}</span>
          </strong>
          <strong>
            <u>
              <span
                style={{
                  fontFamily: "Calibri Light",
                  textTransform: "uppercase",
                }}
              >
                {t("doNotDisregard.title")}
              </span>
            </u>
          </strong>
          <p>{t("withdrawDescription")}</p>
          <p>{t("programFeatures.title")}</p>
        </p>
        <p>
          <span>{t("withdrawDescription")}</span>
        </p>
        <p>
          <span>{t("programFeatures.title")}</span>
          <strong>
            <span>{t("monitoringDevice")}</span>
          </strong>
          <span>{t("monitoringDeviceDescription")}</span>
          <strong>
            <span>{t("monitoredHealthData")}</span>
          </strong>
          <span>{t("monitoredHealthDataDescription")}</span>
          <strong>
            <span>{t("digitalMedicineApp")}</span>
          </strong>
          <span>{t("digitalMedicineAppDescription")}</span>
          <strong>
            <span>{t("myOchsnerApp")}</span>
          </strong>
          <span>{t("myOchsnerAppDescription")}</span>
          <strong>
            <span>{t("integratedApps")}</span>
          </strong>
          <span>{t("integratedAppsDescription")}</span>
        </p>
        <p>
          <span>{t("integratedAppsCloudDescription")}</span>
        </p>
        <p>
          <u>
            <span>{t("providerPlatformDescription")}</span>
          </u>
          <strong>
            <u>
              <span>{t("providerPlatform")}</span>
            </u>
          </strong>
          <u>
            <span>{t("providersDescription")}</span>
          </u>
          <strong>
            <u>
              <span>{t("providers")}</span>
            </u>
          </strong>
          <u>
            <span>{t("programTechnologyDescription")}</span>
          </u>
        </p>
        <p>
          <span>{t("programContentDisclaimer")}</span>
        </p>
        <p>
          <span>{t("liabilityDisclaimer")}</span>
        </p>
        <p>
          <span>{t("availableServicesDescription")}</span>
        </p>
        <p>
          <span>{t("programParticipationDescription")}</span>
          <span>{t("cooperationPromise")}</span>
          <span>{t("accuracyAndLawfulnessPromise")}</span>
          <span>{t("nonCooperationConsequences")}</span>
          <span>{t("accessTerminationDescription")}</span>
        </p>
        <p>
          <span>{t("privacyAndConfidentialityAssurance")}</span>
          <span>{t("confidentialInformationPromise")}</span>
          <span>{t("unauthorizedAccessResponse")}</span>
        </p>
        <p>
          <span>{t("communicationConsentDescription")}</span>
          <span>{t("appCommunicationAcknowledgement")}</span>
          <span>{t("emailCommunicationAcknowledgement")}</span>
          <span>{t("textMessageAcknowledgement")}</span>
          <span>{t("eCommunicationOptOut")}</span>
        </p>
        <p>
          <span>{t("enrollmentProcessDescription")}</span>
        </p>
        <p>
          <span>{t("thirdPartyVendorNotice")}</span>
          <span>{t("thirdPartySupportDisclaimer")}</span>
          <span>{t("thirdPartyVendorChangeNotice")}</span>
        </p>

        <p>
          <span>Ochsner works with the following Third-Party Vendors:</span>
        </p>
        <ul>
          <li>
            <span>Patient Portal: MyOchsner (MyChart for Ochsner Health)</span>
            <ul
              style={{
                marginRight: "0px",
                marginLeft: "0px",
                paddingLeft: "0px",
                listStyle: "circle",
              }}
            >
              <li style={{ marginLeft: "29.3px", paddingLeft: "6.7px" }}>
                <a
                  href="https://my.ochsner.org/PRD/default.asp?mode=stdfile&option=termsandconditions"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <u>
                    <span
                      style={{ fontFamily: "Calibri Light", color: "#000000" }}
                    >
                      https://my.ochsner.org/PRD/default.asp?mode=stdfile&option=termsandconditions
                    </span>
                  </u>
                </a>
              </li>
              <li style={{ marginLeft: "29.3px", paddingLeft: "6.7px" }}>
                <a
                  href="https://my.ochsner.org/PRD/default.asp?mode=stdfile&option=hlthprivacy"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <u>
                    <span
                      style={{ fontFamily: "Calibri Light", color: "#000000" }}
                    >
                      https://my.ochsner.org/PRD/default.asp?mode=stdfile&amp,opx"ion=hlthprivacy
                    </span>
                  </u>
                </a>
                <span></span>
              </li>
            </ul>
          </li>
          <li>
            <span>Devices: iHealth Labs</span>
            <ul
              style={{
                marginRight: "0px",
                marginLeft: "0px",
                paddingLeft: "0px",
                listStyle: "circle",
              }}
            >
              <li style={{ marginLeft: "29.3px", paddingLeft: "6.7px" }}>
                <a
                  href="https://ihealthlabs.com/about-us/terms-and-conditions/"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <u>
                    <span
                      style={{ fontFamily: "Calibri Light", color: "#000000" }}
                    >
                      https://ihealthlabs.com/about-us/terms-and-conditions/
                    </span>
                  </u>
                </a>
              </li>
              <li style={{ marginLeft: "29.3px", paddingLeft: "6.7px" }}>
                <a
                  href="https://ihealthlabs.com/pages/privacy-policy"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <u>
                    <span
                      style={{ fontFamily: "Calibri Light", color: "#000000" }}
                    >
                      https://ihealthlabs.com/pages/privacy-policy
                    </span>
                  </u>
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <p>
          <strong>
            <u>
              <span style={{ textDecoration: "underline" }}>
                {t("programDisclaimerTitle")}
              </span>
            </u>
          </strong>
        </p>
        <p>
          <span>{t("programLocationDisclaimer")}</span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <u>
            <span>{t("healthServicesDiscretion")}</span>
          </u>
          <u>
            <span></span>
          </u>
          <u>
            <span style={{ textDecoration: "underline" }}>
              {t("liabilityDisclaimerB")}
            </span>
          </u>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>{t("programTermination")}</span>
        </p>
        <p>
          <span>{t("programFeatureChanges")}</span>
          <span></span>
        </p>
      </TypographyOrP>
      <TypographyOrP>
        <TypographyOrP fontWeight={FontWeight.bold}>
          {t("typeIIDiabetesConsentTitle")}
        </TypographyOrP>
        <p>{t("typeIIDiabetesConsentIntro")}</p>
        <p>{t("viewConditionsInProfile")}</p>
        <TypographyOrP fontStyle="h4">
          {t("informedConsentTitle")}
        </TypographyOrP>
        <p>
          <span>{t("healthServicesAccessIntro")}</span>
          <strong>
            <span>{t("healthServices")}</span>
          </strong>
          <span>{t("careTeamDescription")}</span>
          <strong>
            <span>{t("careTeam")}</span>
          </strong>
          <span>{t("careTeamRolesDescription")}</span>
        </p>
        <ul>
          <li>
            <strong>
              <u>
                <span>{t("enrollingPhysicianTitle")}</span>
              </u>
            </strong>
            <strong>
              <span>:</span>
            </strong>
            <span>{t("enrollingPhysicianDescription")}</span>
          </li>
          <li>
            <strong>
              <u>
                <span>{t("orderingProviderTitle")}</span>
              </u>
            </strong>
            <u>
              <span>:</span>
            </u>
            <span>{t("orderingProviderDescription")}</span>
          </li>
          <li>
            <strong>
              <u>
                <span>{t("clinicianTitle")}</span>
              </u>
            </strong>
            <strong>
              <span>:</span>
            </strong>
            <span>{t("clinicianDescription")}</span>
          </li>
          <li>
            <strong>
              <u>
                <span>{t("healthCoachesTitle")}</span>
              </u>
            </strong>
            <strong>
              <span>:</span>
            </strong>
            <span>{t("healthCoachesDescription")}</span>
          </li>
        </ul>
        <p>
          <span>{t("relationshipWithEnrollingPhysician")}</span>
        </p>
        <p>
          <span>{t("careTeamLicensingVerification")}</span>
        </p>
        <p>
          <span>{t("careTeamReviewAndManagement")}</span>
        </p>
        <p>
          <span>{t("stateRequirementsForCare")}</span>
          <strong>
            <span style={{ fontStyle: "bold" }}>
              {t("continueRoutineCareWarning")}
            </span>
          </strong>
          <span>{t("contactCareTeam")}</span>
          <strong>
            <span>{t("emergencyWarningB")}</span>
          </strong>
          <span>.</span>

          <strong>
            <u>
              <span style={{ textTransform: "uppercase" }}>
                {t("doNotDisregardAdvice")}
              </span>
            </u>
          </strong>
          <strong>
            <u>
              <span>.</span>
            </u>
          </strong>
        </p>
        <p>
          <span>{t("withdrawWithoutConsequence")}</span>
        </p>
        <p>
          <span>{t("digitalMedicineProgramHelpsManage")}</span>
          <strong>
            <span>{t("monitoringDevice")}</span>
          </strong>
          <span>{t("monitoringDeviceDescriptionB")}</span>
          <strong>
            <span>{t("monitoredHealthData")}</span>
          </strong>
          <span>{t("monitoredHealthDataDescription")}</span>
          <strong>
            <span>{t("digitalMedicineApp")}</span>
          </strong>
          <span>{t("digitalMedicineAppDescriptionB")}</span>
          <strong>
            <span>{t("myOchsnerApp")}</span>
          </strong>
          <span>{t("myOchsnerAppDescription")}</span>
          <strong>
            <span>{t("integratedApps")}</span>
          </strong>
          <span>{t("integratedAppsDescriptionB")}</span>
        </p>
        <p>
          <span>{t("integratedAppsUseCloudComputing")}</span>
        </p>
        <p>
          <u>
            <span>{t("providerPlatformInteraction")}</span>
          </u>
          <strong>
            <u>
              <span>{t("providerPlatform")}</span>
            </u>
          </strong>
          <u>
            <span>{t("providersDescription")}</span>
          </u>
          <strong>
            <u>
              <span>{t("providers")}</span>
            </u>
          </strong>
          <u>
            <span>{t("programTechnologyDescriptionB")}</span>
          </u>
          <strong>
            <u>
              <span>{t("programTechnology")}</span>
            </u>
          </strong>
          <u>
            <span>{t("programTechnologyEase")}</span>
          </u>
        </p>
        <p>
          <span>{t("noWarrantyOnProgramTech")}</span>
        </p>
        <p>
          <span>{t("notLiableForErrors")}</span>
        </p>
        <p>
          <span>{t("stateServiceAvailability")}</span>
        </p>
        <p>
          <span>{t("cooperationPromiseB")}</span>
          <span>{t("ochsnerNotResponsibleForDelays")}</span>
          <span>{t("accessStopsWhenProgramEnds")}</span>
        </p>
        <p>
          <span>{t("confidentialityPromise")}</span>
        </p>
        <p>
          <span>{t("servicePrivacyPromise")}</span>
        </p>
        <p>
          <span>{t("enrollmentProcess")}</span>
        </p>
        <p>
          <span>{t("thirdPartyVendors")}</span>
        </p>
        <p>
          <span>{t("thirdPartyVendorsListIntro")}</span>
        </p>

        <ul>
          <li>
            <span>Patient Portal: MyOchsner (MyChart for Ochsner Health)</span>
            <ul
              style={{
                marginRight: "0px",
                marginLeft: "0px",
                paddingLeft: "0px",
                listStyle: "circle",
              }}
            >
              <li style={{ marginLeft: "29.3px", paddingLeft: "6.7px" }}>
                <a
                  href="https://my.ochsner.org/PRD/default.asp?mode=stdfile&option=termsandconditions"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <u>
                    <span style={{ color: "#000000" }}>
                      https://my.ochsner.org/PRD/default.asp?mode=stdfile&option=termsandconditions
                    </span>
                  </u>
                </a>
              </li>
              <li style={{ marginLeft: "29.3px", paddingLeft: "6.7px" }}>
                <a
                  href="https://my.ochsner.org/PRD/default.asp?mode=stdfile&option=hlthprivacy"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <u>
                    <span style={{ color: "#000000" }}>
                      https://my.ochsner.org/PRD/default.asp?mode=stdfile&amp,opx"ion=hlthprivacy
                    </span>
                  </u>
                </a>
                <span></span>
              </li>
            </ul>
          </li>
          <li>
            <span>Devices: iHealth Labs</span>
            <ul
              style={{
                marginRight: "0px",
                marginLeft: "0px",
                paddingLeft: "0px",
                listStyle: "circle",
              }}
            >
              <li style={{ marginLeft: "29.3px", paddingLeft: "6.7px" }}>
                <a
                  href="https://ihealthlabs.com/about-us/terms-and-conditions/"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <u>
                    <span style={{ color: "#000000" }}>
                      https://ihealthlabs.com/about-us/terms-and-conditions/
                    </span>
                  </u>
                </a>
              </li>
              <li style={{ marginLeft: "29.3px", paddingLeft: "6.7px" }}>
                <a
                  href="https://ihealthlabs.com/pages/privacy-policy"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <u>
                    <span style={{ color: "#000000" }}>
                      https://ihealthlabs.com/pages/privacy-policy
                    </span>
                  </u>
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <p>
          <strong>
            <u>
              <span style={{ textDecoration: "underline" }}>
                {t("guaranteeDisclaimer")}
              </span>
            </u>
          </strong>
        </p>
        <p>
          <span>{t("stateRestrictions")}</span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <u>
            <span>{t("careTeamDiscretion")}</span>
          </u>
          <u>
            <span></span>
          </u>
          <u>
            <span style={{ textDecoration: "underline" }}>
              {t("liabilityDisclaimerC")}
            </span>
          </u>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>{t("participationEnd")}</span>
        </p>
        <p>
          <span>{t("featureChange")}</span>
          <span></span>
        </p>
      </TypographyOrP>
    </TypographyOrP>
  );
};

export default CombinedConsentText;
