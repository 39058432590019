export const Color = {
  primary: "#002d5d",
  white: "#ffffff",
  strongBlue: "#0075c9",
  lightBlue: "#e5f1fa",
  lightGrayOrange: "#fdf6e5",
  darkGray: "#757679",
  lightGray: "#f1f1f1",
  midnightBlue: "#223d6b",
  gold: "#f0ad00",
  softOrange: "#f8d680",
  strongDarkBlue: "#112e59",
  alertsGreen: "#80bc00",
  alertsOrange: "#ff9015",
  alertsRed: "#ce0e2d",
  textBlack: "#101820",
  inputFieldHoverState: "#0075C9",
  checkedInput: "#112E59",
  checkedInputBg: "#e7ecf2",
} as const;
