import { Typography } from "constants/Typography/Typography";
import useTranslation from "common/TranslationHook/useTranslation";
import "./style.css";

interface GeneralConsentTextProps {
  forceStaticHTMLSupport?: boolean;
}

const GeneralConsentText = ({
  forceStaticHTMLSupport = false,
}: GeneralConsentTextProps) => {
  const { t } = useTranslation("GeneralConsentText");
  const TypographyOrP = ({ children, ...rest }: any) =>
    forceStaticHTMLSupport ? (
      <p>{children}</p>
    ) : (
      <Typography {...rest}>{children}</Typography>
    );
  const subpoints = t("subpoints", { returnObjects: true });
  return (
    <TypographyOrP>
      <div>
        <ol type="A" style={{ marginTop: "0px" }}>
          <li>
            <b>{t("title")}</b>
            {t("description")}
          </li>
          <ol type="1">
            {Array.isArray(subpoints) &&
              subpoints.map((point: string, idx: number) => (
                <li key={idx}>{point}</li>
              ))}
          </ol>
          <li>
            <b>{t("authorizationForRelease.title")}</b>{" "}
            {t("authorizationForRelease.description")}
          </li>
        </ol>
        <div>{t("continuedCareAuthorization")}</div>
        <ol type="A" start={3}>
          <li>
            <b>{t("medicarePatientCertification.title")}</b>{" "}
            {t("medicarePatientCertification.description")}
          </li>
          <li>
            <b>{t("insuranceBenefitsAssignment.title")}</b>
            {t("insuranceBenefitsAssignment.description")}
          </li>
          <li>
            <b>{t("financialResponsibilityAcceptance.title")}</b>{" "}
            {t("financialResponsibilityAcceptance.description")}
          </li>
          <li>
            <b>{t("communicationAuthorization.title")}</b>{" "}
            {t("communicationAuthorization.description")}
          </li>
          <li>
            <b>{t("relationshipBetweenFacilityAndPhysician.title")}</b>{" "}
            {t("relationshipBetweenFacilityAndPhysician.description")}
          </li>
          <li>
            <b>{t("privacyPractices.title")}</b>{" "}
            {t("privacyPractices.description")}
          </li>
          <li>
            <b>{t("term.title")}</b> {t("term.description")}
          </li>
          <li>
            <b>{t("ochsnerDefinition.title")}</b>{" "}
            {t("ochsnerDefinition.description")}
          </li>
        </ol>
        <TypographyOrP style={{ fontStyle: "italic" }}>
          {t("nonDiscrimination.description")}
          <br />
          <br />
          {t("languageAssistance.spanish")}
          <br />
          <br />
          {t("languageAssistance.vietnamese")}
        </TypographyOrP>
      </div>
    </TypographyOrP>
  );
};

export default GeneralConsentText;
