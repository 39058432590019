import { useEffect, useState } from "react";
import { Typography } from "constants/Typography/Typography";
import { FontFamily } from "constants/Fonts";
import { IContextActions, IContextState } from "store/Context";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "./wizardscreen/PageTitles";
import BackForwardButton from "common/BackForwardButton";
import RadioButton from "common/RadioButton";
import useTranslation from "common/TranslationHook/useTranslation";
import "./style.css";

interface ExclusionaryCriteriaProps {
  nextStep: () => void;
  backStep: () => void;
  setPrevStep: React.Dispatch<React.SetStateAction<number>>;
  setStepCount: (step: number) => void;
  state: IContextState;
  actions: IContextActions;
}

export const ExclusionaryCriteria = ({
  setStepCount,
  setPrevStep,
  state,
}: ExclusionaryCriteriaProps) => {
  const { t } = useTranslation("ExclusionaryCriteria");

  const [selectedPregnancy, setSelectedPregnancy] = useState("");
  const [selectedEating, setSelectedEating] = useState("");
  const [selectedTypeDiab, setSelectedTypeDiab] = useState("");
  const { auditTrail } = useApi();
  let isEligible =
    state.gender !== "female"
      ? selectedEating !== "" && selectedTypeDiab !== ""
      : selectedPregnancy !== "" &&
        selectedEating !== "" &&
        selectedTypeDiab !== "";

  useEffect(() => {
    auditTrail("Program Validation");
    setPrevStep(PAGE_TITLES.EXCLUSIONARY_CRITERIA.id);
  }, []);

  const handleContinue = () => {
    if (
      selectedPregnancy === "Pregnant" ||
      selectedEating === "EatingDisorder" ||
      selectedTypeDiab === "Type1Diab"
    ) {
      setStepCount(PAGE_TITLES.ERROR_EXCLUSIONARYCRITERIA.id);
    } else {
      auditTrail("Submit Eligibility");
      auditTrail("Pregnancy Selection" + selectedPregnancy);
      auditTrail("TypeDiab Selection " + selectedTypeDiab);
      auditTrail("EatingDisorder Selection" + selectedEating);
      setStepCount(PAGE_TITLES.PROGRAM_COST_CONSENT.id);
    }
  };

  return (
    <div>
      {state.gender === "female" ? (
        <>
          <Typography fontFamily={FontFamily.bold}>
            {t("pregnancyQuestion")}
          </Typography>
          <div className="eligibility-radio-group" style={{ marginBottom: 24 }}>
            <RadioButton
              id="Pregnant"
              value="Pregnant"
              checked={selectedPregnancy === "Pregnant"}
              onChange={() => setSelectedPregnancy("Pregnant")}
              label={t("yes")}
            />
            <RadioButton
              id="NOTPregnant"
              value="NOTPregnant"
              checked={selectedPregnancy === "NOTPregnant"}
              onChange={() => setSelectedPregnancy("NOTPregnant")}
              label={t("no")}
            />
          </div>
        </>
      ) : null}
      <Typography fontFamily={FontFamily.bold}>
        {t("eatingDisorderQuestion")}
      </Typography>
      <div className="eligibility-radio-group" style={{ marginBottom: 24 }}>
        <RadioButton
          id="EatingDisorder"
          value="Pregnant"
          checked={selectedEating === "EatingDisorder"}
          onChange={() => setSelectedEating("EatingDisorder")}
          label={t("yes")}
        />
        <RadioButton
          id="NOTEatingDisorder"
          value="NOTEatingDisorder"
          checked={selectedEating === "NOTEatingDisorder"}
          onChange={() => setSelectedEating("NOTEatingDisorder")}
          label={t("no")}
        />
      </div>
      <Typography fontFamily={FontFamily.bold}>
        {t("type1DiabetesQuestion")}
      </Typography>
      <div className="eligibility-radio-group" style={{ marginBottom: 24 }}>
        <RadioButton
          id="Type1Diab"
          value="Type1Diab"
          checked={selectedTypeDiab === "Type1Diab"}
          onChange={() => setSelectedTypeDiab("Type1Diab")}
          label={t("yes")}
        />
        <RadioButton
          id="NOType1Diab"
          value="NOType1Diab"
          checked={selectedTypeDiab === "NOType1Diab"}
          onChange={() => setSelectedTypeDiab("NOType1Diab")}
          label={t("no")}
        />
      </div>

      <BackForwardButton
        forwardAction={handleContinue}
        backVisible={false}
        disabled={!isEligible}
      />
    </div>
  );
};
