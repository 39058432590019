import React, { useEffect, useState } from "react";
import { ReactComponent as LoadingSpinner } from "../../../assets/svgs/loadingSpinner.svg";
// import "./styles.css";
import { useTheme } from "styled-components";
import { PAGE_TITLES } from "../PageTitles";
import { IContextActions, IContextState, useMyContext } from "store/Context";
import { useApi } from "store/useApi";
import SetDemoGraphComponent from "common/Demographic/SetDemoGraphComponent";
import { Typography } from "constants/Typography/Typography";
import isUnderAge from "common/CalculateAge/isUnderAge";
import moment from "moment";
import useTranslation from "common/TranslationHook/useTranslation";

interface APiLoaderProps {
  state: IContextState;
  actions: IContextActions;
  setStepCount: (step: number) => void;
}

const APiLoader = ({ state, actions, setStepCount }: APiLoaderProps) => {
  const theme = useTheme();
  const [ageChecked, setAgeChecked] = useState(false);

  const { t } = useTranslation("Misc");

  const {
    auditTrail,
    PatientLookup,
    PatientPrograms,
    PatientInfo,
    UpdatePatientSession,
    AbandonCartEmail,
  } = useApi();
  const id = state.id;
  const pageToNavigate = state.pageToNavigate;
  useEffect(() => {
    PatientInfo(id, null, null).then((data) => {
      if (data) {
        SetDemoGraphComponent(data, actions);
        UpdatePatientSession(id);
        if (data.BirthDate) {
          if (isUnderAge(data.BirthDate)) {
            setStepCount(PAGE_TITLES.ERROR_UNDERAGE.id);
            return;
          }
        }
        setAgeChecked(true);
      }
    });
  }, []);
  useEffect(() => {
    if (!ageChecked) return;
    if (state.FirstName && pageToNavigate) {
      if (state.ACE) {
        const current = new Date();
        const formattedCurrentDate = moment(current)
          .format("MM/DD/YYYY h:mma")
          .toString();
        AbandonCartEmail(
          state.EmailAddress,
          pageToNavigate,
          formattedCurrentDate,
          state.LastName,
        );
      }
      PatientLookup().then((data) => {
        if (data.PatientId !== "") {
          actions.setValue("patientId", data.PatientId);
          PatientPrograms(data.PatientId, state.oauth).then((data: any) => {
            if (
              data.CapMet === false &&
              !data.Enrolled.length &&
              !data.Eligible.length &&
              data.Sponsor === ""
            ) {
              auditTrail("Not Eligible");
              auditTrail("Came from MyOchsner");
              actions.setValue("comingFromSignup", true);
              setStepCount(PAGE_TITLES.ERROR_FINANCIAL_ELIGIBLE.id);
            } else if (data.CapMet === "true") {
              actions.setValue("comingFromSignup", true);
              actions.setValue("oauth", true);
              actions.setValue("hasMyChartAccount", true);
              auditTrail("Cap Met");
              setStepCount(PAGE_TITLES.ERROR_PATIENT_CAP.id);
            } else if (!data.Enrolled.length && !data.Eligible.length) {
              auditTrail("Not Eligible");
              setStepCount(PAGE_TITLES.ERROR_FINANCIAL_ELIGIBLE.id);
            } else {
              actions.setValue("Capmet", data.CapMet);
              actions.setValue("sponsor", data.Sponsor);
              actions.setValue("threefourtyB", data.Eligible340B);
              if (data.Enrolled.includes("Hypertension")) {
                actions.setValue("htnEnrolled", true);
              }
              if (data.Enrolled.includes("Type 2 Diabetes")) {
                actions.setValue("diabEnrolled", true);
              }
              if (data.Enrolled.includes("Weight Management")) {
                actions.setValue("wmEnrolled", true);
              }
              if (data.Eligible.includes("Hypertension")) {
                actions.setValue("htnEligible", true);
              }
              if (data.Eligible.includes("Type 2 Diabetes")) {
                actions.setValue("diabEligible", true);
              }
              if (data.Eligible.includes("Weight Management")) {
                actions.setValue("wmEligible", true);
              }
              if (data.WMType === "Lifestyle Only") {
                actions.setValue("wmEligible", true);
                actions.setValue("wmLifestyleProgram", true);
              }
              if (data.WMType === "Med Management") {
                actions.setValue("wmEligible", true);
                actions.setValue("wmMedsProgram", true);
              }
              auditTrail("Returned to the app using a link");
              setStepCount(pageToNavigate);
            }
          });
        } else return null;
      });
    }
  }, [state.FirstName, ageChecked]);
  return (
    <>
      <Typography style={{ display: "flex", justifyContent: "center" }}>
        {t("apiLoader")}
      </Typography>
      <div className="processingUpload">
        <div
          style={{
            marginTop: 70,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LoadingSpinner fill={theme.colors.tertiary} className="spinner" />
        </div>
      </div>
    </>
  );
};

export default APiLoader;
