import React, { useEffect, useCallback, useState } from "react";
import { ReactComponent as LoadingSpinner } from "../../../assets/svgs/loadingSpinner.svg";
import { useTheme } from "styled-components";
import { IContextActions, IContextState } from "store/Context";
import { useApi } from "store/useApi";
import SetDemoGraphComponent from "common/Demographic/SetDemoGraphComponent";
import { PAGE_TITLES } from "../PageTitles";
import isUnderAge from "common/CalculateAge/isUnderAge";

interface APiLoaderProps {
  state: IContextState;
  actions: IContextActions;
  setStepCount: (step: number) => void;
}

const OauthAPiLoader = ({ state, actions, setStepCount }: APiLoaderProps) => {
  const theme = useTheme();
  const [ageChecked, setAgeChecked] = useState(false);
  const {
    auditTrail,
    PatientPrograms,
    PatientInfo,
    PatientSession,
    dateAndFa,
  } = useApi();
  const patientId = state.patientId;

  useEffect(() => {
    PatientInfo(null, patientId, null).then((data) => {
      if (data) {
        SetDemoGraphComponent(data, actions);
        if (data.BirthDate) {
          if (isUnderAge(data.BirthDate)) {
            setStepCount(PAGE_TITLES.ERROR_UNDERAGE.id);
            return;
          }
        }
        setAgeChecked(true);
      }
    });
  }, []);

  const getDateAndFA = async (Sponsor = null) => {
    const data = await dateAndFa();
    actions.setValue("LastConsentDate", data.LastConsentDate);
    actions.setValue("LastSponsor", data.Sponsor);
    const currentDate = new Date();
    const lastConsentDate = new Date(data.LastConsentDate);

    const monthsDiff =
      (currentDate.getFullYear() - lastConsentDate.getFullYear()) * 12 +
      (currentDate.getMonth() - lastConsentDate.getMonth());

    if (state.directEnroll) {
      if (
        data.Sponsor?.toUpperCase() === state.sponsor?.toUpperCase() &&
        monthsDiff >= 6
      ) {
        actions.setValue("needToConsent", true);
        setStepCount(PAGE_TITLES.THANK_YOU_SELECTION.id);
      } else if (data.Sponsor === null || data.LastConsentDate === null) {
        setStepCount(PAGE_TITLES.THANK_YOU_SELECTION.id);
      } else {
        actions.setValue("needToConsent", true);
        setStepCount(PAGE_TITLES.THANK_YOU_SELECTION.id);
      }
    } else {
      if (data.Sponsor?.toUpperCase() === Sponsor && monthsDiff < 6) {
        actions.setValue("needToConsent", true);
      }
    }
  };

  useEffect(() => {
    if (!ageChecked) return;
    if (state.directEnroll) {
      PatientSession(patientId);
      auditTrail("Came from MyOchsner");
      auditTrail("Good Attributes");
      getDateAndFA();
    } else if (patientId) {
      PatientPrograms(patientId, true).then((data: any) => {
        if (
          data.CapMet === false &&
          !data.Enrolled.length &&
          !data.Eligible.length &&
          data.Sponsor === ""
        ) {
          auditTrail("Not Eligible");
          auditTrail("Came from MyOchsner");
          actions.setValue("comingFromSignup", true);
          setStepCount(PAGE_TITLES.ERROR_FINANCIAL_ELIGIBLE.id);
        } else if (data.CapMet === "true") {
          actions.setValue("comingFromSignup", true);
          actions.setValue("oauth", true);
          actions.setValue("hasMyChartAccount", true);
          auditTrail("Cap Met");
          setStepCount(PAGE_TITLES.ERROR_PATIENT_CAP.id);
        } else if (!data.Enrolled.length && !data.Eligible.length) {
          auditTrail("Not Eligible");
          setStepCount(PAGE_TITLES.ERROR_FINANCIAL_ELIGIBLE.id);
        } else {
          actions.setValue("Capmet", data.CapMet);
          actions.setValue("sponsor", data.Sponsor);
          actions.setValue("threefourtyB", data.Eligible340B);
          if (data.Enrolled.includes("Hypertension")) {
            actions.setValue("htnEnrolled", true);
          }
          if (data.Enrolled.includes("Type 2 Diabetes")) {
            actions.setValue("diabEnrolled", true);
          }
          if (data.Enrolled.includes("Weight Management")) {
            actions.setValue("wmEnrolled", true);
          }
          if (data.Eligible.includes("Hypertension")) {
            actions.setValue("htnEligible", true);
          }
          if (data.Eligible.includes("Type 2 Diabetes")) {
            actions.setValue("diabEligible", true);
          }
          if (data.Eligible.includes("Weight Management")) {
            actions.setValue("wmEligible", true);
          }
          if (data.WMType === "Lifestyle Only") {
            actions.setValue("wmEligible", true);
            actions.setValue("wmLifestyleProgram", true);
          }
          if (data.WMType === "Med Management") {
            actions.setValue("wmEligible", true);
            actions.setValue("wmMedsProgram", true);
          }
          getDateAndFA(data.Sponsor);

          PatientSession(patientId);
          setStepCount(PAGE_TITLES.SUCCESS_PAGE.id);
        }
      });
    }
  }, [ageChecked]);

  return (
    <>
      <div className="processingUpload">
        <div
          style={{
            marginTop: 70,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <LoadingSpinner fill={theme.colors.tertiary} className="spinner" />
        </div>
      </div>
    </>
  );
};

export default OauthAPiLoader;
