import styled from "styled-components";
import { Color } from "constants/Colors";
import { Typography as BaseTypography } from "constants/Typography/Typography";
import { ReactComponent as CloseIcon } from "../assets/svgs/close_icon.svg";

interface PopupProps {
  primary?: boolean;
  disabled?: boolean;
  consent?: boolean;
  shippingPopup?: boolean;
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: auto;
  z-index: 1;
`;

const Popup = styled.div<PopupProps>`
  position: relative;
  background-color: white;
  border: 1px solid #ccc;
  padding: ${(props) =>
    !props.shippingPopup ? "32px 72px 40px 32px" : "32px"};
  top: 10vh;
  box-shadow: 1px 5px 20px 0px rgba(7, 43, 97, 0.15);
  ${(props) =>
    !props.shippingPopup &&
    `
    width: 300px;
    max-width: 90vw;
  `}
  border-radius: 8px;
`;

export const CloseBtn = styled(CloseIcon)`
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
  }
  stroke: ${(props) => props.theme.colors.primary};
`;

const Typography = styled(BaseTypography)`
  color: ${Color.textBlack};
`;

interface PopupComponentProps {
  showPopup: boolean;
  setShowPopup: (show: boolean) => void;
  content: any;
  closeIcon: string;
  shippingPopup?: boolean;
}

const PopupComponent = ({
  showPopup,
  setShowPopup,
  content,
  closeIcon,
  shippingPopup = false,
}: PopupComponentProps) => {
  if (!showPopup) return null;

  return (
    <Overlay onClick={() => setShowPopup(false)}>
      <Popup onClick={(e) => e.stopPropagation()} shippingPopup={shippingPopup}>
        <CloseBtn onClick={() => setShowPopup(false)} />
        <Typography>{content}</Typography>
      </Popup>
    </Overlay>
  );
};

export default PopupComponent;
