import { useEffect, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "./wizardscreen/PageTitles";
import { IContextActions, IContextState, MyProvider } from "store/Context";
import { Typography } from "constants/Typography/Typography";
import { CostConsentHTML1 } from "./CostConsentHTML1";
import { CostConsentHTML2 } from "./CostConsentHTML2";
import { CostConsentHTML3 } from "./CostConsentHTML3";
import { CostConsentHTML4 } from "./CostConsentHTML4";
import { CostConsentHTML5 } from "./CostConsentHTML5";
import CombinedConsentText from "constants/CombinedConsentsHTML";
import GeneralConsentText from "./GeneralConsentText";
import TelemedicineConsentText from "./TelemedicineConsent";
import NoticeOfPrivacyConsentText from "./NoticeOfPrivacyConsent";
import FinancialConsentText from "./FinancialConsent";
import ProgramConsentText from "./ProgramConsent";
import BackForwardButton from "common/BackForwardButton";
import AccordionSection from "common/AccordionSection";
import "./style.css";
import { CostConsentHTML1A } from "./CostConsentHTML1A";
import useTranslation from "common/TranslationHook/useTranslation";
import { ThemeProvider } from "styled-components";

interface ConsolidatedConsentProps {
  nextStep: () => void;
  backStep: () => void;
  setPrevStep: React.Dispatch<React.SetStateAction<number>>;
  setStepCount: (step: number) => void;
  state: IContextState;
  actions: IContextActions;
  HBPSelected: boolean;
  T2Selected: boolean;
  theme: any;
}

export const ConsolidatedConsent = ({
  backStep,
  setStepCount,
  HBPSelected,
  T2Selected,
  state,
  actions,
  theme,
}: ConsolidatedConsentProps) => {
  const { t } = useTranslation("ConsolidatedConsent");

  useEffect(() => {
    auditTrail("ConsolidatedConsent");
  }, []);
  const [attribution, setAttribution] = useState(0);
  const [costConsentHTML, setCostConsentHTML] = useState(<></>);

  let costHTMLAPI: any = null;

  useEffect(() => {
    const attribution1 = [
      "ALOHA CARE",
      "LA HEALTHCARE CONNECTIONS",
      "AUSTIN ISD",
      "BROWN AND ROOT",
      "DOL EMPLOYEE PLAN",
      "FORCE POWER",
      "LA CLERK OF COURT EMPLOYEE PLAN",
      "LA MACHINERY",
      "RESOURCE ENERGY",
      "LHC GROUP",
      "SHELL OIL COMPANY",
      "SINGING RIVER EMPLOYEE PLAN",
      "SINGING RIVER GULFPORT EMPLOYEE PLAN",
      "ST. CHARLES PARISH PUBLIC SCHOOLS",
      "ST. TAMMANY FIRE DISTRICT 1",
      "ST. TAMMANY HEALTH SYSTEM",
      "TRUSTMARK",
      "WESTERN DENTAL",
      "YANFENG",
      "ACCUTRANS",
      "ANGEL OF THE WINDS",
      "AMERIHEALTH CARITAS LOUISIANA",
      "BCBS EMPLOYEE GROUP",
      "BCBS BLUE CONNECT",
      "BCBS BLUE ADVANTAGE",
      "UHC SELECT TIER",
      "UMR SELECT TIER",
    ];
    const attribution1A = ["STONE", "ROCKINGHAM"];
    const attribution2 = [
      "LOUISIANA SHERIFFS ASSOCIATION",
      "LAFAYETTE CONSOLIDATED GOVERNMENT",
      "CRESCENT BANK EMPLOYEE PLAN",
      "ST BERNARD PARISH SHERIFFS OFFICE",
    ];

    const attribution3 = [
      "MEDICARE",
      "MEDICARE PART B",
      "OHP MEDICARE ADVANTAGE PLAN",
      "HUMANA GOLD",
      "HUMANA MANAGED MEDICARE GENERIC",
      "HUMANA MANAGED MEDICARE GOLDPLUS",
      "HUMANA MANAGED MEDICARE HMO",
      "HUMANA MANAGED MEDICARE PPO",
      "HUMANA MANAGED MEDICARE SNP",
      "HUMANA TOTAL CARE ADVANTAGE",
      "MEDICARE SHARED SAVINGS PROGRAM (MSSP)",
    ];

    const attribution4 = [
      "OCHSNER EMPLOYEE PLAN - OCHPLUS 1",
      "OCHSNER EMPLOYEE PLAN - OCHPLUS 2",
      "OCHSNER EMPLOYEE PLAN - OCHPLUS 3",
      "SLIDELL MEMORIAL HOSPITAL EMPLOYEE PLAN",
      "CHABERT SRMC MEDPLAN1",
      "CHABERT SRMC MEDPLAN2",
    ];
    const attribution5 = [
      "OCHSNER LSU HEALTH SHREVEPORT-NORTH LOUISIANA EMPLOYEE PLAN",
    ];

    const sponsor: any = state.sponsor;

    if (attribution1.includes(sponsor)) {
      setAttribution(1);
      setCostConsentHTML(<CostConsentHTML1 state={state} actions={actions} />);
      costHTMLAPI = renderToStaticMarkup(
        <ThemeProvider theme={theme}>
          <MyProvider>
            <CostConsentHTML1
              forceStaticHTMLSupport={true}
              state={state}
              actions={actions}
            />
          </MyProvider>
        </ThemeProvider>,
      );
    } else if (attribution1A.includes(sponsor)) {
      setAttribution(1.5);
      setCostConsentHTML(<CostConsentHTML1A state={state} actions={actions} />);
      costHTMLAPI = renderToStaticMarkup(
        <ThemeProvider theme={theme}>
          <MyProvider>
            <CostConsentHTML1A
              forceStaticHTMLSupport={true}
              state={state}
              actions={actions}
            />
          </MyProvider>
        </ThemeProvider>,
      );
    } else if (attribution2.includes(sponsor)) {
      setAttribution(2);
      setCostConsentHTML(<CostConsentHTML2 state={state} actions={actions} />);
      costHTMLAPI = renderToStaticMarkup(
        <ThemeProvider theme={theme}>
          <MyProvider>
            <CostConsentHTML2
              forceStaticHTMLSupport={true}
              state={state}
              actions={actions}
            />
          </MyProvider>
        </ThemeProvider>,
      );
    } else if (attribution3.includes(sponsor)) {
      setAttribution(3);
      setCostConsentHTML(<CostConsentHTML3 state={state} actions={actions} />);
      costHTMLAPI = renderToStaticMarkup(
        <ThemeProvider theme={theme}>
          <MyProvider>
            <CostConsentHTML3
              forceStaticHTMLSupport={true}
              state={state}
              actions={actions}
            />
          </MyProvider>
        </ThemeProvider>,
      );
    } else if (attribution4.includes(sponsor)) {
      setAttribution(4);
      setCostConsentHTML(<CostConsentHTML4 state={state} actions={actions} />);
      costHTMLAPI = renderToStaticMarkup(
        <ThemeProvider theme={theme}>
          <MyProvider>
            <CostConsentHTML4
              forceStaticHTMLSupport={true}
              state={state}
              actions={actions}
            />
          </MyProvider>
        </ThemeProvider>,
      );
    } else {
      setAttribution(5);
      setCostConsentHTML(<CostConsentHTML5 state={state} actions={actions} />);
      costHTMLAPI = renderToStaticMarkup(
        <ThemeProvider theme={theme}>
          <MyProvider>
            <CostConsentHTML5
              forceStaticHTMLSupport={true}
              state={state}
              actions={actions}
            />
          </MyProvider>
        </ThemeProvider>,
      );
    }
  }, []);

  const consolidatedConsentHTMLAPI = renderToStaticMarkup(
    <MyProvider>
      <CombinedConsentText forceStaticHTMLSupport={true} />
    </MyProvider>,
  );

  const { Consent, auditTrail } = useApi();

  const handleContinue = () => {
    Consent("CONSOLIDATED", consolidatedConsentHTMLAPI).then((data) => {});
    Consent("Cost", costHTMLAPI).then((data) => {});
    console.log("state htn chosen: " + state.htnChosen);
    console.log("state diab chosen: " + state.diabChosen);
    if (HBPSelected) {
      setStepCount(PAGE_TITLES.CUFF_SIZE_SELECTION.id);
    } else if (T2Selected) {
      setStepCount(PAGE_TITLES.GLUCOMETER_SELECTION.id);
    } else {
      setStepCount(PAGE_TITLES.CONFIRMATION_PAGE.id);
    }
  };

  const accordionData = [
    { topic: t("generalConsentToTreat"), info: <GeneralConsentText /> },

    { topic: t("telemedicineConsent"), info: <TelemedicineConsentText /> },
    {
      topic: t("noticeOfPrivacyPractice"),
      info: <NoticeOfPrivacyConsentText />,
    },
    { topic: t("financialConsent"), info: <FinancialConsentText /> },
    { topic: t("digitalMedicineConsent"), info: <ProgramConsentText /> },
    { topic: t("digitalMedicineCost"), info: costConsentHTML },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        marginTop: "24px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "16px",
        }}
      >
        <AccordionSection data={accordionData} />
      </div>
      <Typography marginTop="0px" mb="0px" fontStyle="italic">
        {t("attestationText")}
      </Typography>
      <BackForwardButton
        backAction={backStep}
        forwardAction={handleContinue}
        backVisible={true}
        frontVisible={true}
        forwardText={t("buttonAccept")}
        consent={true}
      />
    </div>
  );
};
