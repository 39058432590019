import { Typography } from "constants/Typography/Typography";
import { FontWeight } from "constants/Fonts";
import { useEffect, useState } from "react";
import { IContextActions } from "store/Context";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "./wizardscreen/PageTitles";
import { useTheme } from "styled-components";
import BackForwardButton from "common/BackForwardButton";
import RadioButton from "common/RadioButton";
import useTranslation from "common/TranslationHook/useTranslation";
import "./style.css";

interface GlucometerSelectionProps {
  nextStep: () => void;
  backStep: () => void;
  HBPSelected: boolean;
  T2Selected: boolean;
  setStepCount: (step: number) => void;
  setPrevStep: React.Dispatch<React.SetStateAction<number>>;
  actions: IContextActions;
}

export const GlucometerSelection = ({
  backStep,
  setStepCount,
  setPrevStep,
  actions,
}: GlucometerSelectionProps) => {
  const { DeviceDetail, auditTrail } = useApi();
  const theme = useTheme();
  const { t } = useTranslation("GlucometerSelection");
  useEffect(() => {
    auditTrail("GlucometerSelection");
  }, []);

  const handleContinue = () => {
    let cgmBool: any = false;
    if (CGMUse === "Yes") {
      actions.setValue("hasCgm", true);
      cgmBool = true;
    } else {
      actions.setValue("hasCgm", false);
    }

    DeviceDetail({ hasCgm: cgmBool }).then((data) => {});
    setPrevStep(PAGE_TITLES.GLUCOMETER_SELECTION.id);
    setStepCount(PAGE_TITLES.SHIPPING_ADDRESS.id);
  };

  const [CGMUse, setCGMUse] = useState("");

  return (
    <div>
      <Typography>{t("intro")}</Typography>
      <Typography fontWeight={FontWeight.bold}>{t("question")}</Typography>
      <div className="CGMUse-radio-group" style={{ margin: "24px 0px" }}>
        <RadioButton
          value="Yes"
          id="yesInput"
          checked={CGMUse === "Yes"}
          onChange={() => {
            setCGMUse("Yes");
          }}
          label={t("yesLabel")}
        />

        <RadioButton
          value="No"
          id="noInput"
          checked={CGMUse === "No"}
          onChange={() => {
            setCGMUse("No");
          }}
          label={t("noLabel")}
        />
      </div>
      {CGMUse !== "" && (
        <div
          style={{
            display: "flex",
            padding: "24px",
            margin: "0px 0px 24px 0px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            alignSelf: "stretch",
            border: `1px solid ${theme.colors.secondary}`,
            borderRadius: "8px",
            background: theme.colors.tintSecondary10,
          }}
        >
          <Typography mb="0px" marginTop="0px">
            {t("glucometerInfo")}
          </Typography>
        </div>
      )}
      <BackForwardButton
        backAction={backStep}
        forwardAction={handleContinue}
        backVisible={true}
        frontVisible={true}
        disabled={CGMUse === ""}
      />
    </div>
  );
};
