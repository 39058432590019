import { Typography } from "constants/Typography/Typography";
import { styled, useTheme } from "styled-components";
import "./styles.css";

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: ${(props) => (props.theme.format > 1 ? "row" : "column")};
  padding: ${(props) =>
    props.theme.format > 1 ? "22px 80px" : "24px 20px 32px 20px"};
  align-items: center;
  gap: 24px;
  flex: 1;
  gap: 24px;
  max-width: var(--max-content-width);
  padding-left: ${(props) =>
    props.theme.format > 1
      ? "calc(max((100vw - var(--max-content-width)) / 2, 80px))"
      : null};
`;

const LogoContainer = styled.img`
  max-height: ${(props) => (props.theme.format > 1 ? "56px" : "40px")};
  max-width: ${(props) => (props.theme.format > 1 ? null : "100%")};
`;

const FooterLeftContent = styled(Typography)`
  width: ${(props) => (props.theme.format > 1 ? "100%" : null)};
`;

const Footer = () => {
  const theme = useTheme();

  const currentYear = new Date().getFullYear();

  return (
    <StyledFooter>
      <FooterLeftContent>&copy; {currentYear} Ochsner Health</FooterLeftContent>
      <LogoContainer src={theme.icon} />
    </StyledFooter>
  );
};

export default Footer;
