import styled from "styled-components";
import { FontSize, FontFamily } from "../Fonts";
import isPropValid from "@emotion/is-prop-valid";

interface TypographyProps {
  className?: string;
  children?: React.ReactNode;
  fontSize?: string;
  fontFamily?: string;
  color?: string;
  fontWeight?: number;
  marginTop?: string;
  mb?: string;
  marginBottom?: boolean;
  style?: React.CSSProperties;
  textDecoration?: string;
  display?: string;
  mobileFontSize?: string;
  width?: string;
  mobileWidth?: string;
  fontStyle?: string;
  mobileHeading?: string;
  headingLevel?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "large"
    | "small"
    | "medium"
    | "p24"
    | "p20";
  onClick?: () => void;
  signup?: boolean;
  htmlContent?: string;
  dangerouslySetInnerHTML?: { __html: string };
}

const StyledParagraph = styled(({ children, ...r }: TypographyProps) => (
  <p {...r}>{children}</p>
)).withConfig({
  shouldForwardProp: (prop) => isPropValid(prop),
})`
  font-family: ${({ headingLevel, theme, fontFamily }) => {
    if (fontFamily) {
      return fontFamily;
    } else {
      switch (headingLevel) {
        case "h1":
        case "h2":
        case "h3":
        case "h4":
          return theme.secondaryFont;
        default:
          return FontFamily.primary;
      }
    }
  }};
  color: ${({ theme, color }) => color ?? theme.colors.textPrimary};
  font-weight: ${({ fontWeight, headingLevel }) => {
    if (fontWeight) {
      return fontWeight;
    } else {
      switch (headingLevel) {
        case "h1":
        case "h2":
        case "h3":
        case "h4":
          return 700;
        default:
          return 500;
      }
    }
  }};
  font-size: ${({ headingLevel, fontSize }) => {
    if (fontSize) return { fontSize };
    switch (headingLevel) {
      case "h1":
        return FontSize.h1;
      case "h2":
        return FontSize.h2;
      case "h3":
        return FontSize.h3;
      case "h4":
        return FontSize.h4;
      case "large":
        return FontSize.large;
      case "p24":
        return FontSize.p24;
      case "p20":
        return FontSize.p20;
      case "small":
        return FontSize.small;
      default:
        return FontSize.medium;
    }
  }};
  margin-bottom: ${({ marginBottom, mb }) =>
    marginBottom ? mb ?? "8px" : "0px"};
  margin-top: ${({ marginTop }) => marginTop ?? null};
  text-decoration: ${({ textDecoration }) => textDecoration ?? "none"};
  width: ${({ width }) => width ?? "auto"};
  font-style: ${({ fontStyle }) => fontStyle ?? "none"};
  line-height: ${({ headingLevel }) => {
    switch (headingLevel) {
      case "h1":
        return "120%";
      case "h2":
        return "130%";
      case "h3":
        return "140%";
      case "h4":
        return "150%";
      default:
        return "150%";
    }
  }};
  @media (max-width: 768px) {
    font-size: ${({ headingLevel, mobileHeading }) => {
      switch (headingLevel) {
        case "h1":
          return "34px";
        case "h2":
          return "26px";
        case "h3":
          return "21px";
        case "h4":
          return "19px";
        case "large":
          return "20px";
        case "small":
          return "13px";
        case "p24":
          return "20px";
        case "p20":
          return "18px";
        default:
          return mobileHeading ?? "16px";
      }
    }};
    width: ${({ mobileWidth, width }) => mobileWidth ?? width};
  }
`;

export const Typography = ({
  children,
  fontSize,
  fontFamily,
  color,
  fontWeight,
  marginTop,
  mb,
  style,
  textDecoration,
  display,
  mobileFontSize,
  width,
  mobileWidth,
  fontStyle,
  marginBottom = true,
  onClick,
  className,
  headingLevel,
  mobileHeading,
  signup,
  htmlContent,
  dangerouslySetInnerHTML,
}: TypographyProps) => {
  return (
    <StyledParagraph
      fontSize={fontSize}
      fontFamily={fontFamily}
      color={color}
      fontWeight={fontWeight}
      marginTop={marginTop}
      mb={mb}
      textDecoration={textDecoration}
      display={display}
      mobileFontSize={mobileFontSize}
      style={style}
      width={width}
      mobileWidth={mobileWidth}
      fontStyle={fontStyle}
      onClick={onClick}
      marginBottom={marginBottom}
      className={className}
      headingLevel={headingLevel}
      mobileHeading={mobileHeading}
      signup={signup}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </StyledParagraph>
  );
};
