import { Typography } from "constants/Typography/Typography";
import { useEffect, useState } from "react";
import { useApi } from "store/useApi";
import { PAGE_TITLES } from "./wizardscreen/PageTitles";
import { IContextState } from "store/Context";
import BackForwardButton from "common/BackForwardButton";
import BoxedRadioButton from "common/BoxedRadiotButton";
import useTranslation from "common/TranslationHook/useTranslation";
import "./style.css";

interface ConfirmIdentityProps {
  nextStep: () => void;
  backStep: () => void;
  setStepCount: (step: number) => void;
  state: IContextState;
}

export const ConfirmIdentity = ({
  backStep,
  setStepCount,
  state,
}: ConfirmIdentityProps) => {
  const [selectedOption, setSelectedOption] = useState("");
  const { t } = useTranslation("ConfirmIdentity");

  const { auditTrail } = useApi();
  useEffect(() => {
    auditTrail("Confirm ID Choice");
  }, []);

  const handleContinue = () => {
    if (selectedOption === "identityQuestion") {
      setStepCount(PAGE_TITLES.IDENTITY_VERIFICATION_QUESTIONS.id);
    } else {
      setStepCount(PAGE_TITLES.UPLOAD_PHOTO_ID.id);
    }
  };

  return (
    <div>
      <Typography>{t("confirmIdentityHeader")}</Typography>
      <Typography>{t("confirmIdentitySubHeader")}</Typography>
      <BoxedRadioButton
        value="identityQuestion"
        checked={selectedOption === "identityQuestion"}
        onChange={() => setSelectedOption("identityQuestion")}
        label={t("identityQuestionOption")}
      />
      <BoxedRadioButton
        value="UploadId"
        checked={selectedOption === "UploadId"}
        onChange={() => setSelectedOption("UploadId")}
        label={t("uploadIdOption")}
      />
      <BackForwardButton
        backAction={backStep}
        forwardAction={handleContinue}
        backVisible={true}
        frontVisible={true}
        disabled={selectedOption === ""}
      />
    </div>
  );
};
